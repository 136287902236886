import { type ChangeEvent, type FocusEvent, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import SwitchableUnitField from 'components/SwitchableUnitField';
import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import DateTimeRange from 'components/DateTimeRange';
import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { humanDate } from 'helpers/Utils/formatters';
import { PRICE_UNITS, QUANTITY_UNITS } from 'modules/Blotter/Models/Consts';
import { getFixedValue } from 'modules/Blotter/Helpers';

import type { DropdownChangeEvent } from 'primereact/dropdown';
import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface MainSectionProps extends SectionProps {
  originalData: TradesDataResponse | null;
  setIsDateParsing: (arg: boolean) => void;
};

export function MainSection(props: MainSectionProps): JSX.Element {
  const { mutate, request, errors, shouldShowError, originalData, setIsDateParsing } = props;
  const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);

  const quantityOptions = useMemo(() => {
    if (!originalData || QUANTITY_UNITS.find(u => u === originalData.quantity.unit)) {
      return QUANTITY_UNITS;
    }

    // add original unit as an option
    return [...QUANTITY_UNITS, originalData.quantity.unit];
  }, [originalData]);

  return <section>
    <div className='section--main'>
      <SingleEntitySearch
        allowCustomSelection
        callback={(change?: SearchSuggestionsResponse | string): void => mutate({ instrument: getAutocompleteValue(change) })}
        onInputClear={(): void => mutate({ instrument: undefined })}
        label='Instrument*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError('instrument')}
        fields={EntitySearchFieldsEnum.Instrument}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={request.instrument}
        itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
        onFocusMethod={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
      />
      <DateTimeRange
        label='Trade Date, Time*'
        key={`trade__trade-datetime-${request.id}`}
        showNowButton
        onEmptyValue={(): void => {
          mutate({ dateTime: null });
          setIsDateParsing(false);
        }}
        onParsingStart={(): void => {
          setIsDateParsing(true);
          setShowDateTimeError(false);
        }}
        onDateParsed={(date: ParssedDateTimeResult): void => {
          mutate({ dateTime: DateTime.fromISO(date.fromString) });
          setIsDateParsing(false);
        }}
        onDateParseError={(value: string) => {
          mutate({ dateTime: value });
          setIsDateParsing(false);
          setShowDateTimeError(true);
        }}
        defaultValue={request.dateTime ? humanDate(request.dateTime.toString(), { time: true, toUTC: true }) : ''}
        showErrorMessage={shouldShowError('dateTime') || showDateTimeError}
        required
      />
      <SwitchableUnitField
        key='trade__price'
        className='form-input__container'
        inputType='number'
        label='Price*'
        inputProps={{
          className: clsx({ 'p-invalid': shouldShowError('price.amount') }),
          showError: shouldShowError('price.amount'),
          keyfilter: 'pnum',
          type: 'number',
          value: `${request.price.amount ?? ''}`,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            mutate({
              price: {
                ...request.price,
                amount: getFixedValue(e.target.value)
              }
            }, 'price.amount');
          }
        }}
        dropdownProps={{
          onChange: (e: DropdownChangeEvent) => mutate({ price: { ...request.price, unit: e.value } }, 'price.unit'),
          options: PRICE_UNITS,
          value: request.price.unit,
        }}
      />
      <SwitchableUnitField
        className='form-input__container'
        inputType='number'
        label='Quantity*'
        inputProps={{
          className: clsx({
            'p-invalid': shouldShowError('quantity.amount')
          }),
          showError: shouldShowError('quantity.amount'),
          keyfilter: 'pnum',
          type: 'number',
          value: `${request.quantity.amount ?? ''}`,
          onChange: (e: ChangeEvent<HTMLInputElement>) => mutate({
            quantity: {
              ...request.quantity,
              amount: getFixedValue(e.target.value)
            }
          }, 'quantity.amount'),
        }}
        dropdownProps={{
          className: clsx({
            'p-invalid': shouldShowError('quantity.unit')
          }),
          showError: shouldShowError('quantity.unit'),
          error: errors?.['quantity.unit'],
          onChange: (e: DropdownChangeEvent) => mutate({ quantity: { ...request.quantity, unit: e.value } }, 'quantity.unit'),
          options: quantityOptions,
          value: request.quantity.unit,
        }}
      />
    </div>
  </section>;
}
