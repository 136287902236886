export enum UISettings {
  AUDIT_DATA_SETTING = 'rate-grid-audit-visibility',
  LAST_VISITED_BALTIC_SPOT = 'last-visited-baltic-spot',
  LAST_VISITED_RATE_GRID = 'last-visited-rate-grid',
  LAST_VISITED_VORTEXA_FREIGHT_GRID = 'last-visited-vortexa-freight-grid',
	IEA_MOD_SETTINGS = 'iea-mod-settings',
	JODI_MOD_SETTINGS = 'jodi-mod-settings',
  POSITION_LIST_WORKSHEETS = 'worksheets-postion-lists',
  CARGO_FLOWS_WORKSHEETS = 'worksheets-cargo-flows',
  REPORTS_LASTOPENED = 'reports-last-opened',
  CARGO_TRACKER_ACTIVE_WORKSHEET = 'cargo-tracker-active-worksheet',
  SURVEILLANCE_ACTIVE_WORKSHEET = 'surveillance-active-worksheet',
  SURVEILLANCE_WORKSHEETS = 'surveillance-worksheets',
  BLOTTER_ACTIVE_WORKSHEET = 'blotter-active-worksheet',
  ARTIS_HISTORICAL_CHARTS = 'artis-historical',
  GRID_CONFIGURATIONS = 'datatable-configuration',
  WORKFLOWS_CONFIG = 'workflows-config',
  ACTIVE_CLDD = 'ActiveCldd',
  DEVICE_TOKEN = 'device-token',
}

export enum ProfileSignalEventTypes {
  CLDD_UPDATED = 'clddUpdated',
}