import { DateTime } from 'luxon';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import { ISearchFacet } from '../CargoFlows';

export type CargoMovementSearchParam = {
    searchTerm: string;
    searchField: EntitySearchFieldsEnum;
}

export type ConditionalFilter = {
    condition: '<' | '>' | '=' | '!=';
    fieldName: string;
    group: string;
    operator?: 'or' | 'and';
    value: string | boolean | number | null; // add others if needed
}

export type ConditionalFilterChange = {
    filters: ConditionalFilter[],
    tokens: ISearchFacet[]
}

export type CargoMovementRequest = {
    callbackId?: string;
    pageSize: number;
    pageNumber: number;
    searches: CargoMovementSearchParam[];
    conditionalFilters: ConditionalFilter[];
    orderByColumns: string[]
}

export type CargoMovementResponse = {
    originalRequest: CargoMovementRequest;
    results: CargoMovementSearchResult[];
    totalPages: number;
    totalResults: number;
}

export type CargoMovementSearchResult = {
    key: string;
    vesselClass: string;
    product: string;
    productGroup: string;
    productCategory: string;
    productGrade: string;
	probability: number;
    charterer: string;
    owner: string;
    loadRegion: string;
    loadCountry: string;
    loadPort: string;
    loadTerminal: string;
    dischargeRegion: string;
    dischargeCountry: string;
    dischargePort: string;
    dischargeTerminal: string;
    endUtc: DateTime;
    startUtc: DateTime;
    quantity: number;
    status: string;
    vesselCubeCapacity: number;
    vesselDwt: number;
    vesselFlag: string;
    vesselImoNumber: string;
    vesselName: string;
    isStsLine: boolean;
    hasSts: boolean;
    voyageId: string;
}

export enum CargoMovementOrderCriteriaEnum {
    VesselName = "VesselName",
    Status = "Status",
    LoadPort = "CalculatedLoadPort",
    DischargePort = "CalculatedDischargePort",
    Product = "CalculatedProduct",
    ProductGrade = "CalculatedProductGrade",
	Probability = "Probability",
    StartUtc = "StartUtc",
    EndUtc = "EndUtc",
    Owner = "CalculatedOwner",
    Charterer = "CalculatedCharterer",
    ProductCategory = "CalculatedProductCategory",
	Quantity = "Quantity",
}

export enum ProbabilityLevels {
    High = 0.9,
    Medium = 0.75,
    Low = 0,
}

export {}