import { Button } from 'primereact/button';

import { UserShareAccess } from '../../Models';

import styles from './UserShareItem.module.scss';

interface UserShareItemProps {
  user: UserShareAccess,
  onRemove?: () => void,
}

export default function UserShareItem({
  user,
  onRemove
}: UserShareItemProps): JSX.Element {
  return (
    <div className={styles.userShareItemContainer}>
      <div>{user.name}</div>
      <div className={styles.userShareItemContainerEmail}>
        <i className="iconoir-mail icon--tiny" />
        <div>{user.email}</div>
      </div>
      <Button
        icon='iconoir-trash icon--small'
        className='p-button-text'
        size='small'
        onClick={onRemove}
      />
    </div>
  )
}
