import { useMemo } from 'react';
import useSWR, { type Key, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';


import { WorkflowApi } from './WorkflowApi';
import { apiWorkflowSetCommissionRate, apiWorkflowStartRequest, 
  Workflow, apiWorkflowSetTitle, AddTaskUpdatePayload, apiworkflowSearchRequest } from '../Models';
import { WorkflowStatusTypeEnum } from '../Models/Enums';

import { getFilteredKeyValuePairs } from 'helpers/Utils/enum';
import { Modify } from 'helpers/Utils/misc';
import { replaceItemAt } from 'helpers/Utils/collections';

export const useGetWorkflowTemplates = () => {

  const { data, error, isLoading } = useSWR(
      `Workflow-Templates`,
      WorkflowApi.getTemplates,
      { revalidateOnFocus: false }
  );

  return { templatesData: data, templatesError: error, templatesLoading: isLoading };
}

export const useProviders = () => {
	const { data } = useSWR(
    'workflow-providers',
    WorkflowApi.getProviders,
    { revalidateOnFocus: false }
  );

	return { providers: data  } 
}


export const useStatusOptions = (provider: number = 0) => {

  const { data } = useSWR(
    'workflow-providers',
    WorkflowApi.getProviders,
    { revalidateOnFocus: false }
  );

  
  return useMemo(() => {
    if (!data) return []

    const { statusTypes } = data?.find(({id}) => id === provider)!

    return getFilteredKeyValuePairs(WorkflowStatusTypeEnum, true, statusTypes, true)
    .map(({key, value}) => ({value, key: key.replace(/(on|for)/gi, (match) => match.toLowerCase())}))

  }, [data, provider]);
}

export const useStartWorkflow = () => {
  const { cache } = useSWRConfig();
  const { trigger: startWorkflow, isMutating, error } = useSWRMutation<Workflow | void, Error, Key, apiWorkflowStartRequest>(
    'start-workflow',
    WorkflowApi.startWorkflow,
    {
      onSuccess(workflow) {

        const { provider } = workflow!;
        const cachekey: string = `workflows.${provider}.cache`;
        
        if (workflow) {
          const currentWorkflows = (cache.get(cachekey)?.data ?? []) as Workflow[];
          cache.set(cachekey, { ...cache.get(cachekey), data: [workflow, ...currentWorkflows] });
        }

      },
    });

  return { startWorkflow, isMutating, error };
}

export const useSearchWorkflows = (arg: Modify<apiworkflowSearchRequest, {workflowProvider: number}>) => {


  const cachekey: string = `workflows.${arg.workflowProvider}.cache`;
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    cachekey,
    () => WorkflowApi.searchWorkflows(cachekey, { arg }),
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading, isValidating, mutate };
}

/**
 * Hook will return a `deleteWorkflow` mutation method, which can be used
 * to trigger the deletion of a workflow.
 * 
 * @returns 
 */
export const useDeleteWorkflow = (): { 
  deleteWorkflow: (workflow: Workflow) => Promise<Workflow | undefined>; isDeleting: boolean; 
  error: Error | undefined } => {

  const { cache } = useSWRConfig();
  

  const { trigger: deleteWorkflow, isMutating, error } = useSWRMutation(
    `delete-workflow`,
    WorkflowApi.deleteWorkflow,
    {
      onSuccess(workflow) {
        const cachekey: string = `workflows.${workflow.provider}.cache`

        const currentWorkflows = (cache.get(cachekey)?.data ?? []) as Workflow[];
        cache.set(cachekey, { ...cache.get(cachekey), data: currentWorkflows.filter(x => x.id !== workflow.id) });
      }
    });

  return { deleteWorkflow, isDeleting: isMutating, error };
}

/**
 * Hook will return a `renameWorkflow` mutation method, which can be used
 * to trigger a change in name for an already existing workflow
 * 
 * @returns 
 */
export const useRenameWorkflow = (): { 
  renameWorkflow: (arg: Modify<apiWorkflowSetTitle, {workflowid: string}>) => Promise<Workflow>, 
  isMutating: boolean, 
  error: Error | undefined; } => 
  {
		const { cache } = useSWRConfig();

    const { trigger: renameWorkflow, isMutating, error } = useSWRMutation(
        `rename-workflow`,
        WorkflowApi.renameWorkflow,
				{
					onSuccess(workflow) {
						const cachekey: string = `workflows.${workflow.provider}.cache`
						
						const currentWorkflows = (cache.get(cachekey)?.data ?? []) as Workflow[];
						const index = currentWorkflows.findIndex(wf => wf.id === workflow.id);
					
						cache.set(cachekey, { ...cache.get(cachekey), data: replaceItemAt(currentWorkflows, workflow, index) });
					}
				}
    );

  return { renameWorkflow, error, isMutating };
}


export const useExportWorkflow = (): { 
  exportWorkflow: (arg: Workflow) => Promise<Blob | undefined>; 
  isMutating: boolean; 
  error: Error | undefined } => 
{
  const { trigger: exportWorkflow, error, isMutating } = useSWRMutation(
      'export-workflow',
      WorkflowApi.exportWorkflow,
  );

  return { exportWorkflow, error, isMutating };
}

export const useAddTaskUpdate = (): { 
  updateTask: (arg: AddTaskUpdatePayload) => Promise<Workflow | undefined>; 
  isUpdating: boolean; 
  error: Error | undefined } => 
  {
  
    const { cache } = useSWRConfig();
    const { trigger: updateTask, isMutating, error } = useSWRMutation(
      'add-task-update',
      WorkflowApi.addTaskUpdate,
      {
        onSuccess(workflow) {
          const cachekey: string = `workflows.${workflow.provider}.cache`

          if (workflow) {
            const currentWorkflows = (cache.get(cachekey)?.data ?? []) as Workflow[];
            const index = currentWorkflows.findIndex(wf => wf.id === workflow.id);

            cache.set(cachekey, { ...cache.get(cachekey), data: replaceItemAt(currentWorkflows, workflow, index) });
          }
        }
      });

  return { updateTask, isUpdating: isMutating, error };
}

/**
 * Hook that returns an updateCommissionRate trigger method for making a remote
 * mutation to the commission value of a workflow
 * 
 * TODO - this sort of mutation shoudl be more generic so it's not tightly
 * coupled to the compliance on-boarding module
 * 
 * @returns 
 */
export const useUpdateCommissionRate = (): { 
  updateCommissionRate: (arg: Modify<apiWorkflowSetCommissionRate, {id: string}>) => Promise<Workflow>, 
  isMutating: boolean, 
  error: Error | undefined; } => 
{
  const { trigger: updateCommissionRate, isMutating, error } = useSWRMutation(
    `update-workflow-commission-rate`,
    WorkflowApi.updateCommissionRate,
  );

  return { updateCommissionRate, error, isMutating };
}

