import { RefObject, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';

import eventBus from 'server/EventBus';
import BorealisBar from 'components/BorealisBar';
import { ToastSeverity, type ToastMessageRef } from 'components/ToastMessage';
import { ServiceCallError } from 'components/Errors/ServiceCalls';
import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { BlotterDataApi, useGetTradeDetails, useDeleteTrade, useSaveTrade } from 'modules/Blotter/Services/BlotterAPI';
import { getValidator } from 'modules/Blotter/Models/Validators';
import { ValidationMode } from 'modules/Blotter/Models/Enums';

import { BrokerSection, ClearingSection, CommentsSection, MainSection } from './Templates/Sections';
import { DeletionPopupFooter, type DeletionPopupFooterReferenceProps } from './Templates/DeletionPopup';

import type { ValidationError } from 'joi';
import type { TradesDataResponse, TradeDetailsRequest } from 'modules/Blotter/Models/BlotterResponse';
import type F from 'types/generic-type';

import './TradeDetails.scss';

interface TradeDetailsProps {
  closePanel: () => void;
  toastRef: RefObject<ToastMessageRef>;
  data: TradesDataResponse | null;
  onTradeUpdated: (trade: TradesDataResponse) => void;
  onTradeDeleted: (id: string) => void;
};

export default function TradeDetails(props: TradeDetailsProps): JSX.Element {
  const { data, closePanel, toastRef: toast, onTradeUpdated, onTradeDeleted } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<F<string> | null>(null);
  const [validationMode, setValidationMode] = useState<ValidationMode>(ValidationMode.None);
  const [isDateParsing, setIsDateParsing] = useState<boolean>(false);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const deletionPopupFooterRef = useRef<DeletionPopupFooterReferenceProps>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { data: details, error, isLoading, isValidating } = useGetTradeDetails(data?.id ?? null);
  const { trigger: saveTrade, isMutating: isSavingTrade } = useSaveTrade();
  const { trigger: deleteTrade, isMutating: isDeletingTrade } = useDeleteTrade();

  const [request, setRequest] = useState<TradeDetailsRequest>(details ?? BlotterDataApi.createEmptyTrade());

  useEffect(() => {
    setRequest(details ?? BlotterDataApi.createEmptyTrade());
    setEditMode(!!details);
    setValidationMode(details ? ValidationMode.TouchedOnly : ValidationMode.CompanyOnly); // if it's edit mode -> enable validation
  }, [details]);

  const mutateTrade = (mutation: Partial<TradeDetailsRequest>, touchedField?: string | string[]): void => {
    setRequest(c => ({ ...c, ...mutation }));

    let tf: string[];

    switch (true) {
      case Array.isArray(touchedField):
        tf = touchedField; break;
      case !!touchedField:
        tf = [touchedField]; break;
      default:
        tf = Object.keys(mutation); break; // get `key` from mutation if undefined
    }

    setTouchedFields(t => [...new Set([...t, ...tf])]); // use `Set` to get rid of duplicates
  };

  const handleSave = async (): Promise<void> => {
    setValidationMode(ValidationMode.All);

    const isValid = await validate(ValidationMode.All);

    if (isValid) {
      try {
        const response = await saveTrade(request);

        if (response) {
          toast.current?.replace({
            title: editMode ? 'Trade updated' : 'Trade added',
            message: editMode ? 'A trade has been successfully updated' : 'A new trade has been successfully added',
            severity: ToastSeverity.SUCCESS
          });

          // update saved item immediately
          onTradeUpdated(BlotterDataApi.mapItemToTradeGridData(response));
          closePanel();
        }
      } catch (e) {
        toast.current?.replace({
          title: 'Error',
          message: 'Sorry, something has gone wrong. Please try again later.',
          severity: ToastSeverity.ERROR
        });
      }
    }
  };

  const hideDeletionPopup = (): Promise<boolean> => eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);

  const handleDelete = async (): Promise<void> => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Delete Trade',
      body: 'Are you sure you want to permanently delete this trade? You can\'t undo this.',
      footer: <DeletionPopupFooter
        ref={deletionPopupFooterRef}
        onCancel={hideDeletionPopup}
        onDelete={onDeleteTrade}
      />,
      size: 'large',
    });
  };

  const onDeleteTrade = async (): Promise<void> => {
    if (!data?.id) {
      return;
    }

    try {
      await deleteTrade(data.id);

      closePanel();
      hideDeletionPopup();
      // delete item from the grid immediately
      onTradeDeleted(data.id);
    } catch (e) {
      toast.current?.replace({
        title: 'Error',
        message: 'Sorry, can\'t delete the trade. Please try again later.',
        severity: ToastSeverity.ERROR
      });
    }
  };

  useEffect(() => {
    deletionPopupFooterRef.current?.setIsDeleting(isDeletingTrade);
  }, [isDeletingTrade]);

  const shouldShowError = (fieldName: string): boolean => {
    return (validationMode === ValidationMode.All ||
      (validationMode === ValidationMode.TouchedOnly && touchedFields.includes(fieldName))) && !!validationErrors?.[fieldName];
  };

  /**
   * Validates 'request' object against given validator.
   * @param validateAll if `true` - validate the whole 'request' object, otherwise validate only company fields
   * (for these fields validation is enabled always since `buyer.company` MUST NOT be the same as `seller.company`)
   * @returns {Promise<boolean>} Promise resolving to `true` if object is valid or to `false` otherwise
   */
  const validate = async (validateMode: ValidationMode): Promise<boolean> => {
    const validator = getValidator([ValidationMode.All, ValidationMode.TouchedOnly].includes(validateMode));

    try {
      await validator.validateAsync(request, { abortEarly: false, convert: true });
      setValidationErrors(null);
      return true;
    } catch (e) {
      const errors = (e as ValidationError).details.reduce((acc, er) => ({ ...acc, [er.path.join('.')]: er.message }), {});

      setValidationErrors(errors);
      console.warn('[Trade Details] validation errors:', errors);

      return false;
    }
  };

  useEffect(() => {
    validate(validationMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, validationMode]);

  if (error) {
    return <ServiceCallError error={error} />;
  }

  // prevent input blinking when request is not loaded yet
  if (((isLoading || isValidating) || (!request.id && data?.id))) {
    return <BorealisBar />;
  }

  return <div className='trade-details__container grow-to-fill direction--column overflow--hidden'>
    <header className='trade-details__header'>{isMobile ?
      <Button
        text
        size='small'
        onClick={closePanel}
        icon='iconoir-nav-arrow-left icon--small'
        className='transparent plain-text'
      >
        Back to list
      </Button> :
      <>
        {editMode ? 'Edit Trade' : 'Add Trade'}
        <Button text icon='iconoir-xmark icon--tiny p-button-icon-only'
          className='close-button'
          onClick={closePanel}
        />
      </>}
    </header>
    <div className='grow-to-fill overflow--y' >
      <form className='direction--column'>
        <MainSection
          mutate={mutateTrade}
          request={request}
          errors={validationErrors}
          shouldShowError={shouldShowError}
          originalData={data}
          setIsDateParsing={setIsDateParsing}
        />
        <BrokerSection
          mutate={mutateTrade}
          request={request}
          validationMode={validationMode}
          errors={validationErrors}
          shouldShowError={shouldShowError}
          sectionKey='buyer'
        />
        <BrokerSection
          mutate={mutateTrade}
          request={request}
          validationMode={validationMode}
          errors={validationErrors}
          shouldShowError={shouldShowError}
          sectionKey='seller'
        />
        <ClearingSection
          mutate={mutateTrade}
          request={request}
          errors={validationErrors}
          shouldShowError={shouldShowError}
        />
        <CommentsSection
          mutate={mutateTrade}
          request={request}
          errors={validationErrors}
          shouldShowError={shouldShowError}
        />
      </form>
    </div>
    <footer className={`grow-to-fill' ${editMode ? 'space-between' : 'align--right'}`}>
      {!isMobile && editMode && <Button
        severity='danger'
        loading={isDeletingTrade}
        onClick={handleDelete}
        size='small'
        text
      >
        Delete
      </Button>}
      <Button
        loading={isSavingTrade || isDateParsing}
        onClick={handleSave}
        severity='success'
        size='small'
      >
        {editMode ? 'Update' : 'Save'}
      </Button>
    </footer>
  </div>;
}
