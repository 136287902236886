import * as React from 'react';

import { DistributionListMessagesSearchResponse } from '../../Models/distribution-list-response';

const HeaderTemplate = (data: DistributionListMessagesSearchResponse): JSX.Element => (
  <>
    <div className="dl-row-group-info">
      <div>{data.subject}</div>
      <div className="dl-row-group-info-subtitle">
        {data.openedMessagesCount}
        &nbsp;out of&nbsp;
        {data.recipientsCount}
        &nbsp;recipients opened
      </div>
    </div>
    <div className="dl-row-group-date">{data.messageDate.toFormat('dd LLL yyyy, HH:mm ZZZZ') ?? ''}</div>
  </>
);

export default HeaderTemplate;