import { useEffect } from 'react';
import { unstable_Blocker, unstable_BlockerFunction, unstable_useBlocker } from 'react-router-dom';

export default function useNavigationBlocker (shouldBlock: boolean | unstable_BlockerFunction, onBlocked: (arg: unstable_Blocker) => void) {
  const blocker = unstable_useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      onBlocked(blocker);
    }
  }, [blocker, onBlocked]);

  return blocker;
}