import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { KeyedMutator } from 'swr';

import NoData from 'components/NoData';
import { UISettings } from 'components/OBXUser/Model/Enums';
import {
  useLoadUserSettings,
  useLoggedInUser,
  useSaveUserSetting,
} from 'components/OBXUser/Services/ProfileHooks';
import ToastMessage, {
  ToastMessageRef,
  ToastSeverity,
} from 'components/ToastMessage';
import AllWorksheets from 'components/Worksheets/AllWorksheets';
import { WorksheetStores } from 'components/Worksheets/Models/Enums';
import { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';
import { useCreateNewWorksheet } from 'components/Worksheets/Services/WorksheetHooks';

import SecondaryNavigation from '../../components/SecondaryNavigation';

import DetailsArea from './Components/DetailsArea';
import Results from './Components/Results';
import SearchBar from './Components/SearchBar';
import { emptyHydrator } from './Components/SearchBar/Models/Parsers';
import SharePopup, { SharePopupReferenceProps } from './Components/SharePopup';
import SubscribePanel from './Components/SubscribePanel';
import { SurveillanceModeEnum } from './Models/Enums';
import { DEFAULT_SEARCH_ITEMS, SearchRequest } from './Models/ReportsRequest';
import { ResultsResponse } from './Models/ReportsResponse';
import { SurveillanceSocket } from './Services/SignalRSocket';

import { isWorksheetSharedWithUser } from 'helpers/Share/FilteringFunctions';
import { notNil } from 'helpers/Utils/misc';
import { stripUUID } from 'helpers/Utils/string';

import './Surveillance.scss';

interface SurveillanceProps {
  resultsMode?: SurveillanceModeEnum;
}

const Surveillance = (props: SurveillanceProps): JSX.Element => {
  const { resultsMode } = props;
  const [leftColumn, setLeftColumn] = useState<boolean>(true);
  const [isLoadingWorksheet, setIsLoadingWorksheet] = useState<boolean>(false);
  const [searchItems, setSearchItems] = useState<SearchRequest | undefined>(
    DEFAULT_SEARCH_ITEMS
  );
  const [resultSelected, setResultSelected] = useState<ResultsResponse | null>(
    null
  );
  const [lastModified, setLastModified] = useState<DateTime>();

  const { worksheetparam } = useParams();
  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { newWorksheet } = useCreateNewWorksheet(WorksheetStores.Surveillance);
  const { signal } = useSignalR();
  const { obxuser } = useLoggedInUser();

  const [activeWorksheetId, setActiveWorksheetId] = useState<
    string | null | undefined
  >(worksheetparam || getSetting(UISettings.SURVEILLANCE_ACTIVE_WORKSHEET));
  const [activeWorksheetName, setActiveWorksheetName] = useState<
    string | null | undefined
  >(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const searchContainerRef = useRef<HTMLElement>(null);

  const activeCldd = getSetting(UISettings.ACTIVE_CLDD);

  useEffect(() => {
    const socket = SurveillanceSocket.instance;
    socket.init(signal);
  }, [signal]);

  const toast = useRef<ToastMessageRef>(null);
  const onShareParamsRef = useRef<SharePopupReferenceProps | null>(null);

  const onWorkSheetShared = (): void => {
    toast.current?.replace({
      title: 'Success',
      message: 'Sharing settings saved successfully',
    });
  };

  const addNewWorksheet = useCallback(async (): Promise<void> => {
    setIsLoadingWorksheet(true);
    setActiveWorksheetId(null);
    const data = await newWorksheet({
      store: WorksheetStores.Surveillance,
      hydrator: emptyHydrator,
      name: 'New Report',
    });

    if (!data || !data.worksheetId) {
      return;
    }

    try {
      trigger({
        setting: UISettings.SURVEILLANCE_ACTIVE_WORKSHEET,
        data: data.worksheetId,
      });
    } catch (e) {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue when storing active worksheet',
        severity: ToastSeverity.WARN,
      });
    }
    console.info('Surveillance worksheet added:', data.worksheetId);
    setActiveWorksheetId(data.worksheetId);
    if (worksheetparam) {
      navigate(location.pathname.replace(worksheetparam, data.worksheetId));
    }
    setIsLoadingWorksheet(false);
  }, [location.pathname, navigate, newWorksheet, trigger, worksheetparam]);

  const changeWorksheetHandler = useCallback(
    (id: string | null | undefined): void => {
      setActiveWorksheetId(id);
      trigger({
        setting: UISettings.SURVEILLANCE_ACTIVE_WORKSHEET,
        data: id,
      });
    },
    [trigger]
  );

  const onWorksheetUpdated = (
    updatedWorksheet: Partial<WorksheetResponse>,
    storedWorksheets: WorksheetResponse[],
    updateSheetsLocally: KeyedMutator<any>,
    reloadWorksheetsList: KeyedMutator<WorksheetResponse[]>
  ): void => {
    const isPrivateWS = updatedWorksheet.isPrivate;
    const isUsersWS = obxuser && updatedWorksheet.createdBy === obxuser.userId;
    const isSharedToUser = obxuser
      ? isWorksheetSharedWithUser(
        updatedWorksheet.sharedWith || [],
        activeCldd,
        obxuser
      )
      : false;
    const isSheetExists = Boolean(
      storedWorksheets.find(
        sheet => sheet.worksheetId === updatedWorksheet.worksheetId
      )
    );

    if (isSheetExists) {
      // Private - isPrivate = true and createdBy current user
      // Shared - isPrivate = false and sharedWith is empty array OR current user's cldd OR current user
      if (isUsersWS || (!isPrivateWS && isSharedToUser)) {
        updateSheetsLocally(
          storedWorksheets.map(s =>
            s.worksheetId === updatedWorksheet.worksheetId
              ? { ...s, ...updatedWorksheet }
              : s
          ),
          { revalidate: false }
        );
      } else {
        updateSheetsLocally(
          storedWorksheets.filter(
            s => s.worksheetId !== updatedWorksheet.worksheetId
          ),
          { revalidate: false }
        );
      }
    } else {
      if (isSharedToUser) {
        reloadWorksheetsList();
      }
    }
  };

  useEffect(() => {
    if (notNil(worksheetparam) && worksheetparam !== activeWorksheetId) {
      changeWorksheetHandler(worksheetparam);
    }
  }, [activeWorksheetId, changeWorksheetHandler, worksheetparam]);

  useEffect(() => {
    if (activeWorksheetId) {
      console.info('Surveillance active worksheet:', activeWorksheetId);
    }
  }, [activeWorksheetId]);

  return (
    <div className="surveillance-container grow-to-fill">
      <aside
        className={clsx('surveillance-reports-list position--relative', {
          hidden: !leftColumn,
        })}
      >
        <header>
          <h1>Reports</h1>
          <Button
            icon="iconoir-plus icon--tiny icon--ob-orange icon--primary"
            className="report-add"
            text
            size="small"
            onClick={addNewWorksheet}
          >
            Add
          </Button>
        </header>
        <div className="surveillance-reports-list-all-worksheets grow-to-fill">
          <AllWorksheets
            activeWorksheetId={activeWorksheetId}
            setActiveWorksheetId={changeWorksheetHandler}
            setActiveWorksheetName={setActiveWorksheetName}
            store={WorksheetStores.Surveillance}
            setting={UISettings.SURVEILLANCE_WORKSHEETS}
            currentRoute={stripUUID(location.pathname)}
            preventDelete
            preventSharingByNonOwners
            shareDialogOverride={{
              dialogSize: 'large',
              header: (name: string) => `Share ${name}`,
              body: SharePopup as (params: any) => JSX.Element,
              bodyParams: { ref: onShareParamsRef },
              onShareParamsRef: onShareParamsRef,
              onShared: onWorkSheetShared,
            }}
            worksheetUpdatedHandler={onWorksheetUpdated}
          />
        </div>
      </aside>
      <div className="surveillance-container-main grow-to-fill position--relative module__canvas">
        <header ref={searchContainerRef}>
          <Button
            size="small"
            className="surveillance-toggle-left-panel"
            text
            icon={`iconoir-sidebar-${
              leftColumn ? 'collapse' : 'expand'
            } icon--tiny`}
            onClick={(): void => setLeftColumn(!leftColumn)}
            tooltip={`${leftColumn ? 'Close' : 'Open'} list of reports`}
            tooltipOptions={{
              position: 'right',
            }}
          />
          <SearchBar
            isLoadingWorksheet={isLoadingWorksheet}
            searchItems={searchItems}
            setSearchItems={setSearchItems}
            activeWorksheet={activeWorksheetId}
            searchContainerRef={searchContainerRef}
            setLastModified={setLastModified}
            resultsMode={resultsMode}
          />
        </header>
        <nav className="surveillance-container-main__nav">
          <SecondaryNavigation items={[
            { path: 'results', label: 'Search Results' },
            { path: 'reviewed', label: 'Reviewed' },
          ]}
          />
          <SubscribePanel isDateSet={!!searchItems?.date} activeWorksheetId={activeWorksheetId} />
        </nav>
        <main
          className={clsx('grow-to-fill', {
            'drawer--active': Boolean(resultSelected),
          })}
          data-cols={Boolean(resultSelected) && '9,3'}
          data-drawer-style={Boolean(resultSelected) && 'slide'}
          data-drawer-position={Boolean(resultSelected) && 'alongside-right'}
        >
          {!notNil(activeWorksheetId) && !isLoadingWorksheet && (
            <NoData
              header="No Report has been added yet"
              icon="iconoir-folder-plus"
              isButton={true}
              buttonText="Add Report"
              buttonAction={addNewWorksheet}
            />
          )}
          {notNil(activeWorksheetId) && (
            <section className="grow-to-fill overflow--hidden">
              <Results
                isLoadingWorksheet={isLoadingWorksheet}
                resultSelected={resultSelected}
                setResultSelected={setResultSelected}
                searchItems={searchItems}
                setSearchItems={setSearchItems}
                lastModified={lastModified}
                activeWorksheetId={activeWorksheetId}
                activeWorksheetName={activeWorksheetName}
                toastRef={toast}
                resultsMode={resultsMode}
              />
            </section>
          )}
          {resultSelected && (
            <aside>
              <DetailsArea
                activeWorksheetId={activeWorksheetId}
                record={resultSelected}
                setRecordSelected={setResultSelected}
                toastRef={toast}
                searchRequestFields={searchItems?.searchRequestFields}
              />
            </aside>
          )}
        </main>
        <ToastMessage ref={toast} />
      </div>
    </div>
  );
};
export default Surveillance;
