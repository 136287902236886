import { useCallback, useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

import { CargoSearchRequest } from '../../Models/CargoTrackerRequest';
import { PetroleumProductEnum, PP_TEXT_MAP } from '../../Models/CargoTrackerResponse';

const CdOptions = {
  CPP: PP_TEXT_MAP[PetroleumProductEnum.TankerClean],
  DPP: PP_TEXT_MAP[PetroleumProductEnum.TankerDirty],
  'CPP/DPP': PP_TEXT_MAP[PetroleumProductEnum.TankerBoth]
};

export const CD_OPTIONS_TO_PPE: { [K in keyof typeof CdOptions]: PetroleumProductEnum } = {
  CPP: PetroleumProductEnum.TankerClean,
  DPP: PetroleumProductEnum.TankerDirty,
  'CPP/DPP': PetroleumProductEnum.TankerBoth
};

const GROUP_NAME: keyof CargoSearchRequest = 'petroleumProductType';

interface CargoSearchCdFilterProps {
  activeFilter?: PetroleumProductEnum;
  onChange: (groupName: keyof CargoSearchRequest, value?: PetroleumProductEnum) => void;
}

const CargoSearchCdFilter = (props: CargoSearchCdFilterProps): JSX.Element => {
  const { activeFilter, onChange } = props;
  const options= [CdOptions.CPP, CdOptions.DPP, CdOptions['CPP/DPP']];
  const [value, setValue] = useState<PetroleumProductEnum | null | undefined>(undefined);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (activeFilter) {
      setChecked(true);
      setValue(activeFilter);
    } else {
      setChecked(false);
      if (value === undefined) {
        setValue(PetroleumProductEnum.TankerClean);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  const handleChange = useCallback((isChecked: boolean, newValue: PetroleumProductEnum | null | undefined): void => {
    onChange(GROUP_NAME, newValue && isChecked ? newValue : undefined);
  }, [onChange]);


  const handleCheckboxChange = (e: CheckboxChangeEvent): void => {
    setChecked(!!e.checked);
    handleChange(!!e.checked, value);
  };

  const handleValueChange = (e: SelectButtonChangeEvent): void => {
    const val = CD_OPTIONS_TO_PPE[e.value as keyof typeof CD_OPTIONS_TO_PPE];
    setValue(val);
    handleChange(checked, val);
  };

  return (
    <div className="additional-filters__container--internal selectable-filter__container">
      <div className="additional-filters__checkbox">
        <Checkbox
          inputId="cd_filter_checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="cd_filter_checkbox">C/D</label>
      </div>
      <SelectButton
        disabled={!checked}
        onChange={handleValueChange}
        options={options}
        unselectable={false}
        value={PP_TEXT_MAP[value ?? PetroleumProductEnum.TankerClean]}
      />
    </div>
  );
};

export default CargoSearchCdFilter;
