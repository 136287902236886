import useSWR, { useSWRConfig, State } from 'swr';
import useSWRMutation from 'swr/mutation';
import { AxiosResponse, AxiosError } from 'axios';

import { ProfileApi, PROFILE_KEY } from './ProfileApi';
import { ProfileResult, ProfileCredentials, GridConfiguration } from '../Model/ProfileResult';
import { UISettings } from '../Model/Enums';

type UserResultTriggerArgs = {
	setting?: string,
	data?: string | object;
}

type SaveUserResult = {
	trigger: (extraArgument: UserResultTriggerArgs | any) => Promise<AxiosResponse<ProfileResult> | undefined>;
	isMutating: boolean;
	error: any;
	data: AxiosResponse<any, any> | undefined;
}

type Setting = {
 key: string,
 value: string;
}

type KnownSetting  = Setting & {
	key: UISettings
}


/**
 * custom hook which returns a trigger method which can be passed a set of values
 * that need saving to the users settings
 *
 * @returns {SaveUserResult}
 */
export const useSaveUserSetting = (): SaveUserResult => {

	const { cache } = useSWRConfig();

	const opts = {
		onSuccess: (d: AxiosResponse) => cache.set(PROFILE_KEY, d),
	}

	const { trigger, error, isMutating, data } = useSWRMutation(
		`setting`,
		ProfileApi.saveSetting, 
		opts
	);

	return { trigger, error, isMutating, data }
}

export const useSaveGridConfig = <T>() => {

	const { trigger, error, isMutating, data } = useSaveUserSetting();

	const { cache } = useSWRConfig();
	const res = cache.get(PROFILE_KEY);
	const current: GridConfiguration<any>[] = JSON.parse(res?.data.uiSettings.find((f: KnownSetting) => f.key === UISettings.GRID_CONFIGURATIONS)?.value ?? "[]");
	
	const update = (config: GridConfiguration<T>) => {
	
		trigger({
			setting: UISettings.GRID_CONFIGURATIONS,
			data: [
				...current.filter(i => i.grid !== config.grid),
				config
			]
		})
	}

	return { update, error, isMutating, data }
}

export const useLoggedInUser = (shouldFetch: boolean = true, opts?: any) => {
	const { data, error, isLoading } = useSWR(shouldFetch ? PROFILE_KEY : null, ProfileApi.getProfile, opts);
	return { obxuser: data, error, isLoading }
}



export const useLoadUserSettings = () => {

	const { cache } = useSWRConfig();
	
	return {
		getSetting: (key: string | null) => {

			const res = cache.get(PROFILE_KEY);
			
			try {

				const result = res?.data.uiSettings.find((f: {key: string, value: string}) => f.key === key)?.value;
				
				return result ? JSON.parse(result) : result;
			
			} catch(e) {
				console.error(`No settings property with $key: '${key}' found`);
				throw(e);
			}
		},
		getGridConfig: <R>(configkey: string): GridConfiguration<R> => {
			
			const res = cache.get(PROFILE_KEY);
			
			const defaultConfig = {
				grid: configkey,
				columns: []
			}

			try {

				const result: GridConfiguration<R> = JSON.parse(res?.data.uiSettings.find((f: KnownSetting) => f.key === UISettings.GRID_CONFIGURATIONS).value)
				.find((conf: GridConfiguration<R>) => conf.grid === configkey);

				return result ?? defaultConfig;

			} catch(e) {
				//	There was not config for this key - so return a blank one. 
				return defaultConfig;
			}
		}
	} 
}


type UserCredentials = {
	creds?: ProfileCredentials;
	error: AxiosError;
	isLoading: boolean;
}

export const useLoadUserCreds = (): UserCredentials => {

	const { cache } = useSWRConfig();
	const { data: user } = cache.get(PROFILE_KEY) as State<ProfileResult>;

	// if (!user) return;

	console.log("current state of cache is ", user)

	const { data, error, isLoading } = useSWR(
		'user-creds', 
		() => ProfileApi.getCreds((user as ProfileResult).name),
		{ revalidateOnFocus: false }
	);

	return { creds: data, error, isLoading }

}
