export enum Section {
  Instrument,
  Buyer,
  Seller,
  Price,
  Quantity
}

// local only
export enum BlotterEntitySearchFieldsEnum {
  Price = -1,
  Quantity = -2
}

export enum ValidationMode {
  None,
  All,
  CompanyOnly,
  TouchedOnly,
}