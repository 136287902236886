import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState, } from 'react';

import BorealisBar from 'components/BorealisBar';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';

import { useValidateEmails } from './Services/SuggestionsAPI';
import GroupShareArea from './GroupShareArea';
import { AccessLevel, ShareRequestParams, UserShareAccess } from './Models';
import UsersShareArea from './UsersShareArea';

import { getClddSharingItems, getUsersSharingItems, } from 'helpers/Share/FilteringFunctions';

import styles from './SharePopup.module.scss';

export interface SharePopupReferenceProps {
  params: ShareRequestParams;
}
interface SharePopupProps {
  worksheet: WorksheetResponse;
}

const SharePopup: (
  props: SharePopupProps,
  ref: ForwardedRef<SharePopupReferenceProps>
) => JSX.Element = (props: SharePopupProps, ref): JSX.Element => {
  const {
    worksheet: { sharedWith, isPrivate },
  } = props;

  const [groupAccessLevel, setGroupAccessLevel] = useState<AccessLevel>(
    AccessLevel.None
  );
  const [clddAccessLevel, setClddAccessLevel] = useState<AccessLevel>(
    AccessLevel.None
  );
  const [clddValues, setClddValues] = useState<string[]>([]);
  const [usersEmails, setUsersEmails] = useState<string[]>([]);
  const [users, setUsers] = useState<UserShareAccess[]>([]);

  const { data: validUsers, isLoading } = useValidateEmails(usersEmails);
  
  const { getSetting } = useLoadUserSettings();
  const activeCldd = getSetting(UISettings.ACTIVE_CLDD);
  
  useEffect(() => {
    setUsers(
      validUsers?.validEmailsDetails?.map(user => ({
        ...user,
        access: AccessLevel.Edit,
      })) || []
    );
  }, [validUsers]);

  useImperativeHandle(
    ref,
    () => {
      const shareWithUsers = users.filter(
        ({ access }) => access === AccessLevel.Edit
      );
      return {
        params: {
          shareWithCldds: clddAccessLevel === AccessLevel.None ? [] : clddValues,
          shareWithUsers: shareWithUsers.map(user => user.email),
          isPrivate:
            groupAccessLevel === AccessLevel.None &&
            shareWithUsers.length === 0,
        },
      };
    },
    [clddAccessLevel, clddValues, groupAccessLevel, users]
  );

  /*
  Private - only owner can see WS
  SharedWithCompany -  isPrivate = false and sharedWith is empty
  SharedWithCldd -  isPrivate = false and sharedWith contains item with a cldd
  SharedWithUser - isPrivate = false and sharedWith contains item with a user
 */
  useEffect(() => {
    const clddsItems = getClddSharingItems(
      sharedWith || []
    );
    const clddsGranted: undefined | string[] = clddsItems.map(({ value }) => value);
    const usersGranted: undefined | string[] = getUsersSharingItems(
      sharedWith || []
    ).map(({ value }) => value);
    setClddValues(clddsGranted);
    setClddAccessLevel(!isPrivate && clddsGranted.length > 0 ? AccessLevel.Edit : AccessLevel.None);
    setGroupAccessLevel(
      !isPrivate && (sharedWith || [])?.length === 0 ? AccessLevel.Edit : AccessLevel.None
    );
    setUsersEmails(usersGranted || []);
  }, [activeCldd, isPrivate, sharedWith]);

  return (
    <div className={styles.sharePopupWindow}>
      {isLoading ? (
        <BorealisBar styleOverrides={styles.sharePopupWindowLoaderBar} />
      ) : (
        <>
          <GroupShareArea
            groupAccessLevel={groupAccessLevel}
            groupAccessLevelChanged={setGroupAccessLevel}
            clddAccessLevel={clddAccessLevel}
            setClddAccessLevel={setClddAccessLevel}
            clddValues={clddValues}
            setClddValues={setClddValues}
          />
          <UsersShareArea users={users} usersListUpdated={setUsers} />
        </>
      )}
    </div>
  );
};

export default forwardRef<SharePopupReferenceProps, SharePopupProps>(
  SharePopup
);
