import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Column, ColumnBodyOptions, ColumnEditorOptions } from 'primereact/column';

import Loader from 'components/Loader';
import { StrongContents } from 'helpers/DataTable/Templates/ColumnTemplates';

import { useLoadRateGrid } from 'modules/Rates/Grids/Services/RateGridHooks';
import { 
	RateGridRoute,
	IRateGridRouteValueResponse,
	RateGridApiColumn,
	ISignalRRateGridValue,
	RateDataTypeEnum
} from 'modules/Rates/Grids/Models/RateGridResponse';
import { RatesGridSignalRMessages, SignalRGridMutationType } from 'modules/Rates/Grids/Models/Enums';
 
import RateCell from '../../Templates/RateCell';

import "./RateGridTable.scss";
import eventBus from 'server/EventBus';

interface IArgs {
  rateGridId: string;
  showAuditData: boolean;
}

export const RateGridTable = (args: IArgs) => {

	const { rateGridId, showAuditData } = args;

	const table = useRef(null);

	const { gridData, error, isLoading, mutateData } = useLoadRateGrid(rateGridId);
	const [ dataRows, setDataRows ] = useState<RateGridRoute<IRateGridRouteValueResponse>[]>();
	const [ columns, setColumns ] = useState<RateGridApiColumn[]>([]);
	const [ visibileColumns, setVisibileColumns ] = useState<number>(0);
	const [ maxDataAge, setMaxDataAge ] = useState<number>(0);
	const [ activeColumnGroup, setActiveColumnGroup ] = useState<string>("");

	const curentRateGridId = useRef<string>();
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

	useEffect(() => {

		//	set up handlers for signalR updates via event bus messages
		const onRateGridChanged = (event: CustomEvent<any>) => {
			// console.log("GRID - update is", event.detail);
		}

		const onRateValuedChanged = (event: CustomEvent<ISignalRRateGridValue>) => {
			//	When messages are for grids we aren't currently displaying we can
			//	ignore them
			if (event.detail.linkedRateGridId !== curentRateGridId.current) return;

			// The message contains a mutation for a grid I dont care about
			mutateData(SignalRGridMutationType.CellValue, event.detail)
		}

		eventBus.on(RatesGridSignalRMessages.RATE_PROJECTION_GRID_CHANGE, onRateGridChanged);
		eventBus.on(RatesGridSignalRMessages.RATE_PROJECTION_VALUE_CHANGE, onRateValuedChanged);

		return () => {
			//	Tear down the event subscriptions when the page unloads
			eventBus.remove(RatesGridSignalRMessages.RATE_PROJECTION_GRID_CHANGE, onRateGridChanged);
			eventBus.remove(RatesGridSignalRMessages.RATE_PROJECTION_VALUE_CHANGE, onRateValuedChanged);
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	useEffect(() => {
    
		if (!gridData) return;
		
		const { columns, routes, dataAgeProfileInHours } = gridData;
		
		setColumns(columns);
		setDataRows(routes);
		setMaxDataAge(dataAgeProfileInHours);

		setVisibileColumns(columns.filter((c: RateGridApiColumn) => !["Sentiment", "TCE", "Prior"].includes(c.name)).length);
		
		setActiveColumnGroup(current => current.length ? current : columns[0].name);

	}, [gridData])


	useEffect(() => {
		if (rateGridId) {
			curentRateGridId.current = rateGridId;
		}

		setDataRows([]);
		setActiveColumnGroup("");
		
	}, [rateGridId]);


	const onColumnFilterChanged = (e: DropdownChangeEvent) => {
		setActiveColumnGroup(e.value.name);
	}

	if (error) {
		return <>
			<div className="error-message">
				It appears something has gone wrong. Please get in touch with the support team on <a href="mailto:support@obxlive.com">support@obxlive.com</a>
			</div>
		</>
	}

	if (isLoading) {
		return <Loader />
	}

	const routeColumnConfig = {
		frozen: !isTabletOrMobile,
		sortable: !isTabletOrMobile
	}


  return <>
		{ gridData && 
		<>
			{ isTabletOrMobile && visibileColumns > 1 &&
				<div className="margin--horizontal--small">
					<Dropdown 
						className="grow-to-fill"
						options={columns.filter(c => !["Sentiment", "TCE", "Prior"].includes(c.name))} 
						optionLabel="name"
						value={columns.find(c => c.columnGrouping === activeColumnGroup && c.name !== "Sentiment")}
						onChange={(e: DropdownChangeEvent) => onColumnFilterChanged(e)} 
					/>
				</div>
			}
			<div className="rate-grid-table__container grow-to-fill">
				
				<DataTable 
					value={dataRows}
					ref={table}
					className={clsx(
						"rates-grid-table grow-to-fill",
						!showAuditData && "no--audit-data"
					)}
					rowClassName={() => "row-aligned--top"}
					removableSort 
					scrollable
					sortField="name"
					sortOrder={1}
					style={{width: isTabletOrMobile ? "100%" : "400px"}}
					editMode="cell">
					<Column 
						header="Route" 
						field="name" 
						body={StrongContents}
						{...routeColumnConfig}>
					</Column>
					{ columns
						.filter(c => c.name !== "Prior")
						.filter((c: RateGridApiColumn) => isTabletOrMobile ? c.columnGrouping === activeColumnGroup : c )
						.map((column: RateGridApiColumn, index: number) => {

						let others;
						return <Column
							key={`${column}-${index}`}
							bodyClassName="contents--fill no--padding" 
							header={column.name}
							body={
								(rowdata: RateGridRoute<IRateGridRouteValueResponse>, opts: ColumnBodyOptions) => {

									const { values, name } = rowdata;

									return <RateCell
										name={name}
										data={values}
										maxDataAge={maxDataAge}
										opts={({...opts})} 
										index={column.displayOrder - 1}
									/>
								}
							}
							>
						</Column>
					})}
				</DataTable>	
			</div>
		</>
	}
	</>;
}


