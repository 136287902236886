export enum GlobalDialogDisplayEvents {
  DISPLAY = 'showDialog',
  HIDE = 'hideDialog',
}

export type DialogSize = 'small' | 'medium' | 'large';

export interface IDialogDisplayEventDetails {
  footer: JSX.Element;
  body: JSX.Element;
  size: DialogSize;
  header?: string;
}
