import { type UIEvent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DataTable, type DataTablePropsSingle, type DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { type VirtualScrollerChangeEvent } from 'primereact/virtualscroller';
import clsx from 'clsx';

import { ServiceCallError } from 'components/Errors/ServiceCalls';
import { DoubleLineSimple, ReadableDate, SingleLineWithAddon } from 'helpers/DataTable/Templates/ColumnTemplates';
import ClearingTemplate from 'modules/Blotter/Templates/Clearing';
import TradeItem from 'modules/Blotter/Components/TradeDetails/Templates/TradeItem';
import { sortByPrimaryAndSecondaryField } from 'helpers/DataTable/SortingFunctions';
import { formatName } from 'helpers/Utils/string';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';
import { MOBILE_GRID_ROW_HEIGHT } from 'modules/Blotter/Models/Consts';

import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';
import type { AxiosError } from 'axios';

import './BlotterDataTable.scss';

const EMPTY_MESSAGE = 'Sorry. No matches for that search…';

interface BlotterDataTableProps {
  isLoading: boolean;
  onLazyLoad: (e: VirtualScrollerChangeEvent) => void;
  onTradeSelected: (arg: TradesDataResponse) => void;
  onTableScroll: (e: UIEvent<HTMLElement>) => void;
  selectedTrade: TradesDataResponse | null;
  trades: TradesDataResponse[] | undefined;
  error?: AxiosError;
};

export default function BlotterDataTable(props: BlotterDataTableProps): JSX.Element {
  const { error, isLoading, onTradeSelected, selectedTrade, onLazyLoad, trades, onTableScroll } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  if (error) {
    return <ServiceCallError error={error} />;
  }

  const handleRowClick = (value: TradesDataResponse): void => {
    onTradeSelected(value);
  };

  const COMMON_PROPS = {
    className: 'blotter-data-table',
    dataKey: 'id',
    emptyMessage: EMPTY_MESSAGE,
    loading: isLoading,
    metaKeySelection: false,
    onSelectionChange: (e: DataTableSelectionSingleChangeEvent<TradesDataResponse[]>): void => onTradeSelected(e.value),
    scrollable: true,
    scrollHeight: 'flex',
    selection: selectedTrade,
    value: trades,
    virtualScrollerOptions: {
      autoSize: true,
      className: 'grow-to-fill',
      itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onLazyLoad,
      lazy: true
    }
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const MOBILE_PROPS = {
    cellClassName: () => 'no--padding',
    className: clsx(COMMON_PROPS.className, 'row--no-border'),
    selectionMode: 'single' as DataTablePropsSingle<TradesDataResponse[]>['selectionMode'],
    showHeaders: false,
    virtualScrollerOptions: {
      ...COMMON_PROPS.virtualScrollerOptions,
      itemSize: MOBILE_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onScroll: onTableScroll,
    },
    children: <Column body={(data: TradesDataResponse): JSX.Element => TradeItem({ data, handleRowClick })} />
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const DESKTOP_PROPS = {
    selectionMode: 'single',
    sortField: 'dateTime',
    sortOrder: -1,
    children: [
      <Column
        key='instrument'
        field='instrument'
        header='Instrument'
        sortable
        sortField='instrument'
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)}
        key='buyerObBroker.userName'
        field='buyerObBroker.userName'
        header='Broker (Buy)'
        sortable
        sortField='buyerObBroker.userName'
      />,
      <Column
        body={DoubleLineSimple}
        key='buyerContactName,buyerCompany'
        field='buyerContactName,buyerCompany'
        header='Buy Side'
        sortable
        sortFunction={(e) => sortByPrimaryAndSecondaryField(e, 'buyerContactName', 'buyerCompany')}
      />,
      <Column
        body={(data: TradesDataResponse) => data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)}
        key='sellerObBroker.userName'
        field='sellerObBroker.userName'
        header='Broker (Sell)'
        sortable
        sortField='sellerObBroker.userName'
      />,
      <Column
        body={DoubleLineSimple}
        key='sellerContactName,sellerCompany'
        field='sellerContactName,sellerCompany'
        header='Sell Side'
        sortable
        sortFunction={(e) => sortByPrimaryAndSecondaryField(e, 'sellerContactName', 'sellerCompany')}
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.quantity.unit)}
        bodyClassName='no--padding blotter-data-table__column--quantity'
        key='quantity.amount'
        field='quantity.amount'
        header='Quantity'
        sortable
        sortField='quantity.amount'
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.price.unit)}
        bodyClassName='no--padding blotter-data-table__column--price'
        key='price.amount'
        field='price.amount'
        header='Price'
        sortable
        sortField='price.amount'
      />,
      <Column
        body={(data: TradesDataResponse, config: ColumnBodyOptions) => ReadableDate(data, config, 'dd LLL yyyy, HH:mm ZZZZ')}
        key='dateTime'
        field='dateTime'
        header='Trade Date, Time'
        sortable
        sortField='dateTime'
      />,
      <Column
        body={ClearingTemplate}
        bodyClassName='no--padding'
        key='clearingCleared'
        field='clearingCleared'
        header='Clearing'
        sortable
        sortField='clearingCleared'
      />
    ]
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  return <DataTable
    key={`${isMobile}`}
    {...COMMON_PROPS}
    {...(isMobile ? MOBILE_PROPS : DESKTOP_PROPS)}
  />;
}