import { SyntheticEvent, useEffect, useRef } from 'react';
import { Location, NavigateFunction,useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-spa-js';
import clsx from 'clsx';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings, useLoggedInUser, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';

import ProfilePanel from './ProfilePanel';

import './LeftMenu.scss';
import { WorkflowProvider } from 'modules/Workflows/Models/Enums';

interface IMenuProps {
  signalRStatus: any,
  user: User | undefined,
  toggleState: Function,
  lowerLevelEnvironment: boolean;
}

const LeftMenu = (props: IMenuProps) => {
  const { toggleState, signalRStatus, user, lowerLevelEnvironment } = props;

  const { logout } = useAuth0();
  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { obxuser } = useLoggedInUser();

  //  Depending on the passed showHiddenModules envionment property, items with
  //  isBeta: true are filtered out. Meaning module will NOT appear in the left menu
  //  in either staging or production
  const items = [
    { glyph: 'stats-report', label: 'Reports', route: '/reports', isBeta: false },
    // { glyph: 'stats-report', label: 'Dashboard', route: '/dashboard', isBeta: false },
    { glyph: 'shield-alert', label: 'Sanctions', route: '/sanctions/vessel', isBeta: false },
    { glyph: 'money-square', label: 'Rates', route: '/rates', isBeta: false },
    { glyph: 'candlestick-chart', label: 'Price Curves', route: '/curves', isBeta: false },
    { glyph: 'map', label: 'Distance Calc', route: '/distance-calculator', isBeta: false },
    { glyph: 'open-book', label: 'Blotter', route: '/blotter', isBeta: false },
    { glyph: 'path-arrow', label: 'Cargo Flows', route: '/cargo-flows', isBeta: false },
    { glyph: 'cylinder', label: 'Cargo Tracker', route: '/cargo-tracker/spot', isBeta: false },
    { glyph: 'building', label: 'Onboarding', route: `/workflows/${getSetting(UISettings.WORKFLOWS_CONFIG)?.provider ?? WorkflowProvider.Onboarding}/${getSetting(UISettings.WORKFLOWS_CONFIG)?.lastOpenStatus ?? '0'}`, isBeta: false },
    { glyph: "send-mail", label: "Distribution Lists", route: "/distlist", isBeta: false },
    { glyph: 'binocular', label: 'Surveillance', route: '/surveillance/results', isBeta: false },
  ]



  const op = useRef<OverlayPanel>(null);
  const toastRef = useRef<ToastMessageRef>(null);

  const currentRoute: Location = useLocation();

  const navigate: NavigateFunction = useNavigate();

  const avatarItems = [
    {
      template: (item: any, options: any) => {
        return (
          <div>
            <Button
              className="progressier-subscribe-button _active"
              data-eligible="Subscribe to notifications"
              data-subscribed="Notifications Subscribed"
              data-blocked="Notifications blocked"
            />
          </div>
        )
      }
    },
    {
      label: "logout",
      icon: "iconoir-log-out",
      command: () => {
        logout({ logoutParams: { returnTo: `${window.location.protocol}//${window.location.host}` } })
      }
    },
  ];

  const handleAvatarSelect = (e: any) => {

    switch (e.code) {
      case "Enter":
        op?.current?.toggle(e);
        break;
      case "Tab":
        if (e.shiftKey) op?.current?.hide();
        break;
      default:
        return;
    }
  }

  const navigateTo = (path: string): void => {
    navigate(path, { state: { closeWorksheets: true } });
  }

  // If there is NO CLDD set -> set first isSelectable to user settings
  useEffect(() => {
    const firstSelectable = obxuser?.cldds.find(cldd => cldd.isSelectable)?.code;
    if (!getSetting(UISettings.ACTIVE_CLDD) && !!firstSelectable) {
      trigger({
        setting: UISettings.ACTIVE_CLDD,
        data: firstSelectable // Select first which is "isSelectable"
      });
    }
  }, [trigger, getSetting, obxuser?.cldds]);

  const showUserProfilePanel = (e: SyntheticEvent) => {
    op.current?.toggle(e);
  };

  const onOverlayPanelShow = (): void => {
    const observer = new MutationObserver((_, obs) => {
      obs.disconnect();
      op.current?.align();
    });

    // Observe changes in subtree (content of overlay)
    op.current &&
      observer.observe(op.current?.getElement(), {
        subtree: true,
        attributes: true,
      });
  };

  return <>
    <nav className="left-menu__container">

      <div
        className="left-menu__items">
        <Button
          icon='iconoir-menu icon--medium icon--primary'
          onClick={() => toggleState()}
        />
        <div className="left-menu__routes">
          {items.filter(i => lowerLevelEnvironment ? i : !i.isBeta).map((ic, ind) => {

            const pathnameSplit = currentRoute.pathname.split("/");
            const isCurrent: boolean = ic.route.indexOf(pathnameSplit[1]) !== -1;
            // mark the previous route as active. It is mostly used ex. when access to module
            // is denied, but we should keep marking the route that we came from
            const isPrevious = ic.route.indexOf(currentRoute.state?.from?.split('/')?.[1]) !== -1;

            return <Button
              text
              key={`${ic.glyph}-${ind}`}
              onClick={() => navigateTo(ic.route)}
              className={clsx('plain-text', {
                'active': isCurrent || isPrevious
              })}
              icon={`iconoir-${ic.glyph} icon--medium`}
            >
              {ic.label}
            </Button>
          })}
        </div>
        <hr />
        {lowerLevelEnvironment &&
          <Button
            text
            className={clsx('plain-text', {
              'active': currentRoute.pathname.split('/')[1] === 'internal'
            })}
            icon='iconoir-test-tube icon--medium'
            onClick={() => navigateTo('internal/user-settings')}
          >
            Internal Test Lab
          </Button>
        }
        {user &&
          <div className='left-menu__avatar'>
            {/* <Menu
              model={avatarItems} popup
              ref={contextMenu}
              onBlur={(e) => contextMenu?.current?.hide(e)}
            /> */}
            <OverlayPanel
              ref={op}
              onShow={onOverlayPanelShow}
              className='left-menu__profile-panel-container'
            >
              <ProfilePanel toastRef={toastRef} />
            </OverlayPanel>
            <div className='left-menu__avatar-container'>
              <Avatar
                image={user.picture}
                shape='circle'
                onClick={showUserProfilePanel}
                onKeyDown={(e): void => handleAvatarSelect(e)}
                tabIndex={0}
              >
                <Badge
                  className={clsx(
                    'icon--small',
                    signalRStatus ? 'iconoir-check' : 'iconoir-xmark'
                  )}
                  severity={signalRStatus ? 'success' : 'danger'}
                />
              </Avatar>
            </div>
          </div>
        }
        <div className='left-menu__logo'>
          <img src="/obx-logo.svg" alt="OBX" />
        </div>
      </div>
      <ToastMessage ref={toastRef} />
    </nav>
  </>;
};

export default LeftMenu;
