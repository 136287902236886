import { ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import { type SwipeEventData, RIGHT, LEFT, useSwipeable } from 'react-swipeable';
import clsx from 'clsx';

interface SwipeableHiddenOptionsProps {
  children: ReactNode;
  elementRef: RefObject<HTMLElement>;
  moveByPixels: number;
  options: ReactNode;
  className?: string;
  scrollableContainerRef?: RefObject<HTMLElement>;
}

export default function SwipeableHiddenOptions(props: SwipeableHiddenOptionsProps): JSX.Element {
  const { children, className, elementRef, moveByPixels = 0, options, scrollableContainerRef } = props;
  const [originalMarginLeft, setOriginalMarginLeft] = useState<string>('0');

  const restoreMargin = useCallback(() => {
    if (elementRef.current) {
      elementRef.current.style.marginLeft = originalMarginLeft;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalMarginLeft]);

  const onSwipedLeft = (): void => {
    if (elementRef.current) {
      elementRef.current.style.marginLeft = `-${moveByPixels}px`;
    }
  };

  const onSwiping = (e: SwipeEventData): void => {
    if (elementRef.current) {
      switch (e.dir) {
        case RIGHT:
          elementRef.current.style.marginLeft = `${e.deltaX > 0 ? 0 : e.deltaX}px`;
          break;
        case LEFT:
          // clear transition so it looks smooth when swiping
          elementRef.current.style.transition = 'unset';
          elementRef.current.style.marginLeft = `${e.deltaX < -moveByPixels ? -moveByPixels : e.deltaX}px`;
          break;
      }
    }
  };

  const onSwiped = () => {
    if (elementRef.current) {
      // set transition so animation is visible on pointer up
      elementRef.current.style.transition = 'margin-left .1s ease-in';
    }
  };

  const gestures = useSwipeable({
    onSwipedLeft,
    onSwipedRight: restoreMargin,
    onSwiping,
    onSwiped,
    onSwipedDown: restoreMargin,
    onSwipedUp: restoreMargin,
  });

  useEffect(() => {
    if (elementRef.current) {
      setOriginalMarginLeft(getComputedStyle(elementRef.current).marginLeft);
    }
  }, [elementRef]);

  useEffect(() => {
    const scrollRef = scrollableContainerRef?.current;

    const onScroll = () => {
      restoreMargin();
    };

    const onPointerDown = (e: PointerEvent) => {
      if (!e.defaultPrevented) {
        restoreMargin();
      }
    }

    // close options on click anywhere
    document.addEventListener('pointerdown', onPointerDown);
    scrollRef?.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('pointerdown', onPointerDown);
      scrollRef?.removeEventListener('scroll', onScroll);
    };
  }, [scrollableContainerRef, restoreMargin]);

  return <div {...gestures} className={clsx('swipeable-hidden-options__container overflow--hidden', className)}>
    {children}
    {options}
  </div>;
}