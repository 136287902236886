import axios, { AxiosResponse } from 'axios';
import useSWR, { MutatorCallback } from 'swr';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { isSearchRequestEmpty } from '../Components/SearchBar/Models/Parsers';
import { SearchRequestPaged } from '../Models/ReportsRequest';

import { parsePropsToDateTime } from 'helpers/Utils/misc';

import type { ExportParams } from '../Models/Export';
import type {
  ResultsResponse,
  SearchFiltersResponse,
  SearchResponse,
} from '../Models/ReportsResponse';

export const useGetResults = (sr: SearchRequestPaged, worksheetId?: string | null, random?: string): {
  data: SearchResponse | undefined | void,
  error: Error | undefined,
  isValidating: boolean,
  isLoading: boolean,
  searchMutate: MutatorCallback<SearchResponse> } => {

  const arg: null | [SearchRequestPaged, string | undefined] = (isSearchRequestEmpty(sr) || !worksheetId) ? null : [sr, random];
  const { data, error, isValidating, isLoading, mutate} = useSWR(
    arg,
    () => SurveillanceApiService.getResults(sr, worksheetId ?? ''),
    { revalidateOnFocus: false }
  );

  return { data, error, isValidating, isLoading, searchMutate: mutate };
};

export const useGetSurveillanceFilters = (): {
  data?: SearchFiltersResponse;
  error?: Error;
} => {
  const { data, error } = useSWR(
    'get-filters',
    () =>
      Promise.all([
        SurveillanceApiService.getFilterCompanies(),
        SurveillanceApiService.getFilterMedia(),
      ]).then(([company, media]) => ({ company, media })),
    { revalidateOnFocus: false }
  );

  return { data, error };
};

export class SurveillanceApiService
{
  static getResults = (data: SearchRequestPaged, worksheetId: string): Promise<SearchResponse> => axios.request({
    url: 'audit/surveillance/search',
    data: data,
    method: 'POST',
    headers: {
      worksheetId
    }
  })
    .then((results: AxiosResponse<SearchResponse>) =>
      ({
        ...results.data,
        results: this.updateResultsData(results.data.results, data.pageNumber, data.pageSize)
      })
    )
    .catch(e => {
      ErrorToastService.handleError(e, [400, 500, 503]);

      throw e;
    });

  static updateResultsData = (data: ResultsResponse[], pageNumber: number, pageSize: number):ResultsResponse[] =>
    data.map((el, index) => this.updateResultsResponse(el, index, pageNumber, pageSize));

  static updateResultsResponse =
    (r:ResultsResponse, index: number, pageNumber: number, pageSize: number):ResultsResponse => ({
      ...r,
      ...parsePropsToDateTime(r, ['startTime', 'stopTime']),
      elementCounter: (pageNumber - 1) * pageSize + index + 1, // Added for testing pagination
    });

  static exportResults = (params: ExportParams, worksheetId: string): Promise<ExportParams | null> =>
    axios<ExportParams>({
      url: 'audit/surveillance/export',
      data: params,
      method: 'POST',
      headers: {
        worksheetId
      }
    })
      .then(() => params)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static getFilterCompanies = (): Promise<string[] | null> =>
    axios<string[]>({
      url: 'audit/surveillance/companies',
      method: 'GET',
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        return null;
      });

  static getFilterMedia = (): Promise<string[] | null> =>
    axios<string[]>({
      url: 'audit/surveillance/media',
      method: 'GET',
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        return null;
      });
}
