import axios from 'axios';
import useSWR from 'swr';

import { ValidateEmailsResponse, ValidateEmailsResult } from '../Models';

export const useValidateEmails = (emails?: string[] | null): ValidateEmailsResponse => {
  const { data, error, isLoading } = useSWR(
		emails ? { key: 'validate-emails', emails } : null, 
		({ emails }) => SuggestionsAPI.validateEmails(emails!),
		{ revalidateOnFocus: false }
	);

	return { data, error, isLoading };
}

export class SuggestionsAPI {
	static validateEmails = (emails: string[]): Promise<ValidateEmailsResult> => {
		return axios.request({
			url: 'profile/validateemails',
			method: 'POST',
			data: {
				emails,
			}
		})
			.then(r => r.data);
	}
}