import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';

import DateTimeRange from 'components/DateTimeRange';

import BlotterSearchEntity from './BlotterSearchEntity';
import TradeSearchContainer from './TradeSearchContainer';

import type { SearchRequest, SearchRequestFields } from 'modules/Blotter/Models/SearchRequest';
import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';

interface TradeSearchProps {
  searchItems: SearchRequest;
  addTrade: () => void;
  clearSearch: () => void;
  handleSearchEntityCallback: (data: SearchRequestFields[]) => void;
  handleDateTimeChangeCallback: (date: ParssedDateTimeResult | null) => void;
  handleExternalItemsChangeCallback: (items: SearchRequestFields[]) => void;
};

export default function TradeSearch(props: TradeSearchProps): JSX.Element {
  const { searchItems, addTrade, clearSearch, handleSearchEntityCallback, handleDateTimeChangeCallback, handleExternalItemsChangeCallback } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isParsingDate, setIsParsingDate] = useState<boolean>(false);

  const { dateTime, prices, quantities, searchRequestFields } = searchItems;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  return <TradeSearchContainer isMobile={isMobile}>
    <div className='blotter-trade-search__container grow-to-fill' ref={containerRef}>
      <BlotterSearchEntity
        callback={handleSearchEntityCallback}
        handleExternalItemsChangeCallback={handleExternalItemsChangeCallback}
        searchContainerRef={containerRef}
        searchItems={searchItems}
      />
      <DateTimeRange
        placeholder='Trade date, time'
        defaultValue={dateTime?.original ?? ''}
        onDateParsed={(data) => {
          setIsParsingDate(false);
          handleDateTimeChangeCallback(data);
        }}
        onEmptyValue={() => handleDateTimeChangeCallback(null)}
        onDateParseError={() => setIsParsingDate(false)}
        onParsingStart={() => setIsParsingDate(true)}
        required={false}
      />
      <Button
        text
        size='small'
        onClick={clearSearch}
        disabled={!(dateTime || searchRequestFields?.length || prices?.length || quantities?.length) || isParsingDate}
      >
        Clear
      </Button>
      {!isMobile && <Button
        size='small'
        onClick={addTrade}
      >
        Add trade
      </Button>}
    </div>
  </TradeSearchContainer>;
}
