import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import clsx from 'clsx';

import SecondaryNavigation from 'components/SecondaryNavigation';
import { notNil } from 'helpers/Utils/misc';

import RateTable from '../BalticRates/Components/RateTable/RateTable';
import PriceHistory from '../BalticRates/Components/PriceHistory/PriceHistory';
import { GridProvider } from '../Grids/Models/GridProvider';

// import type { IAvailableRategrid } from '../Grids/Models/RateGrid';
import { AvailableRateGridsResponse } from '../Grids/Models/RateGridResponse';
import type { RatesConfig } from 'index';

interface BalticOrVortexaProps {
    data: { results: any[] } | undefined;
    items: RatesConfig[];
    lastVisitedGrid: AvailableRateGridsResponse | undefined;
    provider: GridProvider;
    switchDisplayedRate: (rate: AvailableRateGridsResponse) => void;
}

export default function BalticOrVortexa(props: BalticOrVortexaProps): JSX.Element {
    const { data, items, lastVisitedGrid, provider, switchDisplayedRate } = props;

    const { market } = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    const mainModuleCanvas = useRef<HTMLDivElement>(null);
    const nodeRef = useRef<HTMLDivElement>(null);

    const [ currentIndex, setCurrentIndex ] = useState<string | null>();
    const [ numNavItems, setNumNavItems ] = useState<number>(0);
    const [ selectedMarket, setSelectedMarket] = useState<AvailableRateGridsResponse | null>(null);

    const gestures = useSwipeable({
        onSwipedDown: (e) => {
            setCurrentIndex(null);
        },
    });

    const handleSwitchDisplayedRate = (rate: AvailableRateGridsResponse) => {
        setCurrentIndex(null);
        setSelectedMarket(rate);

        switchDisplayedRate(rate);
    }

    useEffect(() => {
        if (data) {
            setNumNavItems((data?.results as any[]).length);
        }


        //  use the market param
        if (data && market && !selectedMarket) {
            const match = data.results.find((i: any) => i.name === market) ?? {}; // empty object so error can be handled by RateTable

            setSelectedMarket(match);
            return;
        }

        let grid = data?.results[0];

        if (lastVisitedGrid && data && data.results.findIndex(item => item.name === lastVisitedGrid.name) >= 0) {
            grid = lastVisitedGrid;
        }

        if (grid && !market && !selectedMarket) {
            handleSwitchDisplayedRate(grid);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, lastVisitedGrid]);

    let tertiary: JSX.Element;

    if (isTabletOrMobile && numNavItems >= 4) {
        tertiary = <div>
          <Dropdown
            value={selectedMarket}
            className="grow-to-fill"
            onChange={ (e) => handleSwitchDisplayedRate(e.value)}
            options={data?.results ?? []}
            optionLabel="name" />
        </div>
      } else {
        tertiary = <div className="p-buttonset">
        {data?.results.map((item: AvailableRateGridsResponse, index: number) => (
          <Button
            onClick={() => handleSwitchDisplayedRate(item)}
            key={index}
            disabled={
              selectedMarket?.name?.toLowerCase() === item.name.toLowerCase()
            }
            size="small"
            className={clsx(
              'p-button--tab-style',
              selectedMarket?.name?.toLowerCase() === item.name.toLowerCase() && 'active')}
            >
            {item.name.replace('_', ' ')}
          </Button>
        ))}
      </div>
      }


    return <>
        <nav className="tabbed-navigation-set__container">
            <SecondaryNavigation items={items}/>
            { data && tertiary }
        </nav>
        <main
            className={clsx(
                'grow-to-fill',
                { 'drawer--active': isTabletOrMobile &&  currentIndex }
            )}
            ref={mainModuleCanvas}
            data-cols="7,5"
            data-drawer-style="over"
            data-drawer-position="bottom"
        >
            <section className="grow-to-fill overflow--hidden">
                { selectedMarket &&
                    <RateTable
                        changeRate={setCurrentIndex}
                        gridProvider={provider}
                        isMobile={isTabletOrMobile}
                        market={selectedMarket.name}
                    />
                }
            </section>
            <CSSTransition
                in={notNil(currentIndex) && isTabletOrMobile}
                nodeRef={nodeRef}
                timeout={260}
                classNames="underlay"
                unmountOnExit
            >
                <div key={1} ref={nodeRef} className="menu-underlay"></div>
            </CSSTransition>
            { currentIndex &&
                <aside className="fit-content--height" {...gestures}>
                    <PriceHistory
                        gridProvider={provider}
                        indexid={currentIndex}
                        market={selectedMarket?.name}
                        setCurrentIndex={setCurrentIndex}
                    />
                </aside>
            }
        </main>
    </>
}