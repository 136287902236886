import axios from "axios";
import useSWRInfinate from "swr/infinite";
// import { useSWRConfig } from "swr";
import { DateTime } from "luxon";
import fileDownload from 'js-file-download';

import { CargoMovementRequest, CargoMovementResponse } from "../Models/CargoMovements";
import { uniqueId, sanitiseSystemString } from "helpers/Utils/string";
import ErrorToastService from 'components/Errors/ErrorToast/Services';

export const useCargoFlows = (params: CargoMovementRequest, callback?: Function) => {

    const url: string = "Search/CargoMovements";
    // const { cache } = useSWRConfig();

    const { data, error, isLoading, size, setSize } = useSWRInfinate(
        (page: any, previous: any) => {

            const { pageNumber, callbackId } = params;

            // If there is no callbackId had not been setup yet
            // wich will indicate the page does not have all params loaded yet
            // then we should return null
            if (!callbackId) return null;

            // otherwise we need to set the new page
            return `${pageNumber}-${callbackId}`;
        },
        () => HistoricalFlowsApi.executeSearch({url, params}),
        {
            onSuccess: (d, k) => {
                //  If theres a callback passed - call it…
                if (callback) callback(d);
            },
            revalidateOnFocus: false
        }
    );

    return { data, error, isLoading, size, setSize }
}

export class HistoricalFlowsApi {


    static executeSearch = ({url, params}: {url: string, params: CargoMovementRequest}) => {
        return axios.post<CargoMovementResponse>(url, params)
        .then((response) => {
            const { results, totalPages, totalResults, originalRequest } = response.data;

            const parsed = results.map(
                r => ({
                        ...r,
                        status: sanitiseSystemString(r.status),
                        key: uniqueId(),
                        endUtc: DateTime.fromISO(`${r.endUtc}`).toUTC(),
                        startUtc: DateTime.fromISO(`${r.startUtc}`).toUTC()
                    })
            )

            return { originalRequest, totalPages, totalResults, results: parsed };

        })
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            throw e;
        });;
    }

    static exportSearch = (params: CargoMovementRequest, available: number, callback: (state: boolean) => void) => {
        callback(true);

		const maxExportable: number = 10000;

		//	Depending on how many search results are available either get everything
		//	or use the maximum the export tool will allow
		params = {
			...params,
			pageSize: (available > maxExportable) ? maxExportable : available
		}

        return axios({
			url: "Search/Export/0",
			data: params,
			responseType: "blob",
			method: "POST"
		})
        .then((response) => {
            //  processing complete - so pass false to the callback
            callback(false);

			//	trigger download of file naming it using current date and time
			let dt: DateTime = DateTime.now();
            fileDownload(response.data, `historical-cargoes-${dt.toISODate()}-${dt.hour}-${dt.minute}.xlsx`, "application/vnd.ms-excel");
        })
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            //	TODO - handle any errors…
            throw e;
        });
    }

}