import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import { ResultsResponse } from '../../Models/ReportsResponse';

import { ExportParams } from 'modules/Surveillance/Models/Export';
import { SearchRequest } from 'modules/Surveillance/Models/ReportsRequest';
import { SurveillanceApiService } from 'modules/Surveillance/Services/SurveillanceService';

import styles from './ExportPopup.module.scss';

enum TimeZoneOptions {
  utc = 'utc',
  local = 'local',
}
type TimeZoneLabels = { [key in TimeZoneOptions]: string };

export interface ExportPopupReferenceProps {
  submit: () => Promise<ExportParams | null>;
}
export interface ExportPopupProps {
  activeWorksheetId: string;
  worksheetName: string;
  searchParams: SearchRequest;
  selectedItems: ResultsResponse[];
  recordsCount: number;
  resultsLength: number;
  isSelectAll: boolean;
}

const timeZoneLabels: TimeZoneLabels = {
  [TimeZoneOptions.utc]: 'UTC',
  [TimeZoneOptions.local]: 'Local to User',
};

const ExportPopup = forwardRef<ExportPopupReferenceProps, ExportPopupProps>(
  ({
    activeWorksheetId,
    worksheetName,
    searchParams,
    selectedItems,
    recordsCount,
    resultsLength,
    isSelectAll }, ref): JSX.Element => {
    const [shouldExportAudio, setShouldExportAudio] = useState<boolean>(false);
    const [shouldExportAll, setShouldExportAll] = useState<boolean>(false);
    const [shouldExportTranscriptions, setShouldExportTranscriptions] =
      useState<boolean>(false);
    const [shouldIncludeAttachments, setShouldIncludeAttachments] =
      useState<boolean>(false);
    const [shouldExportMetadata] = useState<boolean>(true);
    const [exportParams, setExportParams] = useState<SearchRequest>({});
    const [exportTimeZone, setExportTimeZone] = useState<TimeZoneOptions>(
      TimeZoneOptions.utc
    );

    useEffect(
      () =>
        setExportParams(shouldExportAll ?
          searchParams :
          {searchRequestFields: selectedItems.map(el => (
            { searchTerm: null,
              searchField: EntitySearchFieldsEnum.SurveillanceSearchKey,
              entityId: el.id,
              metaData: [
                {
                  key: 'partitionKey',
                  value: el.partitionKey
                }
              ]
            }))
          })
      ,
      [searchParams, selectedItems, shouldExportAll]
    );

    useImperativeHandle(ref, () => ({
      submit: () =>
        SurveillanceApiService.exportResults(
          {
            ...exportParams,
            reportName: worksheetName,
            includeAudio: shouldExportAudio,
            includeTranscriptions: shouldExportTranscriptions,
            includeAttachments: shouldIncludeAttachments,
            isTimeZoneLocalToUser: exportTimeZone === TimeZoneOptions.local,
          },
          activeWorksheetId
        ),
    }));

    return (
      <form>
        <div className={styles.surveillanceExportPopup}>
          {isSelectAll &&
            <div className={styles.surveillanceExportPopupInfo}>
              <div className={styles.surveillanceExportPopupInfoHeader}>
                <i className="iconoir-info-circle icon--small" />
                You&apos;re about to export {selectedItems.length} selected results
              </div>
              <div>
                Please be aware that there are {recordsCount - selectedItems.length} more results that match your search
                criteria but haven’t been selected. Would you like to include
                them?
              </div>
              <div>
                <Checkbox
                  inputId="surveillance-export-popup-export-all-input"
                  checked={shouldExportAll}
                  onChange={({ checked }: CheckboxChangeEvent):void =>
                    setShouldExportAll(Boolean(checked))
                  }
                />
                <label htmlFor="surveillance-export-popup-export-all-input">
                  Export all {recordsCount} results
                </label>
              </div>
            </div>}
          <div>
            <label className={styles.surveillanceExportPopupSectionHeader}>
              Include in report
            </label>
            <div className={styles.surveillanceExportPopupIncludeInReport}>
              <div>
                <Checkbox
                  inputId="surveillance-export-popup-include-audio-input"
                  checked={shouldExportAudio}
                  onChange={({ checked }: CheckboxChangeEvent) =>
                    setShouldExportAudio(Boolean(checked))
                  }
                />
                <label htmlFor="surveillance-export-popup-include-audio-input">
                  Audio files
                </label>
              </div>
              <div>
                <Checkbox
                  inputId="surveillance-export-popup-include-transcriptions"
                  checked={shouldExportTranscriptions}
                  onChange={({ checked }: CheckboxChangeEvent) =>
                    setShouldExportTranscriptions(Boolean(checked))
                  }
                />
                <label htmlFor="surveillance-export-popup-include-transcriptions">
                  Transcriptions
                </label>
              </div>
              <div>
                <Checkbox
                  inputId="surveillance-export-popup-include-attachments"
                  checked={shouldIncludeAttachments}
                  onChange={({ checked }: CheckboxChangeEvent) =>
                    setShouldIncludeAttachments(Boolean(checked))
                  }
                />
                <label htmlFor="surveillance-export-popup-include-attachments">
                  Attachments
                </label>
              </div>
              <div>
                <Checkbox
                  inputId="surveillance-export-popup-include-metadata"
                  checked={shouldExportMetadata}
                  disabled={true}
                />
                <label htmlFor="surveillance-export-popup-metadata">
                  Metadata
                </label>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="postion-visibility"
              className={styles.surveillanceExportPopupSectionHeader}
            >
              Time zone
            </label>
            <div
              className={clsx(
                'form-input--radio-buttons',
                styles.surveillanceExportPopupTimezoneRadioButtons
              )}
            >
              {Object.values(TimeZoneOptions).map(key => {
                const value = timeZoneLabels[key];
                return (
                  <div key={`${key}-${value}`}>
                    <RadioButton
                      id={`radio-${key}`}
                      value={key}
                      checked={key === exportTimeZone}
                      onChange={({ value }: RadioButtonChangeEvent) =>
                        setExportTimeZone(value)
                      }
                    />
                    <label htmlFor={`radio-${key}`}>{value}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    );
  }
);

ExportPopup.displayName = 'ExportPopup';

export default ExportPopup;
