import { KeyboardEvent, useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

import { useAreasToAvoid } from '../../Services/CalculateDistances';

import type { AreaToAvoidRequest, AreaToAvoid, AreaToAvoidRequestExtended } from 'modules/DistanceCalc/Models/AreasToAvoidResponse';

import './Avoidings.scss';

interface AvoidingsProps {
  initialValue: AreaToAvoidRequest[] | undefined;
  handleAreasToAvoidChange: (args: AreaToAvoidRequest[]) => void;
}

export default function Avoidings(props: AvoidingsProps): JSX.Element {
  const { initialValue, handleAreasToAvoidChange } = props;
  const [areasToAvoid, setAreasToAvoid] = useState<(AreaToAvoidRequestExtended & AreaToAvoid)[]>([]);

  const { data: areasToAvoidData, isLoading: areasToAvoidIsLoading, error: areasToAvoidError } = useAreasToAvoid();

  useEffect(() => {
    const newData = areasToAvoidData?.map((avoid) => {
      const found = initialValue?.find(a => a.id === avoid.id);

      return {
        ...found,
        ...avoid,
        value: found?.value ?? true
      }});

    if (!areasToAvoidIsLoading && !areasToAvoidError) {
      setAreasToAvoid(newData ?? []);

      if (areasToAvoid.length === 0) {
        handleAreasToAvoidChange(newData ?? []);
      }
    }
  }, [areasToAvoid.length, areasToAvoidData, areasToAvoidError, areasToAvoidIsLoading, handleAreasToAvoidChange, initialValue]);

  function handleCheckboxChange(event: CheckboxChangeEvent): void {
    const updatedData = areasToAvoid.map((avoid) => {
      return {
        ...avoid,
        value: avoid.id === event.value.id ? !event.checked ?? false : avoid.value,
        overridden: avoid.overridden || avoid.id === event.value.id,
      };
    });

    handleAreasToAvoidChange(updatedData ?? []);
    setAreasToAvoid(updatedData);
  }

  function handleKeyDown(event: KeyboardEvent<HTMLDivElement>): void {
    // simulate click on "Enter"
    if (event.key === "Enter") {
      (event.target as HTMLDivElement).click();
    }
  }

  return <div className="grow-to-fill avoidings__container">
    <div className="avoidings__title">Avoid</div>
    <div className="avoidings__checkboxes">
      {areasToAvoid?.map(area =>
        <div className="checkbox__item" key={area.id}>
          <Checkbox
            checked={!area.value}
            inputId={area.id}
            onChange={handleCheckboxChange}
            onKeyDown={handleKeyDown}
            value={area}
          />
          <div className="checkbox__label">
            <label htmlFor={area.id}>{area.name}</label>
            <small>{area.description}</small>
          </div>
        </div>
      )}
    </div>
  </div>;
}