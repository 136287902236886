import { useCallback, useReducer } from 'react';
import { AxiosError } from 'axios';
import useSWR, { Key, KeyedMutator, State, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import eventBus from 'server/EventBus';

import {
	WorksheetsAPI,
	useSaveTokens,
	useSaveProps,
	type IParserFunctions,
	type WorksheetMutationPayload,
	type RenameWorksheetPayload,
	type DeleteWorksheetPayload,
	type CreateWorksheetPayload, 
	type ShareWorksheetPayload
} from "./WorksheetsAPI";
import { WorksheetSignalMessageEventTypes, WorksheetStores } from '../Models/Enums';

import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';

import type { WorksheetMetaProps, WorksheetResponse, WorksheetSearchField } from '../Models/WorksheetResponse'
import type { UISettings } from 'components/OBXUser/Model/Enums';


interface IRenameWorksheetResponse {
	renameSheet: (params: RenameWorksheetPayload) => Promise<WorksheetResponse | undefined>,
	error?: boolean,
}

export const useRenameWorksheet = (store: WorksheetStores): IRenameWorksheetResponse => {

	const { cache } = useSWRConfig();
	const { updateSheets } = useUpdateLocalWorksheetList(store);

	const { trigger, error } = useSWRMutation<WorksheetResponse, boolean, Key, RenameWorksheetPayload>(
		`rename`,
		WorksheetsAPI.renameWorksheet,
		{
			onSuccess: (d: WorksheetResponse) => {
				const { worksheetId, name } = d;

				//	Update the current cache of renamed worksheet so the renamed file
				//	get the updated name
				cache.set(`worksheet-${worksheetId}-${store}`, d as State<WorksheetResponse>);

				const { data } = cache.get(`worksheet-all-${store}`) as State<WorksheetResponse[]>;

				if (!data) return;

				updateSheets(
					[...data.map((c: WorksheetResponse) => {
						return (c.worksheetId !== d.worksheetId)
						? c
						: {...c, ...d}})
					],
					{ revalidate: false }
				)


				eventBus.dispatch(WorksheetSignalMessageEventTypes.WORKSHEET_RENAMED, {worksheetId, name});
			}
		}
	)

	return { renameSheet: trigger, error }
}

export const useUpdateLocalWorksheetList = (store: WorksheetStores | null) => {
	const { mutate } = useSWR(`worksheet-all-${store}`)

	return { updateSheets: mutate }
}

interface IShareWorksheetResponse<T> {
  error: boolean;
  isSharing: boolean;
  shareWorksheet: (
    params: ShareWorksheetPayload<T>
  ) => Promise<WorksheetResponse | null | undefined>;
}

export const useShareWorksheet = function useShareWorksheet<T>(
  store: WorksheetStores
): IShareWorksheetResponse<T> {

	const { cache } = useSWRConfig();
	const { updateSheets } = useUpdateLocalWorksheetList(store);

  const { trigger, error, isMutating } = useSWRMutation<
    WorksheetResponse | null,
    any,
    Key,
    ShareWorksheetPayload<T>
  >(
		'worksheet-share',
		WorksheetsAPI.shareWorksheet,
		{
			onSuccess: (d) => {

				const { data: current } = cache.get(`worksheet-all-${store}`) as State<WorksheetResponse[]>;

				if (!current || !d) {
					return;
				}
      	// const current = cache.get(`worksheet-all-${store}`);

				const filtered: WorksheetResponse[] = current.filter(i => i.worksheetId !== d.worksheetId) as WorksheetResponse[];

				//	update the local cache by mutating the item in the collection
				//	that has just had it's shared status updated
				updateSheets([...filtered, d], { revalidate: false })
			},
		}
	)

  return { shareWorksheet: trigger, error, isSharing: isMutating }
}

interface ICreateWorksheetResponse {
	newWorksheet: (params: CreateWorksheetPayload) => Promise<WorksheetResponse | undefined>,
	isCreating: boolean;
}

export const useCreateNewWorksheet = (store: WorksheetStores): ICreateWorksheetResponse => {

  const { cache } = useSWRConfig();
  const { updateSheets } = useUpdateLocalWorksheetList(store);

  const { trigger, isMutating } = useSWRMutation<WorksheetResponse | undefined, AxiosError, Key, CreateWorksheetPayload>(
		`worksheet-new`,
		WorksheetsAPI.createNewWorksheet,
		{
			revalidate: false,
			onSuccess: (d) => {

        		const { data } = cache.get(`worksheet-all-${store}`) as State<WorksheetResponse[]> ?? {};

				if (!data || !d) return;

				//	set local cache of the stores worksheet list to an updated version
				//	with the deleted worksheet filtered out
				updateSheets([...data, {...d, currentlyOpen: true}], { revalidate: false });

				console.log("new worksheet created", d);
			}
		}
	)

	return { newWorksheet: trigger, isCreating: isMutating }
}

interface ILoadWorksheetResponse {
	data: WorksheetResponse | undefined;
	error: AxiosError;
	isLoading: boolean;
}

/**
 * Custom hook which given a value store type and id, will load the contents
 * of a worksheet. Contensts of the results returned theough the API are
 * sanitised through an optional set of parsers to hydrate with any required
 * default values
 *
 * @param {WorksheetStores} store
 * @param {(string | undefined)} sheet
 * @param {IParserFunctions} parsers
 * @returns {ILoadWorksheetResponse}
 */
export const useLoadWorksheet = (store: WorksheetStores, sheet: string | undefined, parsers: IParserFunctions): ILoadWorksheetResponse => {

	const { data, error, isLoading } = useSWR<WorksheetResponse>(
		`worksheet-${sheet ?? "last"}-${store}`,
		sheet ? () => WorksheetsAPI.getWorksheetById(store, sheet, parsers) : () => WorksheetsAPI.getLast(store, parsers),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading }
}

interface INukeWorksheetResponse {
	error?: Error;
	deleteWorksheet: (params: DeleteWorksheetPayload) => Promise<WorksheetResponse | undefined>;
}

export const useNukeWorksheet = (store: WorksheetStores | null): INukeWorksheetResponse => {

	const { cache } = useSWRConfig();
	const { updateSheets } = useUpdateLocalWorksheetList(store);

	const { trigger, error } = useSWRMutation<WorksheetResponse, Error, Key, DeleteWorksheetPayload>(
		`deletion`,
		WorksheetsAPI.deleteWorksheet,
		{
			onSuccess: (d: WorksheetResponse) => {
				const { data } = cache.get(`worksheet-all-${store}`) as State<WorksheetResponse[]>;

				if (!data) return;

				const filtered: WorksheetResponse[] = data.filter(s => s.worksheetId !== d.worksheetId);

				//	set local cach of the stores worksheet list to an updated version
				//	with the deleted worksheet filtered out
				updateSheets([...filtered], { revalidate: false });

				const cacheData = (cache.get(`worksheet-${d.worksheetId}-${store}`) as State<WorksheetResponse[]>)?.data;

				if (!cacheData) return;

				cache.set(`worksheet-${d.worksheetId}-${store}`, { ...cache.get(`worksheet-${d.worksheetId}-${store}`), data: undefined });
				cache.set(`worksheet-last-${store}`, { ...cache.get(`worksheet-last-${store}`), data: undefined });
			}
		}
	)

	return { deleteWorksheet: trigger, error }
}

export const useAllWorksheets = (
  store: WorksheetStores,
  setting: UISettings,
  resultsFilter?: (
    item: WorksheetResponse,
    index?: number,
    array?: WorksheetResponse[]
  ) => boolean
): {
  sheets?: WorksheetResponse[];
  error: any;
  isLoading: boolean;
  mutate: KeyedMutator<WorksheetResponse[]>;
} => {
  const { getSetting } = useLoadUserSettings();

  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR<WorksheetResponse[]>(
    `worksheet-all-${store}`,
    () =>
      WorksheetsAPI.getAllWorksheets(store, getSetting(setting), resultsFilter),
    { revalidateOnFocus: false }
  );

  return { sheets: data, error, isLoading, mutate };
};

export enum WorksheetMutationTypes {
	Full = 0,
	Fields = 1,
	Props = 2,
}

export type { WorksheetMutationPayload };

interface MutationAction {
	type: WorksheetMutationTypes;
	payload: WorksheetMutationPayload;
}

// TODO: Workaround(?) - check if that can be handled better.
// `mutateTokens` is declared inside `useMutateWorksheet` hook and because of this 'worksheetid', that is passed as an argument
// to this function on the first declaration, is used in the closure of `mutateTokens` method
// even if the updated worksheetid param is passed.
// Use global scoped variable to set proper worksheetId in the `saveTokens` payload.
// https://dev.azure.com/oilbrokerage/OBXchange/_workitems/edit/1815
let worksheetId: string | undefined;

export const useMutateWorksheet = (store: WorksheetStores, worksheetid: string | undefined) => {
	worksheetId = worksheetid;

	const mutationReducer = (state: WorksheetResponse, action: MutationAction): WorksheetResponse => {

		const { type, payload } = action;

		//	Depending on the payload - mutate the appropriate parts of the
		//	search params
		switch (type) {
			case WorksheetMutationTypes.Full:
				return payload as WorksheetResponse;
			case WorksheetMutationTypes.Fields:

				//	The mutation is a change to the fields collection
				return { ...state, fields: payload as WorksheetSearchField[] }

			case WorksheetMutationTypes.Props:

				//	The mutation is a change to the additional properties array
				return { ...state, additionalSearchProperties: payload as WorksheetMetaProps[] }

			default:
				return state;
		}
	}

	const [ worksheet, dispatcher ] = useReducer(mutationReducer, {} as WorksheetResponse, undefined);

	const { saveProps, isMutating: propMutating } = useSaveProps(worksheet?.worksheetId);
	const { saveTokens, isMutating : tokensMutating} = useSaveTokens(worksheet?.worksheetId);

	const mutateTokens = useCallback((payload: WorksheetMutationPayload) => {
		if (worksheetId) {
			//	local mutation change…
			dispatcher({ type: WorksheetMutationTypes.Fields, payload });

			// trigger a remote mutation
			saveTokens({
				store,
				sheet: worksheetId,
				data: payload
			});
		}
	}, [saveTokens, store]);

	const mutateAdditionalProps = useCallback((payload: WorksheetMutationPayload) => {
		if (worksheetId) {

			//	local mutation change…
			dispatcher({ type: WorksheetMutationTypes.Props, payload });

			// trigger a remote mutation
			saveProps({
				store,
				sheet: worksheetId,
				data: payload
			});
		}
	}, [saveProps, store]);

	return {
		worksheet,
		mutateWorksheet: dispatcher,
		mutateTokens,
		mutateAdditionalProps,
		isMutating: propMutating || tokensMutating,
	};
};
