import {
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Dropdown } from 'primereact/dropdown';

import NotAvailable from 'components/NotAvailable';

import { DetailsVersion, TranscriptionComponentProps } from '../../Models';

import TranscriptionsFooter from './Components/TranscriptionFooter';

import { uniqueId } from 'helpers/Utils/string';

import styles from './TranscriptionComponent.module.scss';
import MarkTextComponent from './Components/MarkTextComponent';

const DATE_FORMAT = 'dd LLL HH:mm:ss';

const isTranscriptionsEqual = (
  a?: DetailsVersion,
  b?: DetailsVersion
): boolean => a?.updatedAt === b?.updatedAt;

const TranscriptionComponent = ({
  className,
  transcriptions,
  highlights,
  isEditable,
  saveTranscriptionVersion,
}: TranscriptionComponentProps): JSX.Element => {
  const [selectedVersion, setSelectedVersion] = useState<
    DetailsVersion | undefined
  >(transcriptions?.[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDisabled, setIsEditingDisabled] = useState(false);
  const [editTranscriptionText, setEditTranscriptionText] = useState<
    string | undefined
  >(selectedVersion?.text);
  const [transcriptionText, setTranscriptionText] = useState<
    string | undefined
  >(selectedVersion?.text);

  const contentRef = useRef<HTMLTextAreaElement>(null);

  const isCurrent = useCallback(
    (selected?: DetailsVersion): boolean =>
      selected?.updatedAt === transcriptions?.[0]?.updatedAt,
    [transcriptions]
  );

  useEffect(() => {
    setSelectedVersion(transcriptions?.[0]);
  }, [transcriptions]);

  useEffect(() => {
    setIsEditing(false);
    if (isCurrent(selectedVersion)) {
      setTranscriptionText(highlights?.[0] || selectedVersion?.text);
    } else {
      setTranscriptionText(selectedVersion?.text);
    }
    setEditTranscriptionText(selectedVersion?.text);
  }, [selectedVersion, highlights, isCurrent]);

  useEffect(() => {
    setIsEditingDisabled(
      !isTranscriptionsEqual(selectedVersion, transcriptions?.[0]) ||
        !transcriptionText?.trim()
    );
  }, [transcriptions, selectedVersion, transcriptionText]);

  const isVersionsVisible = transcriptions && transcriptions?.length > 1;

  const versionToOption = (item?: DetailsVersion): string | undefined =>
    isTranscriptionsEqual(item, transcriptions?.[0])
      ? 'Current version'
      : item?.isOriginal
        ? 'Original version'
        : item?.updatedAt &&
          `${DateTime.fromISO(item?.updatedAt).toFormat(DATE_FORMAT)} - ${
            item?.updatedByName
          }`;

  const onSave = (): void => {
    saveTranscriptionVersion &&
      editTranscriptionText &&
      saveTranscriptionVersion(editTranscriptionText).then(() =>
        setIsEditing(false)
      );
  };
  const onTranscriptionChanged = (e: FormEvent<HTMLTextAreaElement>): void => {
    setEditTranscriptionText(e.currentTarget.value);
  };
  const onTranscriptionKey = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key.toLowerCase() === 'escape') {
      onCancel();
    }
  };
  const onEdit = (): void => {
    setIsEditing(true);
  };
  const onCancel = (): void => {
    setIsEditing(false);
    if (isCurrent(selectedVersion)) {
      setTranscriptionText(highlights?.[0] || selectedVersion?.text);
    } else {
      setTranscriptionText(selectedVersion?.text);
    }
  };

  return (
    <div className={styles.container}>
      {isVersionsVisible && (
        <div className={styles.versions}>
          <Dropdown
            options={transcriptions}
            optionLabel='text'
            valueTemplate={(item): JSX.Element => (
              <div>{versionToOption(item)}</div>
            )}
            value={selectedVersion}
            onChange={({ value }): void => setSelectedVersion(value)}
            itemTemplate={versionToOption}
          />
        </div>
      )}
      <div className={clsx(styles.content, className)}>
        <div className={clsx(styles.messages, 'grow-to-fill')}>
          {selectedVersion?.text ? (
            <>
              <textarea
                ref={contentRef}
                hidden={!isEditing}
                value={editTranscriptionText}
                onChange={onTranscriptionChanged}
                onKeyUp={onTranscriptionKey}
              />
              <div
                className={clsx(
                  styles.highlightsSection,
                  isEditing ? 'hidden' : 'direction--column'
                )}
              >
                {transcriptionText?.split('\n').map(message => (
                  <div key={uniqueId()}>
                    <MarkTextComponent>{message}</MarkTextComponent>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NotAvailable label='Empty' />
          )}
        </div>
      </div>
      <footer>
        <TranscriptionsFooter
          isEditable={isEditable === true}
          isDisabled={isEditingDisabled}
          isEditing={isEditing}
          onEdit={onEdit}
          onCancel={onCancel}
          transcription={selectedVersion}
          onSave={onSave}
        />
      </footer>
    </div>
  );
};

export default TranscriptionComponent;
