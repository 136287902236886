import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable, DataTableBaseProps, type DataTableSortEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from '../../Models/Enums';

import CreateNewButton from './CreateNewButton';
import DroppedTooltip from './DroppedTooltip';
import EmptyMessage from './EmptyMessage';
import HeaderTemplate from './HeaderTemplate';
import ResendButton from './ResendButton';

import { sortBySelectedField } from 'helpers/DataTable/SortingFunctions';

import type F from 'types/generic-type';

import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat,
  DistributionListResponse,
} from '../../Models/distribution-list-response';


interface MessageDesktopProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSubjectSearch: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  isCreateDisabled: boolean;
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setMessages: Dispatch<SetStateAction<DistributionListMessagesSearchResponse[] | undefined>>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  messages?: DistributionListMessagesSearchResponse[];
  activeDlId?: DistributionListResponse['id'];
}

const MessageDesktop = (props: MessageDesktopProps): JSX.Element => {
  const {
    messages,
    setMessages,
    searchValue,
    setSearchValue,
    setSubjectSearch,
    isLoading,
    isCreateDisabled,
    handleClose,
    toast,
    activeDlId,
    setActiveDetailTab,
  } = props;

  const initialSort: DataTableBaseProps<DistributionListMessagesSearchResponseFlat[]> = useMemo(() => ({
    sortMode: 'multiple',
    multiSortMeta: [{field: 'messageDate', order: -1}, {field: 'id', order: 1}],
  }), []);

  const [expandingDisabled, setExpandingDisabled] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<DistributionListMessagesSearchResponseFlat[]>([]);
  const [messagesUngrouped, setMessagesUngrouped] = useState<DistributionListMessagesSearchResponseFlat[] | undefined>(undefined);
  const [sortConfig, setSortConfig] = useState<DataTableBaseProps<DistributionListMessagesSearchResponseFlat[]>>(initialSort);
  const { emailIdParam } = useParams();

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  // Convert [{id: 1, recipients: [{status: a}, {status: b}]}] => [{id: 1, status: a}, {id: 1, status: b}]
  const convertMessages = useCallback((data?: DistributionListMessagesSearchResponse[]): DistributionListMessagesSearchResponseFlat[] => {
    const elements: DistributionListMessagesSearchResponseFlat[] = [];
    data?.forEach(
      el => el.recipients.forEach(((recipient, index) => {
        elements.push({ ...el, ...recipient, idKey: `${ el.id }_${ index }`, recipientIndex: index });
      })));
    const sortedElements = elements.sort((a, b) =>
      b.messageDate.toMillis() - a.messageDate.toMillis()
    );
    return sortedElements;
  }, []);

  useEffect(() => {
    if (messages !== undefined && !isLoading) {
      setMessagesUngrouped(convertMessages(messages));
    }
  }, [convertMessages, isLoading, messages]);

  const getFirstItemsInGroups = (data: DistributionListMessagesSearchResponseFlat[] ): DistributionListMessagesSearchResponseFlat[] =>
    data.reduce((acc: DistributionListMessagesSearchResponseFlat[], curr: DistributionListMessagesSearchResponseFlat) => {
      if (!acc.find(t => t.id === curr.id)) {
        return [...acc, curr];
      }
      return acc;
    }, []);

  useEffect(() => {
    if (emailIdParam && !isLoading && messagesUngrouped !== undefined) {
      const foundMessage = messagesUngrouped?.find(c => c.id === emailIdParam);
      if (foundMessage) {
        setExpandedRows([foundMessage]);
      } else {
        toast.current?.replace({
          title: 'Wrong Subject ID',
          message: 'Provided Subject ID in link was not found',
          severity: ToastSeverity.WARN
        });
      }
    }
  }, [emailIdParam, messages, isLoading, toast, messagesUngrouped]);

  const handleSort = (event: DataTableSortEvent): void => {
    const { multiSortMeta } = event;
    const isMultisort = multiSortMeta && multiSortMeta?.length > 0;
    setExpandedRows(isMultisort ? getFirstItemsInGroups(messagesUngrouped || []) : []); // Expand all for sorting / collapse when it's reset, or just "messages" in first case to show all (not grouped)
    setSortConfig(isMultisort ? { multiSortMeta, sortMode: 'multiple' } : initialSort);
    setExpandingDisabled(!!isMultisort);
  };

  return (
    <>
      <div className="dl-messages-container grow-to-fill">
        <div className="dl-messages-search-container">
          <span className="p-input-icon-left grow-to-fill">
            <i
              className={clsx('pi', {
                'pi-spinner pi-spin': isLoading,
                'pi-search': !isLoading,
              })}
            />
            <InputText
              placeholder="Search by subject"
              value={searchValue}
              onChange={(e): void => setSearchValue(e.target.value)}
              onBlur={(e): void => setSubjectSearch(e.target.value)}
              onKeyUp={(e): boolean | void => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
              className="dl-search-input"
            />
          </span>
          <CreateNewButton activeDlId={activeDlId} isDisabled={isCreateDisabled}/>
        </div>
        <DataTable
          className={clsx('distlist__table--messages', {
            'grow-to-fill': messagesUngrouped && messagesUngrouped.length > 0,
            'expanding-disabled': expandingDisabled,
          })}
          dataKey="idKey"
          loading={isLoading}
          value={messagesUngrouped || []}
          scrollable
          removableSort
          emptyMessage=" "
          rowGroupMode="subheader"
          expandableRowGroups={!expandingDisabled}
          expandedRows={expandedRows}
          onRowToggle={(e: { data: DistributionListMessagesSearchResponseFlat[] }): void => {
            !expandingDisabled && setExpandedRows(e.data);
          }}
          onSort={handleSort}
          groupRowsBy="id"
          rowGroupHeaderTemplate={HeaderTemplate}
          sortMode='multiple'
          multiSortMeta={initialSort.multiSortMeta}
          {...sortConfig}
        >
          <Column
            header="Recipient"
            field="emailAddress"
            sortable
            sortFunction={(e: ColumnSortEvent): F<string>[] => sortBySelectedField(e, 'emailAddress')}
          />
          <Column
            header="Status"
            field="status"
            sortable
            sortFunction={(e: ColumnSortEvent): F<string>[] => sortBySelectedField(e, 'status')}
            body={(data): JSX.Element => [DLMessagesStatus.dropped].includes(data.status) ?
              <>{DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]}<DroppedTooltip/></> :
              <>{DL_MESSAGES_STATUS_LABEL[data.status as DLMessagesStatus ?? DLMessagesStatus.null]}</>}
            className="column-status"
          />
          <Column
            header="Attachment downloaded"
            field="attachmentDownloaded"
            body={(data): JSX.Element => data.attachmentDownloaded ? <i className="pi pi-check"></i> : <></>}
            sortable
          />
          <Column header="Action"
            className="column-action"
            body={(data): JSX.Element =>
              [undefined, DLMessagesStatus.null, DLMessagesStatus.processed, DLMessagesStatus.deferred].includes(data.status) ?
                <></> :
                <ResendButton data={data} messages={messages} setMessages={setMessages}/>
            }/>
        </DataTable>
        {messages?.length === 0 &&
          <EmptyMessage activeDlId={activeDlId} isCreateDisabled={isCreateDisabled} setActiveDetailTab={setActiveDetailTab} />}
      </div>
      <Button
        text
        icon="iconoir-xmark icon--tiny p-button-icon-only"
        className={clsx('close-button', {hidden: isMobile})}
        onClick={handleClose}
      />
    </>
  );
};

export default MessageDesktop;
