
interface IComponentParams {
	worksheet: string;
}

export const ShareWorksheetDialogBody = (params: IComponentParams) => {
	
	const { worksheet } = params;

	return <>
		<strong>Share {worksheet}</strong>
		Please note that if you choose to share this sheet, everyone in your office will have access to it.
	</>
}