import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import AudioPlayer from 'components/AudioPlayer';

import {
  SURVEILLANCE_MEDIA_LABEL,
  SurveillanceMedia,
  SurveillanceMediaRecording,
  SurveillanceMediaText,
} from '../../../Models/ReportsResponse';
import { AttachmentComponentProps, AttachmentType, AttachmentTypeMap, DetailsViewProps, MIMEType } from '../Models';
import {
  useFile,
  useStoreRecordingPlayedToEndFlag,
} from '../Services/SurveillanceDetailsAPI';

import AttachmentsSection from './AttachmentsSection';
import TranscriptionComponent from './TranscriptionComponent';

import styles from './DetailsView.module.scss';

const audioTypes = Object.keys(SurveillanceMediaRecording);
const noRecordMediaTypes = [
  ...Object.keys(SurveillanceMediaText),
  SurveillanceMediaRecording.WhatsAppAudio,
];

const DetailsView = ({
  activeWorksheetId,
  className,
  id,
  companyName,
  details,
  partitionKey,
  highlights,
  saveTranscriptionVersion,
}: DetailsViewProps): JSX.Element => {
  const [shouldRequestAudio, setShouldRequestAudio] = useState(false);

  const isAudioMessage = details?.media
    && audioTypes.includes(details?.media);
  const isTextOnly = details?.media
    && noRecordMediaTypes.includes(details?.media);

  useEffect(() => {
    setShouldRequestAudio(false);
  }, [id]);

  const { url: dataSource, mime } = useFile(
    details && ((isAudioMessage && shouldRequestAudio) || details.isAttachment)
      ? {
        id,
        userName: details.username,
        companyName,
        providerName: details.providerName,
        startTime: details.startTime,
      }
      : null,
    activeWorksheetId
  );

  const { trigger: storeRecordingPlayedToEndFlag } =
    useStoreRecordingPlayedToEndFlag();

  const onPlay = useCallback(() => {
    if (!dataSource) {
      setShouldRequestAudio(true);
    }
  }, [dataSource]);
  const onPlayed = useCallback(() => {
    storeRecordingPlayedToEndFlag({
      id,
      partitionKey,
      providerName: details?.providerName!,
    });
  }, [id, partitionKey, details?.providerName, storeRecordingPlayedToEndFlag]);

  const mimeType: MIMEType | undefined = mime?.split('/')[0] as MIMEType;
  const attachment: AttachmentComponentProps = {
    type: AttachmentTypeMap[mimeType] || AttachmentType.File,
    source: dataSource || '',
    fileName: details?.metadata['record id'] || '',
  };

  const transcriptions = useMemo(
    () => details?.versions.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
    [details]
  );

  return (
    <div className={clsx(styles.container, className)}>
      {details && (
        <div className={clsx(styles.scroll, 'direction--column')}>
          <div className={styles.content}>
            <div
              className={clsx(
                styles.recordDetailsContainer,
                styles.itemContainer,
                styles.gap,
              )}
            >
              <dl>
                <dt>Company</dt>
                <dd>{companyName}</dd>
                <dt>User</dt>
                <div className={clsx(styles.recordDetailsContainerCounterpart)}>
                  {details.username && (
                    <div>
                      <div className={clsx(styles.lineParticipantsLeftColUser)}>
                        Name:
                      </div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.username}
                      </dd>
                    </div>
                  )}
                  {details.metadata.number && (
                    <div>
                      <div className={clsx(styles.lineParticipantsLeftColUser)}>
                        Number:
                      </div>
                      <div>
                        <dd className={clsx(styles.lineParticipantsRightCol)}>
                          {details.metadata.number}
                        </dd>
                      </div>
                    </div>
                  )}
                  {details.metadata.email && (
                    <div>
                      <div className={clsx(styles.lineParticipantsLeftColUser)}>
                        Email:
                      </div>
                      <div>
                        <dd className={clsx(styles.lineParticipantsRightCol)}>
                          {details.metadata.email}
                        </dd>
                      </div>
                    </div>
                  )}
                </div>
                <dt>Counterpart</dt>
                <div className={clsx(styles.recordDetailsContainerCounterpart)}>
                  {details.line && (
                    <div>
                      <div className={clsx(styles.lineParticipantsLeftCol)}>
                        Line:
                      </div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.line}
                      </dd>
                    </div>
                  )}
                  <div>
                    {details.line && (
                      <div className={clsx(styles.lineParticipantsLeftCol)}>
                        Participants:
                      </div>
                    )}
                    <dd className={clsx(styles.lineParticipantsRightCol)}>
                      {details.participants}
                    </dd>
                  </div>
                </div>
                {details.media && (
                  <>
                    <dt>Media</dt>
                    <dd>
                      {
                        SURVEILLANCE_MEDIA_LABEL[
                          details.media as SurveillanceMedia
                        ]
                      }
                    </dd>
                  </>
                )}
                <dt>Date</dt>
                <dd>
                  {DateTime.fromISO(details.startTime)
                    .setZone('UTC')
                    .toFormat('dd LLL yyyy, HH:mm:ss')}{' '}
                  UTC
                </dd>
              </dl>
            </div>
            <header>Content</header>
            {details.isAttachment && (
              <div className={clsx(styles.itemContainer, styles.gap)}>
                <AttachmentsSection
                  header="Attachment"
                  data={attachment}
                />
              </div>
            )}
            {!(isTextOnly) && ( // For VoxSmart SMS there is no recording
              <>
                <label className={styles.itemContainer}>Record</label>
                <AudioPlayer
                  className={styles.player}
                  source={dataSource}
                  onPlay={onPlay}
                  onEnd={onPlayed}
                />
              </>
            )}
            <label className={styles.itemContainer}>{isTextOnly ? 'Message' : 'Transcription'}</label>
            <TranscriptionComponent
              className={styles.itemContainer}
              transcriptions={transcriptions}
              highlights={highlights?.content}
              isEditable={details?.media && audioTypes.includes(details?.media)}
              saveTranscriptionVersion={saveTranscriptionVersion}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsView;
