import { RefObject, useCallback, useRef } from 'react';
import { Button } from 'primereact/button';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { SurveillanceEntityStatus, SurveillanceModeEnum } from '../../Models/Enums';
import {
  DEFAULT_SEARCH_ITEMS,
  SearchRequest,
} from '../../Models/ReportsRequest';
import { ResultsResponse } from '../../Models/ReportsResponse';
import ExportPopup, {
  ExportPopupReferenceProps,
} from '../ExportPopup/ExportPopup';
import {
  ExportButton,
  SurveillancePopupFooter, SurveillancePopupFooterReferenceProps,
} from '../ExportPopup/Templates';
import MarkAsReviewedPopup, { MarkAsReviewedPopupReferenceProps } from '../MarkAsReviewedPopup';
import { useUpdateMarkAsReviewed } from '../MarkAsReviewedPopup/Services/MarkAsReviewedService';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { eventBus } from 'server/EventBus';

interface ITableFooterProps {
  selectedItems: ResultsResponse[];
  recordsCount: number;
  resultsLength: number;
  isSelectAll: boolean;
  toastRef?: RefObject<ToastMessageRef>;
  searchItems?: SearchRequest;
  activeWorksheetId?: string | null;
  activeWorksheetName?: string | null;
  resultsMode?: SurveillanceModeEnum;
}

const TableFooter = (props: ITableFooterProps): JSX.Element => {
  const {
    selectedItems,
    recordsCount,
    resultsLength,
    isSelectAll,
    toastRef,
    searchItems,
    activeWorksheetId,
    activeWorksheetName,
    resultsMode
  } = props;

  const exportDetailsRef = useRef<ExportPopupReferenceProps | null>(null);
  const exportFooterRef = useRef<SurveillancePopupFooterReferenceProps | null>(null);
  const markAsReviewedDetailsRef = useRef<MarkAsReviewedPopupReferenceProps | null>(null);
  const markAsReviewedFooterRef = useRef<SurveillancePopupFooterReferenceProps | null>(null);

  const { trigger: triggerUpdate } = useUpdateMarkAsReviewed(activeWorksheetId);

  const onExportClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      // slice first 43 symbols in order to have overall length of a header equal to 50 symbols
      header: `Export ${ activeWorksheetName?.slice(0, 43) }`,
      body: (
        <ExportPopup
          ref={exportDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          worksheetName={activeWorksheetName || ''}
          searchParams={searchItems || DEFAULT_SEARCH_ITEMS}
          selectedItems={selectedItems}
          recordsCount={recordsCount}
          resultsLength={resultsLength}
          isSelectAll={isSelectAll}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={exportFooterRef}
          onCancel={closePopupWindow}
          onConfirm={onWorkSheetExport}
        />
      ),
      size: 'large',
    });
  };
  const onWorkSheetExport = () => {
    exportFooterRef.current?.setIsLoading(true);
    exportDetailsRef?.current
      ?.submit()
      .finally(() => {
        exportFooterRef.current?.setIsLoading(false);
        closePopupWindow();
      })
      .then(params => {
        if (params?.includeAudio) {
          toastRef?.current?.replace({
            title: 'Preparing the download',
            message: 'You\'ll receive an email when the download is ready.',
          });
        }
      });
  };

  const onMarkAsReviewed = useCallback(():void => {
    markAsReviewedFooterRef.current?.setIsLoading(true);
    markAsReviewedDetailsRef?.current
      ?.submit()
      .then((e) => {
        markAsReviewedFooterRef.current?.setIsLoading(false);
        closePopupWindow();
        toastRef?.current?.replace({
          title: 'Success',
          message: 'Selected items are marked as reviewed',
          severity: ToastSeverity.SUCCESS
        });
      })
      .catch((e):void => {
        markAsReviewedFooterRef.current?.setIsLoading(false);
        if (e !== 'validator') { // If it's not from validator
          toastRef?.current?.replace({
            title: 'Error',
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR
          });
        }
      });
  }, [toastRef]);

  const onMarkAsReviewedClick = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Mark as Reviewed',
      body: (
        <MarkAsReviewedPopup
          ref={markAsReviewedDetailsRef}
          activeWorksheetId={activeWorksheetId || ''}
          selectedItems={selectedItems}
        />
      ),
      footer: (
        <SurveillancePopupFooter
          ref={markAsReviewedFooterRef}
          onCancel={closePopupWindow}
          onConfirm={onMarkAsReviewed}
          confirmValue='Mark as Reviewed'
        />
      ),
      size: 'large',
    });
  };

  const onRestoreClick = (): void => {
    triggerUpdate({
      items: selectedItems.map(el => ({
        id: el.id,
        partitionKey: el.partitionKey,
      })),
      assignedUserId: null,
      status: SurveillanceEntityStatus.ActiveWithComments,
      reason: null,
      note: 'restored by user',
      worksheetId: activeWorksheetId || '',
    }).then((e):void => {
      toastRef?.current?.replace({
        title: 'Success',
        message: 'Selected items are restored in search results',
        severity: ToastSeverity.SUCCESS
      });
    }).catch(():void => {
      toastRef?.current?.replace({
        title: 'Error',
        message: 'Sorry, something has gone wrong. Please try again later.',
        severity: ToastSeverity.ERROR
      });
    });
  };

  const closePopupWindow = (): Promise<boolean> =>
    eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);

  return (
    <footer>
      Selected: {selectedItems.length}{' '}
      {selectedItems.length > 1 ? 'items' : 'item'}
      {resultsMode === SurveillanceModeEnum.Results && <>
        <ExportButton isDisabled={!recordsCount} onClick={onExportClick} />
        <Button
          size="small"
          className="surveillance-reviewed"
          outlined
          onClick={onMarkAsReviewedClick}
        >
          Mark as Reviewed
        </Button>
      </>}
      {resultsMode === SurveillanceModeEnum.Reviewed &&
        <Button
          size="small"
          className="surveillance-reviewed"
          outlined
          onClick={onRestoreClick}
        >
          Restore
        </Button>
      }
    </footer>
  );
};

export default TableFooter;