import Joi from 'joi';
import { PRICE_UNITS, QUANTITY_UNITS } from './Consts';

const VALIDATION_MESSAGES = {
  'string.equal': 'Buyer and Seller company can not be the same',
  'string.empty': 'Required field',
  'any.required': 'Required field'
};

const buyerCompanyRef = Joi.ref('/buyer.company');
const sellerCompanyRef = Joi.ref('/seller.company');

const companyValidator = Joi.alternatives().conditional(sellerCompanyRef, {
  is: Joi.equal(buyerCompanyRef),
  otherwise: Joi.string().required(),
  then: Joi.string().custom((_value, helpers) => helpers.error('string.equal'))
});

const brokerValidator = Joi.object({
  company: companyValidator,
  contactName: Joi.string().required(),
  tradingAccount: Joi.string().required(),
  paysBrokerage: Joi.bool().required(),
  rate: Joi.when('paysBrokerage', {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.optional()
  }),
  obBroker: Joi.object({
    userId: Joi.when('/isImported', {
      is: true,
      then: Joi.string().optional(), // imported item has no `userId` defined
      otherwise: Joi.string().required()
    }),
    userName: Joi.string().required()
  }).required()
});

const tradeValidator = Joi.object({
  instrument: Joi.string().required(),
  dateTime: Joi.object().required(), // how to properly validate luxon.DateTime?
  price: Joi.object({
    amount: Joi.number().required(),
    unit: Joi.string().valid(...PRICE_UNITS).required()
  }),
  quantity: Joi.object({
    amount: Joi.number().required(),
    unit: Joi.string().valid(...QUANTITY_UNITS).required().messages({ 'any.only': 'Selected option is invalid' })
  }),
  buyer: brokerValidator,
  seller: brokerValidator,
  clearing: Joi.object({
    requires: Joi.boolean().required(),
    house: Joi.when('requires', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('', null)
    }),
    id: Joi.when('requires', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('', null)
    }),
    cleared: Joi.when('requires', {
      is: true,
      then: Joi.boolean().required(),
      otherwise: Joi.boolean().optional().allow(null)
    }),
  }),
  comments: Joi.string().optional().allow('')
}).unknown(true).messages(VALIDATION_MESSAGES);

const tradeValidatorCompanyOnly = Joi.object({
  buyer: Joi.object({ company: companyValidator.optional().allow('') }).unknown(true),
  seller: Joi.object({ company: companyValidator.optional().allow('') }).unknown(true),
}).unknown(true).messages(VALIDATION_MESSAGES);

export const getValidator = (validateAll: boolean): Joi.ObjectSchema<any> => {
  return validateAll ? tradeValidator : tradeValidatorCompanyOnly;
};