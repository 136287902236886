import {
	useEffect,
	useReducer,
	useState,
	useRef,
} from 'react';
import { useNavigate, NavigateFunction } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import { Button } from 'primereact/button';
import { clsx } from "clsx";

import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { SignalRWorksheetUpdate, SignalRWorksheetPanel } from 'components/Worksheets/Models/Events';
import { useCreateNewWorksheet } from 'components/Worksheets/Services/WorksheetHooks';
import Traversal from 'components/Traversal';
import ToastMessage, { type ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { eventBus } from "server/EventBus";

import {
  WorksheetStores,
  WorksheetSignalMessageEventTypes,
  WorksheetPanelDisplayEvent,
  MutationType } from './Models/Enums';
import {
	OpenWorksheet,
	WorksheetSearchField,
	WorksheetMetaProps } from './Models/WorksheetResponse';


import WorksheetTab from './Components';

import styles from './Worksheets.module.scss';

interface IOpenWorkSheetsProps {
	store: WorksheetStores,
	worksheetid: string | undefined,
	setWorksheetid: Function,
	children?: JSX.Element,
	currentRoute: string;
	hydrator: () => WorksheetMetaProps[];
	uiSettingName: UISettings;
}

export interface MutationAction {
	type: MutationType,
	payload: string,
	name?: string,
	isInvalid?: boolean;
}

const OpenWorksheets = (props: IOpenWorkSheetsProps) => {

	const { store, worksheetid, /* setWorksheetid ,*/ currentRoute, hydrator, uiSettingName } = props;

	const tabContainer = useRef<HTMLDivElement>(null);

	const { getSetting } = useLoadUserSettings();
	const { trigger } = useSaveUserSetting();
	const [ currentActiveSheet, setCurrentActiveSheet ] = useState<string | undefined>(worksheetid);
	const [ toggle, setToggle ] = useState<boolean>(false);
	const [ isOverflowing, setIsOverflowing ] = useState<boolean>(false);
	const [ shouldSaveState, setShouldSaveState] = useState(false);
	const navigate: NavigateFunction = useNavigate();
	const toast = useRef<ToastMessageRef>(null);
	const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

	const { newWorksheet, isCreating } = useCreateNewWorksheet(store);

	useEffect(() => {
		if (worksheetid) {
			worksheetDispatcher({ type: MutationType.Open, payload: worksheetid });
		}
	}, [worksheetid]);

	const mutationReducer = (state: OpenWorksheet[], action: MutationAction): OpenWorksheet[] => {
		if (!Array.isArray(state)) {
			state = [];
		}

		switch(action.type) {
			case MutationType.Error:
				//	Something went wrong with loading this sheet, so we should
				//	ensure the tab shows in a disabled state,
				const { payload } = action;

				return [
					...state.map(s => {
						return (s.id === payload)
							? ({ ...s, name: '…', isInvalid: true })
							: s
					})
				];

			case MutationType.Close:
			case MutationType.Delete:
				//	Should the passed mutation be for the the currently
				//	active worksheet we need to figure out which one
				//	becomes active
				if (currentActiveSheet === action.payload) {
					const pointer = state.findIndex(ws => ws.id === action.payload);
					const nid = (pointer === 0) ? state[1]?.id : state[pointer - 1]?.id;
					const path = nid ?
						`${currentRoute}${nid}` :
						// remove trailing back-slash when all worksheets are closed (deleted) -> go to ex. "/cargo-flows"
						currentRoute.slice(0, -1);

					navigate(path);
				}

				const newOpenWorksheets = state.filter(ws => ws.id !== action.payload);

				// save opened sheets to the cache immidiatelly to avoid getting old data on fast switching between modules
				setShouldSaveState(true);
				//	otherwise get on with mutating the collection of open
				//	worksheets
				return newOpenWorksheets;

			case MutationType.Open:
				setCurrentActiveSheet(action.payload);

				//	A file has been opened - check if we don't already have it
				if(state?.some(s => s.id === action.payload)) {
					return state;
				}

				// if it was not previously opened we should save new state
				setShouldSaveState(true);

				return [...(state ?? []) , { id: action.payload, name: action.name as string, store }];

			case MutationType.Change:
				const { payload: worksheetId } = action;

				//	mutate the name of the item with a matching worksheetId
				return [
					// filter out invalid worksheets as we can do nothing with them anyway
					...state
						.filter(s => !s.isInvalid)
						.map(s => {
							// we only need to save state in case name has been changed
							if(s.id === worksheetId && s.name !== action.name) {
								setShouldSaveState(true);
							}
							return (s.id === worksheetId)
								? ({ ...s, name: action.name ?? '', store })
								: s
						})
				];

			case MutationType.Create:
				setShouldSaveState(true);
				
				return [
					...state,
					{ id: action.payload, name: action.name ?? '', store }
				];

			default:
				return state;
		}
	}

	const initState = () => {
		try {
			//	Get the state from the uiSettings cache
			return getSetting(uiSettingName).map((s: OpenWorksheet) => ({...s, store}));
		} catch(e) {
			//	seems we dont have a open worksheets state - so lets create one
			return [];
		}
	}

	const [ openWorksheets, worksheetDispatcher] = useReducer(mutationReducer, null, initState);

	useEffect(() => {
		// if there is a change in the open worksheets
		// and should save flag set to true so we should
		// save settings to the UI settings on BE
		if(shouldSaveState) {
			try {
				trigger({
					setting: uiSettingName,
					data: openWorksheets
				});
			} catch (e) {
				//  TODO - handle potential problems saving the current state of what
				//  worksheets are currently open
				console.error(e);
			}
			finally {
				setShouldSaveState(false);
			}
		}
	}, [uiSettingName, shouldSaveState, openWorksheets, trigger]);

	useEffect(() => {
    //  Define the callback function triggered when WorksheetSignalMessageEventTypes events
    //  are recieved by the conponent
		const onWorksheetUpdate = (event: CustomEvent<SignalRWorksheetUpdate<WorksheetSearchField, WorksheetMetaProps>>) => {
			const { worksheetId, name, mutation: type = MutationType.Change } = event.detail;

			if (type === MutationType.Error) {
				//	Elsewhere we've tried to load the worksheet and for whatever
				//	reason it's NOT valid. So we should set the instance into
				//	a locked state
				worksheetDispatcher({ type, payload: worksheetId, isInvalid: true });
			} else {
				//	We know our current open collection has a worksheet with
				//	this id, BUT the name is different. So mutate the item in
				//	the local collection to reflect this update
				worksheetDispatcher({type, payload: worksheetId, name});
			}
		}


		const events = [
			WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
			WorksheetSignalMessageEventTypes.WORKSHEET_RENAMED,
			WorksheetSignalMessageEventTypes.WORKSHEET_DELETED,
			WorksheetSignalMessageEventTypes.WORKSHEET_DENIED,
		];

		events.map(e => eventBus.on(e, onWorksheetUpdate));

		return () => {
			events.map(e => eventBus.remove(e, onWorksheetUpdate));
		}
	});


  const handleSheetListToggle = () => {

		if (toggle) {
			//	It's open already - so close it
			eventBus.dispatch<SignalRWorksheetPanel>(WorksheetPanelDisplayEvent.HIDE_PANEL, { type: null, show: false, uiSetting: null, active: null })

		} else {
			//	Trigger an event to show the panel
			eventBus.dispatch<SignalRWorksheetPanel>(
				WorksheetPanelDisplayEvent.SHOW_PANEL,
				{ type: store, show: true, uiSetting: uiSettingName, active: worksheetid }
			)
		}

    setToggle(c => !c);
  }

	return <div className='direction--column'>
		<div className={clsx(
		'worksheet-tabs',
		{ 'overflowing': isOverflowing }
	)}>
		{ openWorksheets &&
			<>
				<div>
					<Button
						size="small"
						text
						icon={`iconoir-sidebar-${toggle ? 'collapse' : 'expand'} icon--tiny`}
						onClick={() => handleSheetListToggle()}
						tooltip={isMobile ? undefined : `${toggle ? 'Close' : 'Open'} list of worksheets`}
						tooltipOptions={{
							position: 'right',
						}}
					/>
				</div>
				<Traversal
					activeTab={openWorksheets.findIndex(item => item.id === currentActiveSheet)}
					container={tabContainer}
					onVisibilityChange={ (visible) => setIsOverflowing(visible) }
				/>
				<div ref={tabContainer} className="worksheet-tabs__container">
					{ openWorksheets.map((ws, i) =>
						<WorksheetTab
							key={i}
							sheet={ws}
							disabled={openWorksheets.length === 1}
							dispatcher={worksheetDispatcher}
							currentRoute={currentRoute}
							className={clsx("worksheet-tab", ws.id === currentActiveSheet && 'worksheet-tab--active')}
						/>
					)}
				</div>
				<div>
					<Button
						size="small"
						text
						icon="iconoir-plus icon--tiny"
						loading={isCreating}
						className='plain-text'
						tooltip="Create New Worksheet"
						tooltipOptions={{
							position: 'top',
						}}
						onClick={async () => {
							try {
								const data = await newWorksheet({
									store,
									hydrator,
								});

								if (!data) return;

								const { worksheetId, name } = data;

								worksheetDispatcher({ type: MutationType.Create, payload: worksheetId ?? '', name });

								navigate(`${currentRoute}${worksheetId}`);
							} catch (e) {
								toast.current?.replace({
									title: 'Issue occurred',
									message: 'Issue when creating worksheet',
									severity: ToastSeverity.WARN
								});
							}
						}}
					/>
				</div>
			</>
		}
		{ props.children &&
			<div>{ props.children }</div>
		}
		</div>
		<ToastMessage ref={toast} />
		{openWorksheets.length === 0 && <div className={styles.worksheetError}>Worksheet not available</div>}
	</div>;
}

export default OpenWorksheets;
