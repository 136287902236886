import axios from 'axios';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { BalticRateDTO } from '../Grids/Models/BalticRatesListResponse';

import type { GridProvider } from 'modules/Rates/Grids/Models/GridProvider';

import { apiRatesResponse, apiRatesFeedResponse } from '../Models';

interface IHistoricalPriceParams {
    market: string,
    indexid: string,
    period: string,
    provider: GridProvider,
}

export class BalticOrVortexaApi {

		static getAvailableFeeds = (provider: GridProvider) => axios.request<null, {data: apiRatesFeedResponse[]}>({
			url: 'rates/feeds',
			method: 'GET',
			headers: { 'X-Obx-Provider': provider }
		})
		.then(r => ({ results: r.data }))
		.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e;
		});

		static getRatesByMarket = ({market, provider} : { market: string; provider: GridProvider }) => axios.request<any, {data: apiRatesResponse[]}>({
			url: `rates/${market}`,
			method: 'GET',
			headers: { 'X-Obx-Provider': provider
		}})
		.then(r => ({
			results: r.data.map((item: apiRatesResponse) => new BalticRateDTO(item) )
		}))
		.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e;
		});

		static getHistoricalPrices = (url: string, params: IHistoricalPriceParams) => {

			const { market, indexid, period, provider } = params;

			return axios.request<null, { data: any }>({
				url: `rates/${market}/source/${indexid}/${period}`,
				method: 'GET',
				headers: { 'X-Obx-Provider': provider }
			})
			.then( r => ({ results: r.data  }))
			.catch((e) => {
				ErrorToastService.handleError(e, [500, 503]);
	
				throw e;
			});
		}
}
