import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';

import eventBus from 'server/EventBus';
import BorealisBar from 'components/BorealisBar';
import ToastMessage, { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';
import PriceChangeSubscription from 'modules/ArtisCharting/Components/PriceChangeSubscriptions/PriceChangeSubscription';
import {
  useCreatePriceChangeSubscription,
  useDeletePriceChangeSubscription,
  useGetPriceChangeSubscriptions,
  useUpdatePriceChangeSubscription,
} from 'modules/ArtisCharting/Services/hooks';
import { ArtisPricesSignalRMessages, PriceChangeSubscriptionDirecton, PriceChangeSubscriptionStatus } from 'modules/ArtisCharting/Models/Enums';

import type {
  ActiveChartPrice,
  CreatePriceChangeSubscriptionPayload,
  PriceChangeSubscription as PriceChangeSubscriptionType,
  SignalRPriceChangeSubscriptionUpdated
} from 'modules/ArtisCharting/Models/ArtisPrices';

import './PriceChangeSubscriptions.scss';

const DRAFT_SUBSCRIPTION: PriceChangeSubscriptionType = {
  direction: PriceChangeSubscriptionDirecton.Above,
  expires: '',
  expiresOn: '',
  format: '',
  id: null,
  packageId: '',
  productId: '',
  status: PriceChangeSubscriptionStatus.Active,
  tenorCode: '',
  tenorName: '',
  value: undefined,
};

interface PriceChangeSubscriptionsProps {
  price: ActiveChartPrice;
}

export default function PriceChangeSubscriptions(props: PriceChangeSubscriptionsProps): JSX.Element {
  const { price } = props;
  const [subscriptions, setSubscriptions] = useState<PriceChangeSubscriptionType[]>([]);
  const [showDraft, setShowDraft] = useState<boolean>(false);
  const [subscriptionToUpdate, setSubscriptionToUpdate] = useState<string | null>(null);
  const toast = useRef<ToastMessageRef>(null);

  const { data, isLoading, error, changeSubscriptionsCacheOnSubscriptionUpdate } = useGetPriceChangeSubscriptions(price);
  const { trigger: create, isMutating: isCreating } = useCreatePriceChangeSubscription();
  const { trigger: update, isMutating: isUpdating } = useUpdatePriceChangeSubscription();
  const { trigger: triggerDeleteSubscription } =
    useDeletePriceChangeSubscription();

  useEffect(() => {
    setSubscriptions(data ?? []);
  }, [data]);

  useEffect(() => {
    // when the price is changed let's show 'Create Notification' button
    setShowDraft(false);
  }, [price]);

  useEffect(() => {
    const onPriceChangeSubscriptionUpdated = async (event: CustomEvent<SignalRPriceChangeSubscriptionUpdated>): Promise<void> => {
      const result = changeSubscriptionsCacheOnSubscriptionUpdate(event.detail);

      // seve state only if subscription for selected tenor has been updated
      if (price.product === event.detail.productId && price.tenorCode === event.detail.tenorCode) {
        setSubscriptions(result);
      }
    };

    eventBus.on(
      ArtisPricesSignalRMessages.PRICE_CHANGE_SUBSCRIPTION_UPDATED,
      onPriceChangeSubscriptionUpdated
    );

    return () => {
      eventBus.remove(
        ArtisPricesSignalRMessages.PRICE_CHANGE_SUBSCRIPTION_UPDATED,
        onPriceChangeSubscriptionUpdated
      );
    };
  }, [changeSubscriptionsCacheOnSubscriptionUpdate, price]);

  const addDraftSubscription = (): void => {
    setShowDraft(true);
  };

  const createSubscription = async (data: CreatePriceChangeSubscriptionPayload): Promise<void> => {
    try {
      await create(data);
    } catch (e) {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue with adding the notification',
        severity: ToastSeverity.WARN
      });
      throw e;
    }
  };

  const updateSubscription = async (data: PriceChangeSubscriptionType): Promise<void> => {
    setSubscriptionToUpdate(data.id);

    try {
      await update(data);
    } catch (e) {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue with updating the notification',
        severity: ToastSeverity.WARN
      });
      throw e;
    }

    setSubscriptionToUpdate(null);
  };

  const deleteSubscription = async (
    data: PriceChangeSubscriptionType
  ): Promise<void> => {
    const deletedSubscription = await triggerDeleteSubscription(data).catch(() => {});

    if(deletedSubscription) {
      setSubscriptions(
        (subscriptions) => subscriptions
          .filter((subscription) => subscription.id !== deletedSubscription.id)
      );
    }
  };

  const renderContent = useCallback(() => {
    if (error && !isLoading) {
      return <div>Something went wrong</div>;
    }

    if (isLoading) {
      return <BorealisBar styleOverrides='price-change-subscriptions__loader' />;
    }

    if (!subscriptions?.length && !showDraft) {
      return <div className='price-change-subscriptions__content--no-data direction--column'>
        <p>No notifications currently configured for this product tenor</p>
        <Button
          text
          size='small'
          iconPos='left'
          icon='iconoir-plus icon--small icon--left'
          onClick={addDraftSubscription}
        >
          Create notification
        </Button>
      </div>;
    }

    return <>
      {subscriptions.map(subscription => <PriceChangeSubscription
        data={subscription}
        isLoading={isUpdating && subscriptionToUpdate === subscription.id} // disable updated one only
        key={subscription.id}
        price={price}
        update={updateSubscription}
        deleteSubscription={deleteSubscription}
      />)}
      <PriceChangeSubscription
        create={createSubscription}
        data={DRAFT_SUBSCRIPTION}
        isDraft
        isLoading={isCreating}
        price={price}
      />
    </>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isCreating, isLoading, isUpdating, subscriptions, showDraft]);

  return <Panel
    toggleable={false}
    className='price-change-subscriptions__container direction--column grow-to-fill'
    pt={{ content: { className: 'position--relative' } }}
    header='Notifications'
  >
    {renderContent()}
    <ToastMessage ref={toast} />
  </Panel>;
}