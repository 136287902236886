import { memo, RefObject, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';

import Loader from 'components/Loader';
import {
  useLoadUserCreds,
  useLoadUserSettings,
  useLoggedInUser,
  useSaveUserSetting,
} from 'components/OBXUser/Services/ProfileHooks';
import type { ToastMessageRef } from 'components/ToastMessage';

import { ProfileSignalEventTypes, UISettings } from '../OBXUser/Model/Enums';

import eventBus from 'server/EventBus';

import styles from './ProfilePanel.module.scss';

type ICredentialParams = {
  label: string;
  value: string;
  encrypted?: boolean;
  toastRef?: RefObject<ToastMessageRef>;
  className?: string;
};

type ProfileParams = {
  toastRef?: RefObject<ToastMessageRef>;
};

type HelpItemProps = {
  label: string;
  description: string;
  link: string;
  className?: string;
};

const Credential = (props: ICredentialParams): JSX.Element => {
  const { label, value, toastRef, encrypted = false, className } = props;

  const copyToClipBoard = async (snippet: string): Promise<void> => {
    await navigator.clipboard.writeText(snippet);

    toastRef?.current?.replace({
      title: `${label} copied to clipboard`,
      message: 'You can now paste this into place you want',
    });
  };

  return (
    <div className={className}>
      <span>{label}</span>
      <div>
        <span>{encrypted ? value.replace(/(\w|\D)/g, '*') : value}</span>
        <Button
          size='small'
          text
          icon='iconoir-multiple-pages icon--small'
          onClick={() => copyToClipBoard(value)}
        />
      </div>
    </div>
  );
};

const HelpItem = (props: HelpItemProps): JSX.Element => {
  const { label, description, link, className } = props;

  return (
    <div
      className={clsx(
        className,
        styles.helpContainer,
        'direction--row align-items--center'
      )}
    >
      <div
        className={clsx(
          styles.helpDescription,
          'direction--column grow-to-fill'
        )}
      >
        <dt>{label}</dt>
        <dd>{description}</dd>
      </div>
      <div
        className={clsx(styles.downloadButtonContainer, 'direction--column')}
      >
        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          role='tab'
          download={true}
        >
          <i className='iconoir-download icon--medium' />
        </a>
      </div>
    </div>
  );
};

export const ProfilePanel = ({ toastRef }: ProfileParams): JSX.Element => {
  const { creds, error, isLoading } = useLoadUserCreds();

  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { obxuser } = useLoggedInUser();
  const clddOptions = useMemo(
    () =>
      obxuser?.cldds.filter(item => item.isSelectable).map(item => item.code) ??
      [],
    [obxuser?.cldds]
  );
  const activeCldd = getSetting(UISettings.ACTIVE_CLDD);

  const { logout } = useAuth0();

  if (error) {
    return <div>Sorry - couldn't load profile...</div>;
  }

  const handleClddChange = (value: string): void => {
    trigger({
      setting: UISettings.ACTIVE_CLDD,
      data: value,
    });
    eventBus.dispatch(ProfileSignalEventTypes.CLDD_UPDATED, null);
  };

  return (
    <>
      {creds && (
        <div className={styles.tabview}>
          <TabView renderActiveOnly={true}>
            <TabPanel className={styles.credentialContainer} header='General'>
              <div className={styles.contentContainer}>
                <Button
                  tabIndex={0}
                  text
                  icon='iconoir-log-out icon--small'
                  size='small'
                  onClick={(): void =>
                    logout({
                      logoutParams: {
                        returnTo: `${window.location.protocol}//${window.location.host}`,
                      },
                    })
                  }
                >
                  Log out
                </Button>
              </div>
              <label className={styles.contentContainer}>
                Data Access Credentials
              </label>
              <div className='direction--column'>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Credential
                      className={clsx(
                        styles.credential,
                        styles.contentContainer
                      )}
                      label='User Name'
                      value={creds.userId}
											toastRef={toastRef}
                    />
                    <Credential
                      className={clsx(
                        styles.credential,
                        styles.contentContainer
                      )}
                      label='Secret'
                      value={creds.userSecret}
											toastRef={toastRef}
                      encrypted
                    />
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel className='direction--column' header='Workspace'>
              {clddOptions.length > 1 && (
                <div className={styles.clddSwitch}>
                  <label htmlFor='switch-workplace'>Switch Workspace</label>
                  <Dropdown
                    id='switch-workplace'
                    value={activeCldd || clddOptions[0]}
                    onChange={(e: DropdownChangeEvent): void =>
                      handleClddChange(e.value)
                    }
                    options={clddOptions}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel className='direction--column' header='Help'>
              <HelpItem
                label='Search for specific dates and times'
                description='Date Only / Relative Ranges / Times Only / Dates & Times'
                link={`/static/${encodeURIComponent(
                  'Search for specific dates and times.pdf'
                )}`}
                className={clsx(styles.contentContainer, styles.separator)}
              />
            </TabPanel>
            <TabPanel className='hidden' header='Notifications'>
              <Button
                size='small'
                tabIndex={0}
                className='progressier-subscribe-button _active'
                data-eligible='Subscribe to notifications'
                data-subscribed='Notifications Subscribed'
                data-blocked='Notifications blocked'
              />
            </TabPanel>
          </TabView>
        </div>
      )}
    </>
  );
};

export default memo(ProfilePanel);
