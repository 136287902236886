import { useCallback, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { InputText } from 'primereact/inputtext';

interface TextInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  addonText?: string;
  showError?: boolean;
  customErrorMessage?: string;
  id?: string;
}

const TextInput = (props: TextInputProps): JSX.Element => {
  const { defaultValue, showError, customErrorMessage, onChange, addonText, id } = props;
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const handleUpdate = useCallback(async () => {
    onChange && onChange(value);
  }, [onChange, value]);

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  useEffect(() => {
    setError(customErrorMessage ?? (!value || value === '' ? 'Required field' : null));
  }, [value, customErrorMessage]);

  return (<>
    <div
      className={clsx('p-inputgroup', {'p-invalid': showError && error !== null})}>
      <InputText
        id={id}
        value={value}
        onFocus={(e):void => e.target.select()}
        onChange={(e): void => setValue(e.target.value)}
        onBlur={handleUpdate}
        onKeyUp={(e): void => {
          if (e.key === 'Enter') {
            handleUpdate();
          }
        }}
      />
      {addonText && <span className="p-inputgroup-addon">{addonText}</span>}
    </div>
    {showError && error !== null && <small className="message-invalid">{error || 'Required field'}</small>}
  </>
  );
};

export default TextInput;