import {
  Dispatch,
  Key,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableRowClickEvent, DataTableRowData,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable';
import {
  VirtualScrollerChangeEvent,
  VirtualScrollerLazyEvent,
  VirtualScrollerProps,
} from 'primereact/virtualscroller';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import NoData from 'components/NoData';
import { ProfileSignalEventTypes, UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { ToastMessageRef } from 'components/ToastMessage';
import {
  WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from 'components/Worksheets/Models/Enums';
import type { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';
import {
  useMutateWorksheet,
  WorksheetMutationTypes,
} from 'components/Worksheets/Services/WorksheetHooks';

import { SurveillanceEntityStatus, SurveillanceModeEnum } from '../../Models/Enums';
import { SearchRequest, SearchRequestPaged } from '../../Models/ReportsRequest';
import {
  ResultsResponse,
  SearchFiltersResponse,
} from '../../Models/ReportsResponse';
import { SurveillanceSignalEventTypes } from '../../Services/SignalRSocket';
import {
  useGetResults,
  useGetSurveillanceFilters,
} from '../../Services/SurveillanceService';
import { EntityUpdateResponse } from '../MarkAsReviewedPopup/Services/MarkAsReviewedService';
import { isSearchItemsEqual } from '../SearchBar/Models/Parsers';
import TableFooter from '../TableFooter';

import { Content } from './Templates/Content';
import FilteringHeader from './Templates/FilteringHeader';
import { MediaChip } from './Templates/MediaChip';
import { UserCell } from './Templates/UserCell';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { DoubleLineCounterpart } from 'helpers/DataTable/Templates/ColumnTemplates/DoubleLineCounterpart';
import { removeItemAt } from 'helpers/Utils/collections';
import { numberFormatter } from 'helpers/Utils/formatters';
import { notNil, parsePropsToDateTime } from 'helpers/Utils/misc';
import { uniqueId } from 'helpers/Utils/string';
import { getAdditionalPropsParsed } from 'modules/CargoTracker/Components/CargoSearch/Models/Parsers';

import { DEFAULT_GRID_ROW_HEIGHT, SELECTABLE_ROW_CLASS, SELECTED_ROW_CLASS } from 'models/shared/consts';
import eventBus from 'server/EventBus';

import './Results.scss';

interface ResultsProps {
  resultSelected: ResultsResponse | null;
  setResultSelected: Dispatch<SetStateAction<ResultsResponse | null>>;
  isLoadingWorksheet: boolean;
  searchItems?: SearchRequest;
  setSearchItems?: Dispatch<SetStateAction<SearchRequest | undefined>>;
  lastModified?: DateTime;
  activeWorksheetId?: string | null;
  activeWorksheetName?: string | null;
  toastRef?: RefObject<ToastMessageRef>;
  resultsMode?: SurveillanceModeEnum;
}

const PAGE_SIZE = 1000;

const Results = ({
  resultSelected,
  setResultSelected,
  isLoadingWorksheet,
  searchItems,
  setSearchItems,
  lastModified,
  activeWorksheetId,
  activeWorksheetName,
  toastRef,
  resultsMode,
}: ResultsProps): JSX.Element => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [results, setResults] = useState<ResultsResponse[]>([]);
  const [previousSearchItems, setPreviousSearchItems] = useState<
    SearchRequest | undefined
  >(searchItems);
  const [pagedSearchItems, setPagedSearchItems] = useState<SearchRequestPaged>({pageNumber: pageNumber, pageSize: PAGE_SIZE});
  const [lastPageNumber, setLastPageNumber] = useState<number>(1);
  const [isSearchItemsChanged, setIsSearchItemsChanged] = useState<boolean>(false);
  const [isPageNumberChanged, setIsPageNumberChanged] = useState<boolean>(true);
  const [isCLDDChanged, setIsCLDDChanged] = useState(false);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(true);
  const [isLoadingTimeout, setIsLoadingTimeout] = useState(false);
  const [random, setRandom] = useState<string>(uniqueId()); // add random token as SWR returns first old data when switching tabs
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [lastModifiedUpdated, setLastModifiedUpdated] = useState<DateTime>();
  const [selectedItems, setSelectedItems] = useState<ResultsResponse[]>([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isIntermediateSelect, setIsIntermediateSelect] = useState(false);
  const [lastRenderedElement, setLastRenderedElement] = useState(0);

  const { data, isValidating: isValidatingResults, isLoading: isLoadingResults } = useGetResults(
    pagedSearchItems,
    activeWorksheetId,
    random
  );

  const { data: filters } = useGetSurveillanceFilters();
  // Worksheet mutate loader
  const { worksheet, mutateWorksheet, mutateAdditionalProps } =
    useMutateWorksheet(
      WorksheetStores.Surveillance,
      activeWorksheetId ?? undefined
    );

  const dt = useRef<DataTable<ResultsResponse[]>>(null);
  const { getSetting } = useLoadUserSettings();
  const [activeCldd, setActiveCldd] = useState<string>(getSetting(UISettings.ACTIVE_CLDD));

  const updateVirtualScroller = useCallback((selectedItemsLength: number): void => {
    // Hack for virtualscroller -> when footer is shown -> virtualscroller remains same height and footer is going below table and off screen
    // Height is calculated properly with "key: uniqueId()" in virtualScrollerOptions, but then on loading next pages, table is scrolled to top
    const virtualScrollerRef = dt.current?.getVirtualScroller().getElementRef() as RefObject<HTMLDivElement>;
    const virtualScrollerEl = virtualScrollerRef?.current;
    if (virtualScrollerEl) {
      const val = parseInt(virtualScrollerEl.style.height, 10); // i.e. 532px -> 532
      virtualScrollerEl.style.height = selectedItemsLength > 0 ? `${ val - 50 }px` : `${ val + 50 }px`;
    }
  }, []);

  const resetState = useCallback((): void => {
    setResults([]);
    if (selectedItems.length > 0) { // update height of VS if footer with selected items was opened
      updateVirtualScroller(0);
    }
    setSelectedItems([]);
    setPageNumber(1);
    setLastPageNumber(1);
    setResultsCount(0);
  }, [selectedItems.length, updateVirtualScroller]);

  const handleUpdateData = useCallback((): void => {
    setIsLoadingTimeout(true);
    setTimeout(() => {
      resetState();
      const newCLDD = getSetting(UISettings.ACTIVE_CLDD);
      setActiveCldd(newCLDD);
      setIsCLDDChanged(true);
      setPagedSearchItems({...searchItems, pageNumber: 1, pageSize: PAGE_SIZE, cldd: newCLDD });
      setIsLoadingTimeout(false);
    }, 6000); // TODO: For now added timeout 6s until there is no SignalR when data will be updated
  }, [getSetting, resetState, searchItems]);

  const onWSUpdated = useCallback(
    (e: CustomEvent<Partial<WorksheetResponse>>): void => {
      if (e.detail.worksheetId === activeWorksheetId) {
        setLastModifiedUpdated(
          DateTime.fromISO(`${ e.detail.lastModified }`).toUTC()
        );
        mutateWorksheet({
          type: WorksheetMutationTypes.Full,
          payload: {
            ...worksheet,
            ...e.detail,
          },
        });
      }
    },
    [worksheet, activeWorksheetId, mutateWorksheet]
  );

  const onEntityUpdated = useCallback((e: CustomEvent<EntityUpdateResponse>):void => {
    const { status, items, worksheetId } = e.detail;

    let updatedCount = 0;
    let updatedResults: ResultsResponse[] | null = null;
    let updatedSelectedItems: ResultsResponse[] | null = null;

    // If it's Results Tab (and status is changed to reviewed) or Reviewed Tab (and status is changed to active) -> remove items
    if (worksheetId === activeWorksheetId &&
      ((resultsMode === SurveillanceModeEnum.Results && status === SurveillanceEntityStatus.Reviewed) ||
       (resultsMode === SurveillanceModeEnum.Reviewed && (status === SurveillanceEntityStatus.Active ||
         status === SurveillanceEntityStatus.ActiveWithComments ||
         status === SurveillanceEntityStatus.Escalated)))) {
      items.forEach(item => {
        const indexSelected: number = selectedItems.findIndex(d =>
          d.id === item.id && d.partitionKey === item.partitionKey);
        const indexResults: number = results.findIndex(d =>
          d.id === item.id && d.partitionKey === item.partitionKey);
        if (indexSelected > -1) {
          updatedSelectedItems = removeItemAt(updatedSelectedItems || selectedItems, indexSelected);
        }
        if (indexResults > -1) {
          updatedResults = removeItemAt(updatedResults || results, indexResults);
          updatedCount--;
        }
        // Hide details if it's one of removed ones
        if (resultSelected && resultSelected.id === item.id && resultSelected.partitionKey === item.partitionKey) {
          setResultSelected(null);
        }
      });

    // If it's Results Tab (and status is changed to active) or Reviewed Tab (and status is changed to reviewed) -> add items
    } else if (worksheetId === activeWorksheetId &&
      ((resultsMode === SurveillanceModeEnum.Results && (status === SurveillanceEntityStatus.Active ||
        status === SurveillanceEntityStatus.ActiveWithComments ||
        status === SurveillanceEntityStatus.Escalated)) ||
        (resultsMode === SurveillanceModeEnum.Reviewed && status === SurveillanceEntityStatus.Reviewed))) {
      items.forEach(item => {
        const indexResults: number = results.findIndex(d =>
          d.id === item.id && d.partitionKey === item.partitionKey);
        // If it's not already in results -> add it
        if (indexResults === -1) {
          updatedResults = [
            ...(updatedResults || results),
            parsePropsToDateTime(item, ['startTime', 'stopTime'])
          ].sort((a, b) =>
            b.startTime.toMillis() - a.startTime.toMillis()
          );
          updatedCount++;
        }
      });
    }

    // Update state's results and selection
    updatedResults && setResults(updatedResults);
    if (updatedSelectedItems) {
      setSelectedItems(updatedSelectedItems);
      setIsSelectAll(false);
      updateVirtualScroller(0);
      setIsIntermediateSelect(false);
    }
    setResultsCount(resultsCount + updatedCount);

  }, [activeWorksheetId, resultSelected, results, resultsCount, resultsMode, selectedItems, setResultSelected, updateVirtualScroller]);

  useEffect(() => {
    eventBus.on(ProfileSignalEventTypes.CLDD_UPDATED, handleUpdateData);
    eventBus.on(WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED, onWSUpdated);
    eventBus.on(SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES, onEntityUpdated);

    return () => {
      eventBus.remove(ProfileSignalEventTypes.CLDD_UPDATED, handleUpdateData);
      eventBus.remove(WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED, onWSUpdated);
      eventBus.remove(SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES, onEntityUpdated);
    };
  }, [handleUpdateData, onEntityUpdated, onWSUpdated]);

  const isSearchEmpty =
    !searchItems?.searchRequestFields?.filter(srf => srf.searchField !== EntitySearchFieldsEnum.SurveillanceReportState).length &&
    !searchItems?.date &&
    !notNil(searchItems?.onlyAttachments) &&
    !notNil(searchItems?.onlyEmpty);
  const appliedFilters = useMemo(
    () =>
      searchItems?.searchRequestFields?.reduce(
        (acc, value) => {
          switch (value.searchField) {
            case EntitySearchFieldsEnum.SurveillanceCompany:
              value.searchTerm && acc.company?.push(value.searchTerm);
              break;
            case EntitySearchFieldsEnum.SurveillanceMedia:
              value.searchTerm && acc.media?.push(value.searchTerm);
              break;
          }
          return acc;
        },
        { company: [], media: [] } as SearchFiltersResponse
      ),
    [searchItems?.searchRequestFields]
  );

  useEffect(() => {
    // Clear results on search params change (check if params object really have changed)
    if (!isSearchItemsEqual(previousSearchItems, searchItems)) {
      resetState();
      setIsSearchItemsChanged(true);
      setIsPageNumberChanged(true);
      if (!isSearchEmpty) {
        setIsLoadingSearchResults(true);
      }
      setPagedSearchItems({...searchItems, pageNumber: 1, pageSize: PAGE_SIZE, cldd: activeCldd });
    }

    setPreviousSearchItems(searchItems);
  }, [activeCldd, isCLDDChanged, isSearchEmpty, previousSearchItems, resetState, searchItems]);

  useEffect(() => {
    if (
      data?.results?.length && !isValidatingResults &&
      (isSearchItemsChanged || isPageNumberChanged || isCLDDChanged)
    ) {
      // Add incoming data to end of results
      setResults((r) => [...r, ...data.results]);
      setLastPageNumber(data.totalPages ?? 1);
      setResultsCount(data?.totalResults ?? 0);
      setIsSearchItemsChanged(false);
      setIsPageNumberChanged(false);
      setIsLoadingSearchResults(false);
      setIsCLDDChanged(false);
    } else if (data?.results?.length === 0) {
      resetState();
      setIsSearchItemsChanged(false);
      setIsPageNumberChanged(false);
      setIsLoadingSearchResults(false);
      setIsCLDDChanged(false);
    }
  }, [
    data,
    isCLDDChanged,
    isValidatingResults,
    isLoadingSearchResults,
    isPageNumberChanged,
    isSearchItemsChanged,
    pageNumber,
    resetState,
    results.length,
    setPageNumber,
  ]);

  useEffect(() => {
    // On Tab change (Search results / reviewed) update random to fetch new data
    setRandom(uniqueId());
    // Unselect items if selected
    if (selectedItems.length) {
      setSelectedItems([]);
      setIsSelectAll(false);
      updateVirtualScroller(0);
      setIsIntermediateSelect(false);
    }
    // Hide details
    if (resultSelected) {
      setResultSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsMode]
  );

  useEffect(
    () => setRecordsCount(resultsCount),
    [resultsCount]
  );

  const lastUpdated = lastModifiedUpdated ?? lastModified;

  const loadLazy = useCallback((event: VirtualScrollerLazyEvent): void => {
    const { last } = event;

    // If last element in scrolled list reached last loaded element -> increase page number and load next page
    if (last as number > 0 &&
      last as number >= results?.length &&
      results.length < resultsCount &&
      pageNumber < lastPageNumber &&
      data !== undefined) {
      setPagedSearchItems({...searchItems, pageNumber: pageNumber + 1, pageSize: PAGE_SIZE, cldd: activeCldd });
      setPageNumber(pn => pn + 1);
      setIsPageNumberChanged(true);
    }
  }, [activeCldd, data, lastPageNumber, pageNumber, results.length, resultsCount, searchItems]);

  const onSelectionChange = useCallback((e: DataTableSelectionMultipleChangeEvent<ResultsResponse[]>): void => {
    // In DataTable only rendered elements ale selected on selectAll
    // So when results are 1000, only ~30-43 are selected, depends on screen height
    // If we would like to manually select all "setSelectedItems(results)" - Primereact can't handle it anyway.
    setSelectedItems(e.value);
    setIsSelectAll(e.type === 'all' && e.value.length > 0);

    if (selectedItems.length === 0 || e.value.length === 0) { // Only on change from/to empty selection
      updateVirtualScroller(e.value.length);
    }

    if (e.type === 'all' && e.value.length > 0) {
      setLastRenderedElement(e.value.length);
    }

    if (e.type === 'all' || // If user clicks "all" checkbox (both on or off)
      (e.type === 'checkbox' && e.value.length === lastRenderedElement) || // If user clicks checkbox and all rendered are selected
      (e.type === 'checkbox' && e.value.length === 0)) { // If deselects all
      setIsIntermediateSelect(false);
    } else if (e.type === 'checkbox' && (e.value.length < selectedItems.length || e.value.length > 0)) { // If user clicks checkbox and selectedItems are decreased
      setIsIntermediateSelect(true);
    }
  }, [lastRenderedElement, selectedItems.length, updateVirtualScroller]);

  const onScrollIndexChange = useCallback((e: VirtualScrollerChangeEvent): void => {
    setLastRenderedElement(e.last as number);
    setIsIntermediateSelect(e.last as number > selectedItems.length && selectedItems.length > 0);
  }, [selectedItems.length]);

  const rowClassName = useCallback((d: DataTableRowData<ResultsResponse[]>): undefined | string => {
    if (resultSelected && d.id === resultSelected.id) {
      //	When a row has been clicked on and the edit/add panel is active
      //	we want the row to render in it's selected state
      return SELECTED_ROW_CLASS;
    }

    return SELECTABLE_ROW_CLASS;
  }, [resultSelected]);

  const onFilterItemChanged = useCallback(
    (
      entitySearchField: EntitySearchFieldsEnum,
      entitySearchValue: string,
      value: boolean
    ): void => {
      if (setSearchItems) {
        if (value) {
          const searchRequestFields = [
            ...(searchItems?.searchRequestFields || []),
            {
              searchField: entitySearchField,
              searchTerm: entitySearchValue,
              metaData: [],
              external: true,
            },
          ];
          setSearchItems({
            ...searchItems,
            searchRequestFields,
          });
          mutateAdditionalProps(
            getAdditionalPropsParsed(
              'searchRequestFields',
              searchRequestFields,
              worksheet?.additionalSearchProperties
            )
          );
        } else {
          const searchRequestFields = searchItems?.searchRequestFields
            ? removeItemAt(
                searchItems.searchRequestFields,
                searchItems.searchRequestFields.findIndex(
                  item => item.searchTerm === entitySearchValue
                )
              )
            : searchItems?.searchRequestFields;
          setSearchItems({
            ...searchItems,
            searchRequestFields,
          });
          mutateAdditionalProps(
            getAdditionalPropsParsed(
              'searchRequestFields',
              searchRequestFields,
              worksheet?.additionalSearchProperties
            )
          );
        }
      }
    },
    [searchItems, worksheet, setSearchItems, mutateAdditionalProps]
  );

  const companyHeaderItems = useMemo(
    () =>
      filters?.company?.map(filterValue => ({
        label: filterValue,
        value: Boolean(
          appliedFilters?.company?.find(company => company === filterValue)
        ),
        onChanged: onFilterItemChanged.bind(
          this,
          EntitySearchFieldsEnum.SurveillanceCompany,
          filterValue
        ),
      })),
    [appliedFilters, filters, onFilterItemChanged]
  );
  const mediaHeaderItems = useMemo(
    () =>
      filters?.media?.map(filterValue => ({
        label: filterValue,
        value: Boolean(
          appliedFilters?.media?.find(media => media === filterValue)
        ),
        onChanged: onFilterItemChanged.bind(
          this,
          EntitySearchFieldsEnum.SurveillanceMedia,
          filterValue
        ),
      })),
    [appliedFilters, filters, onFilterItemChanged]
  );

  return (
    <>
      {isSearchEmpty && (
        <NoData
          header={
            'It looks like no records have been added yet.\nPlease start your search to create a report'
          }
          icon="iconoir-search"
        />
      )}
      {!isSearchEmpty && results.length === 0 && !isLoadingResults && !isLoadingSearchResults && (
        <NoData header="No Results Found" icon="iconoir-search-xmark"/>
      )}
      {((!isSearchEmpty && results.length > 0) || isLoadingResults || (isLoadingSearchResults && !isSearchEmpty)) && (
        <DataTable
          ref={dt}
          className="surveillance-results__table grow-to-fill"
          dataKey="id"
          loading={isLoadingResults || isLoadingWorksheet || isLoadingTimeout || (isLoadingSearchResults && !isSearchEmpty) || isValidatingResults}
          value={results}
          scrollable
          selectionMode="checkbox"
          selection={selectedItems}
          onSelectionChange={onSelectionChange}
          onRowClick={(e:DataTableRowClickEvent):void => setResultSelected(e.data as ResultsResponse)}
          virtualScrollerOptions={{
            className: 'grow-to-fill',
            itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
            lazy: true,
            step: 10,
            onLazyLoad: loadLazy,
            loading: isLoadingResults,
            onScrollIndexChange: onScrollIndexChange,
          } as VirtualScrollerProps & { key: Key }}
          footer={
            selectedItems?.length
              ?	<TableFooter
                selectedItems={selectedItems}
                activeWorksheetId={activeWorksheetId}
                activeWorksheetName={activeWorksheetName}
                searchItems={searchItems}
                recordsCount={recordsCount}
                resultsLength={results.length}
                isSelectAll={isSelectAll}
                toastRef={toastRef}
                resultsMode={resultsMode}
              />
              : <></>
          }
          rowClassName={rowClassName}
        >
          {/* For testing: <Column header="#" field="elementCounter" /> */}
          <Column selectionMode="multiple" frozen headerClassName={clsx({intermediate: isIntermediateSelect})} ></Column>
          <Column
            header={
              <FilteringHeader
                className='surveillance-results__table-header'
                menuClassName='surveillance-results__table-header-context-menu'
                label='Company'
                items={companyHeaderItems}
              />
            }
            field="company"
          />
          <Column
            header="User"
            field="userName,email,number"
            body={UserCell}
          />
          <Column
            header="Counterpart"
            field="counterParty,groupName"
            body={(data, options): JSX.Element => DoubleLineCounterpart<ResultsResponse>(data, options, true)}
          />
          <Column
            header={
              <FilteringHeader
                className='surveillance-results__table-header'
                menuClassName='surveillance-results__table-header-context-menu'
                label='Media'
                items={mediaHeaderItems}
              />
            }
            field="provider,media"
            body={MediaChip}
          />
          <Column
            header="Date"
            field="startTime"
            body={(data, config):JSX.Element => ReadableDate<ResultsResponse>(data, config, 'dd LLL yyyy, HH:mm:ss ZZZZ')}
          />
          <Column
            header="Content"
            field="content,isAttachment"
            className="content-cell"
            body={Content}
          />
        </DataTable>
      )}
      <footer className="surveillance-footer">
        <div className="surveillance-active-ws">Active workspace: {activeCldd ?? '-'}</div>
        <div className="surveillance-last-updated">
          {numberFormatter(resultsCount)} results found,
          Last updated {lastUpdated?.toFormat('HH:mm:ss LLL dd, yyyy (ZZZZ)') ?? '-'}
        </div>
      </footer>
    </>
  );
};
export default Results;
