import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { clsx } from 'clsx';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import { SurveillanceEntityStatus } from '../../Models/Enums';
import { ResultsResponse } from '../../Models/ReportsResponse';

import { Reason } from './Models/Enums';
import { markAsReviewedValidator } from './Models/Validators';
import { StateRequest, useUpdateMarkAsReviewed } from './Services/MarkAsReviewedService';

import styles from './MarkAsReviewedPopup.module.scss';

export interface MarkAsReviewedPopupReferenceProps {
  submit: () => Promise<StateRequest | undefined>;
}

export interface MarkAsReviewedPopupProps {
  activeWorksheetId: string;
  selectedItems: ResultsResponse[];
}

const ReasonOptions = [
  { label: 'False positive', value: Reason.FalsePositive },
  { label: 'Duplicate', value: Reason.Duplicate },
  { label: 'Other', value: Reason.Other }
];

const MarkAsReviewedPopup = forwardRef<MarkAsReviewedPopupReferenceProps, MarkAsReviewedPopupProps>(
  ({
    activeWorksheetId,
    selectedItems,
  }, ref): JSX.Element => {
    const [ reason, setReason] = useState<Reason>();
    const [ notes, setNotes] = useState<string>();
    const [ isValid, setIsValid ] = useState<boolean>(false);
    const [ isValidationVisible, setIsValidationVisible ] = useState<boolean>(false);
    const { trigger: triggerUpdate } = useUpdateMarkAsReviewed(activeWorksheetId);

    useImperativeHandle(ref, () => ({
      submit: (): Promise<StateRequest | undefined> => {
        if (isValid) {
          return triggerUpdate({
            items: selectedItems.map(el => ({
              id: el.id,
              partitionKey: el.partitionKey,
            })),
            assignedUserId: null,
            status: SurveillanceEntityStatus.Reviewed,
            reason: reason || Reason.Other,
            note: notes || '',
            worksheetId: activeWorksheetId || '',
          });
        } else {
          setIsValidationVisible(true);
          return new Promise((resolve, reject) => {
            reject('validator');
          });
        }
      },
    }));

    useEffect(() => {
      const validate = async ():Promise<void> => {
        if (!activeWorksheetId) {
          return;
        }
        try {
          const result = await markAsReviewedValidator.validateAsync(
            { reason, notes },
            { convert: false }
          );
          if (result) {
            setIsValid(true);
          }
        } catch(e) {
          setIsValid(false);
        }
      };

      validate();
    }, [activeWorksheetId, notes, reason]);

    const reasonErrorMessage = (): JSX.Element => (
      <small className="reason-invalid">Please select reason</small>
    );

    const notesErrorMessage = (): JSX.Element => (
      <small className="note-invalid">Please enter a note</small>
    );

    return (
      <form>
        <div className={styles.surveillanceMarkAsReviewedPopup}>
          <div className={styles.surveillanceMarkAsReviewedPopupReasons}>
            <label>Reasons*</label>
            <Dropdown
              value={reason}
              className={clsx('direction--row grow-to-fill',
                { [styles.invalid]: isValidationVisible && !reason })}
              placeholder="Select reason"
              options={ReasonOptions}
              onChange={(e): void => setReason(e.value)}
            />
            {isValidationVisible && !reason && reasonErrorMessage()}
          </div>
          <div className={styles.surveillanceMarkAsReviewedPopupNote}>
            <label>Note{reason === Reason.Other ? '*' : ''}</label>
            <InputTextarea
              id="spot-notes"
              autoResize
              placeholder={` Please add a${ reason === Reason.Other ? '' : 'n optional' } note/comment `}
              onFocus={(e): void => e.target.select()}
              rows={5}
              value={notes ?? ''}
              onChange={(e): void => setNotes(e.target.value)}
              className={isValidationVisible && reason === Reason.Other && (!notes || notes === '') ? 'p-invalid' : ''}
            />
            {isValidationVisible && reason === Reason.Other && (!notes || notes === '') && notesErrorMessage()}
          </div>
        </div>
      </form>
    );
  }
);

MarkAsReviewedPopup.displayName = 'ExportPopup';

export default MarkAsReviewedPopup;
