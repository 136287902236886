import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

import { clsx } from "clsx";

import { Button } from 'primereact/button';

import { ResultsModeEnum } from './Models/Enums';

import SanctionsSearch from './Components/SanctionsSearch';
import SanctionedVesselDataTable from './Components/SanctionedVesselDataTable';
import SanctionedEntityDataTable from './Components/SanctionedEntityDataTable';
import SanctionedIndividualDataTable from './Components/SanctionedIndividualDataTable';
import SanctionDetails from './Components/SanctionDetails';
import SanctionedVesselCharting from './Components/SanctionedVesselCharting';
import SanctionedEntityCharting from './Components/SanctionedEntityCharting';
import SanctionedIndividualOtherInfo from './Components/SanctionedIndividualOtherInfo';
import SanctionedIndividualDetails from './Components/SanctionedIndividualDetails';
import SecondaryNavigation from "components/SecondaryNavigation";

import type { IVesselSanctionResult } from './Models/VesselSanctionResult';

import type { SanctionsConfig } from 'index';

import { apiLegalEntityResponse, apiIndividualSanctionSearchModel } from './Models';

import './SanctionsPage.scss';

interface sanctionsPageProps {
    items: SanctionsConfig[]
    resultsMode: ResultsModeEnum
}

function SanctionsPage(props: sanctionsPageProps) {

    const { items } = props;

    const { search } = useParams();
    const [ searchTerm, setSearchTerm ] = useState<string>(search ?? "*");
    const [ activInfoTab, setActiveInfoTab ] = useState<"visualisation" | "details" | "other">("visualisation");
    const [ selectedItem, setSelectedItem ] = useState<IVesselSanctionResult | apiLegalEntityResponse | apiIndividualSanctionSearchModel | null>(null);
    const [ displayLoader, setDisplayLoader ] = useState<boolean>(false);

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

	const isVesselSanctions = props.resultsMode === ResultsModeEnum.VesselSanctions;
	const isEntitySanctions = props.resultsMode === ResultsModeEnum.LegalEntitySanctions;
	const isIndividualSanctions = props.resultsMode === ResultsModeEnum.IndividualSanctions;
	const detailsPaneVisible = !!(selectedItem || isTabletOrMobile || !isIndividualSanctions);

    useEffect(() => {
		// Individuals have no 'visualisation' tab item
		if (isIndividualSanctions && activInfoTab === 'visualisation') {
			setActiveInfoTab('details');
		} else if (!isIndividualSanctions && activInfoTab === 'other') {
			setActiveInfoTab('visualisation')
		}
    }, [activInfoTab, isIndividualSanctions]);

    useEffect(() => {
        //  Handle an item being selected but the details tab NOT currently being active
        if (selectedItem && activInfoTab !== "details") setActiveInfoTab("details");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    const gestures = useSwipeable({
        onSwipedRight: (e) => {
            setSelectedItem(null);
        },
      });

    return <>
		<header>
			{/* <h1>Sanctions</h1> */}
			<SanctionsSearch
				currentSearchMode={props.resultsMode}
				displayLoader={displayLoader}
				setSearchTerm={setSearchTerm}
				currentSearchTerm={searchTerm}
			/>
		</header>
		<nav className="tabbed-navigation__container">
			<SecondaryNavigation
				items={items}
				// selectedItem stays set when tab is changed. Because of that, improper item is passed (and displayed) in Details section.
				onBeforeNavigation={() => {
					setSelectedItem(null)
					return Promise.resolve();
				}}
			/>
		</nav>
		<main
			className={clsx(
				"grow-to-fill",
				{ "drawer--active": isTabletOrMobile && selectedItem }
			)}
			{...(detailsPaneVisible && {
				"data-cols": "9,3",
				"data-drawer-style": "slide",
				"data-drawer-position": "alongside-right",
			})}
		>
			<section className="overflow--hidden">
				<div className="grow-to-fill">
					{ isVesselSanctions &&
						<SanctionedVesselDataTable
							key={props.resultsMode}
							setSelectedItem={setSelectedItem}
							selectedItem={selectedItem as IVesselSanctionResult}
							searchTerm={searchTerm}
							setDisplayLoader={setDisplayLoader}
						/>
					}
					{ isEntitySanctions &&
						<SanctionedEntityDataTable
							key={props.resultsMode}
							setSelectedItem={setSelectedItem}
							selectedItem={selectedItem as apiLegalEntityResponse}
							searchTerm={searchTerm}
							setDisplayLoader={setDisplayLoader}
							isMobile={isTabletOrMobile}
						/>
					}
					{ isIndividualSanctions &&
						<SanctionedIndividualDataTable
							key={props.resultsMode}
							setSelectedItem={setSelectedItem}
							selectedItem={selectedItem as apiIndividualSanctionSearchModel}
							searchTerm={searchTerm}
							setDisplayLoader={setDisplayLoader}
						/>
					}
				</div>
			</section>
			<aside 
				{...gestures} 
				className={clsx('sanction-details__details-pane', { 'hidden': !detailsPaneVisible })}
			>
				<Button
					className={clsx({ "hidden": !isTabletOrMobile })}
					icon="iconoir-nav-arrow-left icon--small"
					text
					size="small"
					onClick={() => setSelectedItem(null)}
				>
					Return to Search Results
				</Button>
				<header className="sanction-details__header">
						{isIndividualSanctions && !isTabletOrMobile && 
							<Button
								className="close-button"
								icon="iconoir-xmark icon--tiny"
								onClick={() => setSelectedItem(null)}
								text
							/>
						}
						<div
							className={clsx(
								"tabbed-navigation-set--share-space grow-to-fill sanction-details__tabbed-nav",
								{ "hidden": isTabletOrMobile && !isIndividualSanctions }
							)}>
							{!isIndividualSanctions && 
								<Button
									className={clsx(
										"p-button--tab-style",
										{ "active": activInfoTab === "visualisation"}
									)}
									onClick={() => setActiveInfoTab("visualisation")}
									size='small'
								>
									Visualisation
								</Button>}
								<Button
									className={clsx(
										"p-button--tab-style",
										{ "active": activInfoTab === "details"}
									)}
									onClick={() => setActiveInfoTab("details")}
									size='small'
								>
									Details
								</Button>
							{isIndividualSanctions && 
								<Button
									className={clsx(
										"p-button--tab-style",
										{ "active": activInfoTab === "other"}
									)}
									onClick={() => setActiveInfoTab("other")}
									size='small'
								>
									Other information
								</Button>}
						</div>
					{/* </nav> */}
				</header>
				<div className="direction--column overflow--y no--padding">
					{ activInfoTab === "details" && isIndividualSanctions &&
						<SanctionedIndividualDetails
							selectedItem={selectedItem as apiIndividualSanctionSearchModel}
						/>
					}
					{ activInfoTab === "details" && !isIndividualSanctions &&
						<SanctionDetails
							selectedItem={selectedItem as IVesselSanctionResult}
							resultsMode={ props.resultsMode }
						/>
					}
					{
						activInfoTab === "visualisation" && isVesselSanctions &&
						<SanctionedVesselCharting
							searchTerm={searchTerm}
						/>
					}
					{
						activInfoTab === "visualisation" && isEntitySanctions &&
						<SanctionedEntityCharting
							searchTerm={searchTerm}
						/>
					}
					{
						activInfoTab === "other" && isIndividualSanctions &&
						<SanctionedIndividualOtherInfo
							selectedItem={selectedItem as apiIndividualSanctionSearchModel}
						/>
					}
				</div>
			</aside>
		</main>
    </>
}

export default SanctionsPage;