import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable, DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';

import GridElement from 'components/GridElement';
import Loader from 'components/Loader';
import ToastMessage, {
  ToastMessageRef, ToastSeverity,
} from 'components/ToastMessage';

import { deferNextAction } from '../CargoTracker/Components/CargoEditWarningDialog';

import { DistListWarningDialogEvents } from './Components/CloseWarningDialog';
import SidePanel from './Components/SidePanel';
import { DistributionListResponse } from './Models/distribution-list-response';
import { useGetDistLists } from './Services/DistListService';
import { DistListSignalEventTypes, DistributionListSocket } from './Services/SignalRSocket';

import { sortByDateTime } from 'helpers/DataTable/SortingFunctions';
import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { stripParams } from 'helpers/Utils/string';

import eventBus from 'server/EventBus';
import F from 'types/generic-type';

import './DistListPage.scss';

const DistListPage = (): JSX.Element => {
  const { loadDistlistData, loadDistlistError, loadDistlistIsLoading } = useGetDistLists();
  const toast = useRef<ToastMessageRef>(null);
  const [ distLists, setDistLists] = useState<DistributionListResponse[] | undefined | void>(undefined);
  const [ isRightColumnVisible, setIsRightColumnVisible] = useState<boolean>(false);
  const [ selectedDL, setSelectedDL] = useState<DistributionListResponse | null>(null);
  const [ isLoading, setIsLoading] = useState<boolean>(false);
  const [ activeDetailTab, setActiveDetailTab ] = useState<number>(0);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { signal } = useSignalR();
  
  const navigate: NavigateFunction = useNavigate();
  const currentRoute = useLocation();
  const { distListIdParam, emailIdParam } = useParams();

  useEffect(() => {
    setDistLists(loadDistlistData);
  }, [loadDistlistData]);

  useEffect(() => {
    if (distListIdParam && !loadDistlistIsLoading && distLists !== undefined) {
      const foundDistList = distLists.find(list => list.id === distListIdParam);
      if (foundDistList) {
        setSelectedDL(foundDistList);
        setIsRightColumnVisible(true);
      } else {
        toast.current?.replace({
          title: 'Wrong Distribution List ID',
          message: 'Provided Distribution List ID in link was not found',
          severity: ToastSeverity.WARN
        });
      }
    }
  }, [distListIdParam, distLists, loadDistlistIsLoading]);

  const addDLButton = useMemo(() => {
    const handleAddDL = (): void => {
      setActiveDetailTab(1);
      setSelectedDL(null);
      setIsRightColumnVisible(true);
    };

    return (
      <Button
        size="small"
        className="distlist-add no-background"
        onClick={async ():Promise<void> => {
          const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
            null,
            () => handleAddDL());
          if (result) {
            return;
          }

          handleAddDL();
        }}
      >
        Add distribution list
      </Button>);
  }, []);

  const handleSelection = (value?: DistributionListResponse):void => {
    if (value?.id) {
      const route = stripRoute();
      navigate(`${ route }${ value.id }`);
      setSelectedDL(value);
      setIsRightColumnVisible(true);
    } else {
      handleCloseSidePanel();
    }
  };

  const handleCloseSidePanel = ():void => {
    const route = stripRoute();
    navigate(route);
    setSelectedDL(null);
    setIsRightColumnVisible(false);
  };

  const stripRoute = ():string => {
    let route = stripParams(currentRoute.pathname, distListIdParam); // // Strip DistListID, selectedDL?.id
    route = stripParams(route, emailIdParam); // Strip also email ID
    return route;
  };

  const handleSelectionChange = async (e: DataTableSelectionSingleChangeEvent<DataTableValueArray>): Promise<void> => {
    const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => handleSelection(e.value as DistributionListResponse));
    if (result) {
      return;
    }

    handleSelection(e.value as DistributionListResponse);
  };

  useEffect(() => {
    setIsLoading(loadDistlistIsLoading || loadDistlistError !== undefined);
  }, [loadDistlistIsLoading, loadDistlistError]);

  const itemTemplate = (item: DistributionListResponse): JSX.Element => (
    <div className={clsx('list-element')}
      onClick={(): void => handleSelection(item)}
      key={item.id}>
      <GridElement header="Distribution List" body={item.name} />
      <GridElement header="Recipients" body={`${ item.recipientsCount }`} />
      <GridElement header="Last Email" body={ReadableDate(item, { field: 'lastEmailDate' }, 'dd LLL yyyy, HH:mm ZZZZ')} />
    </div>
  );

  useEffect(() => {
    const socket: DistributionListSocket = DistributionListSocket.instance;
    socket.init(signal);
  }, [signal]);

  const handleUpdateData = useCallback((event: CustomEvent<DistributionListResponse>): void => {
    if (distLists === undefined) {
      return;
    }

    const index: number = distLists.findIndex(d => d.id === event.detail.id);
    const parsedData = parsePropsToDateTime(event.detail, ['lastEmailDate']);
    const newData = (index !== -1 && index !== undefined) ?
      replaceItemAt(distLists, parsedData, index) : // Update existing
      [ // Add new item
        ...distLists,
        parsedData
      ];
    setDistLists(newData);
  }, [distLists]);

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);
    };
  }, [handleUpdateData]);

  return (
    <>
      {!isMobile && <header className="align--right distlist-header">{addDLButton}</header>}
      <main
        className={clsx(
          'distlist-container',
          distLists !== undefined && distLists?.length > 0 && 'distlist-container-table',
          { 'drawer--active': isRightColumnVisible },
          'grow-to-fill')}
        {...(isRightColumnVisible && {
          'data-cols': '5,7',
          'data-drawer-style': 'slide',
          'data-drawer-position': 'alongside-right',
        })}
      >
        {isLoading && (
          <section className="distlist-empty grow-to-fill">
            <Loader className="no-background" />
          </section>
        )}
        {distLists !== undefined && distLists.length === 0 && !isLoading && (
          <section className="distlist-empty grow-to-fill">
            <i className="iconoir-folder-plus no-background"></i>
            <h2 className="no-background">
              It looks like no list has been added yet
            </h2>
            {addDLButton}
          </section>
        )}
        {distLists !== undefined && distLists?.length > 0 && !isLoading && (
          <section className="grow-to-fill">
            {isMobile ?
              <DataView value={distLists}
                itemTemplate={itemTemplate}
                className="distlist__view grow-to-fill no-background" /> :
              <DataTable
                className="distlist__table--distLists grow-to-fill"
                dataKey="id"
                value={distLists}
                sortField="lastEmailDate"
                sortOrder={-1}
                scrollable
                removableSort
                selectionMode="single"
                selection={selectedDL}
                onSelectionChange={handleSelectionChange}
                metaKeySelection={false}
              >
                <Column header="Distribution List" field="name" sortable />
                <Column header="Recipients" field="recipientsCount" sortable />
                <Column header="Last Email"
                  field="lastEmailDate"
                  body={(data, config):JSX.Element => ReadableDate<DistributionListResponse>(data, config, 'dd LLL yyyy, HH:mm ZZZZ')}
                  sortable
                  sortFunction={(e: ColumnSortEvent):F<DateTime>[] => sortByDateTime(e, 'lastEmailDate')}
                />
              </DataTable>}
          </section>
        )}
        {isRightColumnVisible && (
          <SidePanel
            activeDL={selectedDL}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
            handleClose={handleCloseSidePanel}
            toast={toast} />
        )}
      </main>
      {(isMobile && !isRightColumnVisible) && <footer className="align--right distlist-footer">{addDLButton}</footer>}
      <ToastMessage ref={toast} className="distlist-toast" />
    </>
  );
};

export default DistListPage;
