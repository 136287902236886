import axios from "axios";
import { DateTime, Duration } from "luxon";
import useSWR from "swr";

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';

export interface ParssedDateTimeResult {
	original: string;
	from: DateTime;
    to: DateTime;
	fromString: string;
	toString: string;
	duration: Duration;
}

export const useDateStringConvert = (value: string | null | undefined) => {
    const { data, error } = useSWR(value, ConvertStringApi.getParsedDate);

    return { data, error };
}

export class ConvertStringApi {

	static getParsedDate = (value: string) => {

		const punctuationless = value.replace(/[^\w\s\-+.:<>]/g, "").replace(/\s+/g, " ");

		return axios.request({ url: `search/parse/date`, data: {value: punctuationless }, method: "POST" })
			.then(r => ({ results: ConvertStringApi.parseDateTime(r.data) }))
			.catch((e) => {
				ErrorToastService.handleError(e, [500, 503]);

				throw e;
			});
	};

	static parseDateTime = (date: { original: string; from: string; to: string; }): ParssedDateTimeResult => {
		const { from, to, original } = date;

		const f: DateTime = DateTime.fromISO(from, { zone: 'utc' });
		const t: DateTime = DateTime.fromISO(to, { zone: 'utc' });

		return {
			original,
			fromString: `${from}`,
			toString: `${to}`,
			from: f,
			to: t,
			duration: t.plus({ minutes: 1 }).diff(f, ['years', 'quarters', 'months', 'days', 'hours', 'minutes', 'seconds'])
		}
	}
}