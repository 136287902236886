import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams, NavigateFunction, useNavigate, useLocation } from 'react-router-dom';

import { clsx } from 'clsx';

import { useSignalR } from 'App';

import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';

import SecondaryNavigation from 'components/SecondaryNavigation';
import ColumnLayoutSelector, { ChartingDisplayState } from 'components/ColumnLayoutSelector';
import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';

import { ArtisPricesSocket } from 'modules/ArtisCharting/Services/Socket';
import { usePackages } from './Services/hooks';
import NoAccess from './Components/NoAccess';
import PriceGrid, { PriceGridHandles} from './Components/PriceGrid';
import CandleStickChart from './Components/CandleStickChart';
import Chart from './Components/Chart';
import PriceChangeSubscriptions from './Components/PriceChangeSubscriptions';
import { productTenorsToChartData } from './Models/Helpers';

import type { ActiveChartPrice } from './Models/ArtisPrices';
import type { ArtisPackage, ArtisProduct, TenorPeriods, TenorWindowKey } from './Models/Packages';

import styles from './ArtisCharting.module.scss';

const ArtisCharting = () => {

	const { artispackage } = useParams();
	const location = useLocation();
	const navigate: NavigateFunction = useNavigate();

	const pricegrid = useRef<PriceGridHandles>(null);
	const toastRef = useRef<ToastMessageRef>(null);

	const { signal } = useSignalR();

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

	const { getSetting } = useLoadUserSettings();
	const userSetting = getSetting(UISettings.ARTIS_HISTORICAL_CHARTS);

	const [ selectedPackage, setSelectedPackage ] = useState<ArtisPackage | null>(null);
	const [ selectedProduct, setSelectedProduct ] = useState<ArtisProduct | null>();
	const [activePrice, setActivePrice] = useState<ActiveChartPrice | null>(null);
	const [ showCharting, setShowCharting ] = useState<ChartingDisplayState>(ChartingDisplayState.Hidden);
	const [ showForwardCharting, setShowForwardCharting ] = useState<ChartingDisplayState>(ChartingDisplayState.Hidden);
	const [ layoutSettings, setLayoutSettings ] = useState<string>('12,0');
	const [selectedFrequency, setSelectedFrequency] = useState<keyof TenorWindowKey | undefined>(activePrice?.windows[0]?.frequency);
	const [selectedPeriod, setSelectedPeriod] = useState<TenorPeriods | undefined>(activePrice?.windows[0]?.periods[0]);

	const { packages, /* error, */ isLoading: loadingPackages } = usePackages();

	//	Set up ability to load/save user settings for this module
	const { trigger: saveActivePackage } = useSaveUserSetting();

	useEffect(() => {
		setSelectedPackage( packages.find(p => p.source === artispackage) ?? null )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artispackage])


	useEffect(() => {

		let socket: ArtisPricesSocket;
		socket = ArtisPricesSocket.instance
		socket.init(signal);

	}, [signal])


	useEffect(() => {

		if (!packages || !packages.length) return;

		if (artispackage) {
			//	The URL has a package - so we should check the user even has the right to access it
			if ( !packages.map(p => p.source).includes(artispackage)) {
				//	The package in the URL doesn't match what the user has access too
				setSelectedPackage(null);
				return
			}
		}

		if (!userSetting?.lastVisited) {
			//	Likely the first time the user has visited the module. So default to
			//	first available grid for the package
			const [ defaultPackage, ] = packages;

			setSelectedPackage(defaultPackage);
			return;
		}

		setSelectedPackage(userSetting.lastVisited);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packages])


	useEffect(() => {
		if (!selectedPackage) return;

		//	Save this changwe to the user profile
		saveActivePackage({
			setting: UISettings.ARTIS_HISTORICAL_CHARTS,
			data: {
				...userSetting,
				lastVisited: selectedPackage
			}
		})

		switchGrid(selectedPackage);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPackage])

	useEffect(() => {
		if (!activePrice) {
			setShowCharting(ChartingDisplayState.Hidden);

			return;
		};

		/**
		 * If the display state is hidden - default it to small otherwise
		 * keep it as current
		**/
		setShowCharting( curr => curr === ChartingDisplayState.Hidden ? ChartingDisplayState.Small : curr);

	}, [activePrice])

	useEffect(() => {
		if(showCharting !== ChartingDisplayState.Hidden) {
			setShowForwardCharting(ChartingDisplayState.Hidden);
		}
	}, [showCharting]);

	useEffect(() => {
		if(showForwardCharting !== ChartingDisplayState.Hidden) {
			setActivePrice(null);
			pricegrid.current?.deselect();
			setShowCharting(ChartingDisplayState.Hidden);
		}
	}, [showForwardCharting]);

	useEffect(() => {
		const size = Math.max(showCharting, showForwardCharting);
	
		switch(size) {
			case ChartingDisplayState.Hidden:
				setLayoutSettings('12,0');

				if (!pricegrid.current) break;
				setActivePrice(null);
				/**
				 * When hidding - we also want to make sure we nullify
				 * any active cell selection
				**/
				pricegrid.current.deselect();
				break;

			case ChartingDisplayState.Small:
				setLayoutSettings('9,3');
				break;
			case ChartingDisplayState.Medium:
				setLayoutSettings('6,6');
				break;
			case ChartingDisplayState.Full:
				setLayoutSettings('0,12');
				break;
		}

	}, [showCharting, showForwardCharting]);

	const switchGrid = (p: ArtisPackage) => {
		navigate(`/curves/${p.source}`);
	}

	const openChartInNewWindow = (): void => {
		if (activePrice && selectedPackage && selectedFrequency && selectedPeriod) {
			window.open(`${location.pathname}/${activePrice.product}/${activePrice.tenorCode}/${activePrice.index}/${selectedFrequency}/${selectedPeriod}`,
				// it's only the name, it's ok to pass it through the new window name
				// use `Date.now()` as a seed to distinguish windows with the same params
				`${activePrice.tenorName.original}|${activePrice.name}|${Date.now()}`,
				'popup,width=400,height=780'
			);
		}
	}

	const onShowForwardChart = (product: ArtisProduct) => {
		setSelectedProduct(product);
		setShowForwardCharting(curr => curr === ChartingDisplayState.Hidden ? ChartingDisplayState.Small : curr);
	}

	const forwardChartSeries = selectedProduct
		&& productTenorsToChartData(selectedProduct?.id, selectedProduct.label, pricegrid.current?.getPrices()?.results);

	if (loadingPackages) {
		//	TODO - add loading bar…
		return <></>
	}

	if (!selectedPackage && packages) {
		return <>
		<nav className='tabbed-navigation-set__container'>
			<SecondaryNavigation items={packages} pathKey='source'/>
		</nav>
			<main className='direction--column'>
				<NoAccess artispackage={artispackage}/>
			</main>
		</>
	}

  return <>
		<nav className='tabbed-navigation-set__container'>
			<SecondaryNavigation items={packages} pathKey='source' />
		</nav>
		<main
			className={clsx(
				styles.main,
				'grow-to-fill',
				{ 'drawer--active': isTabletOrMobile && selectedPackage }
			)}
			data-cols={layoutSettings}
			data-drawer-style="over"
			data-drawer-position="bottom"
		>
			<section className={clsx(
				'grow-to-fill overflow--hidden',
				styles.section
			)}>
			<PriceGrid
				ref={pricegrid}
				artispackage={selectedPackage}
				setActivePrice={setActivePrice}
				onShowForwardChart={onShowForwardChart}
			/>
			</section>
		  {activePrice && showCharting !== ChartingDisplayState.Hidden &&
				<aside className={styles.aside}>
					<header className={clsx(
						"no--tabs align--rightd",
					)}>
						<span>
							<h2>{ activePrice.name }</h2>
						  <h3>{activePrice.tenorName.display}</h3>
						</span>
					  <ColumnLayoutSelector
							state={showCharting}
							changeState={setShowCharting}
						  openInNewWindow={openChartInNewWindow}
						/>
					</header>
				  <CandleStickChart
						price={activePrice}
					  setFrequency={setSelectedFrequency}
					  setPeriod={setSelectedPeriod}
						toastRef={toastRef}
					/>
				  <PriceChangeSubscriptions price={activePrice} />
				</aside>
			}
		  {selectedProduct && showForwardCharting !== ChartingDisplayState.Hidden &&
				<aside className={styles.aside}>
					<header className='no--tabs'>
						<span>
							<h2>{ selectedProduct.label }</h2>
						  <h3>Forward charting</h3>
						</span>
					  <ColumnLayoutSelector
							state={showForwardCharting}
							changeState={setShowForwardCharting}
						/>
					</header>
				  <Chart
						series={forwardChartSeries}
						size={showForwardCharting}
						feed={selectedProduct.label}
						sourceName='ForwardCharting'
						toastRef={toastRef}
					/>
				</aside>
			}
			<ToastMessage ref={toastRef} />
		</main>
	</>
}

export default ArtisCharting;
