import { useState, useEffect, ReactNode } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { clsx } from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { AzureMapsProvider } from 'react-azure-maps';

import { DEFAULT_SPEED } from 'modules/DistanceCalc/Models/Consts';

import DistCalcMap from './Components/Map';
import ResultsPanel from './Components/ResultsPanel/ResultsPanel';
import DistanceCalcSearchEntity from './Components/EntitySearch/EntitySearch';
import { useAreasToAvoid, useCalculateDistance } from './Services/CalculateDistances';
import { Group } from './Models/Enums';

import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { CalculationResult } from './Models/CalculationResponse';
import type { AreaToAvoidRequest, AreaToAvoidRequestExtended } from './Models/AreasToAvoidResponse';
import type { CalculationRequest, CalculationRequestLocation } from './Models/CalculationRequests';
import type { Suggestion, SuggestionItem } from 'components/Autocomplete';
import type { CalcParamChange } from './Models/CalcParamsChange'

import './DistanceCalcPage.scss';
import { TimeZonesEnum } from 'components/TimeZonePicker/TimeZonePicker';

export default function DistanceCalcPage(): JSX.Element {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
    const gestures = useSwipeable({
        onSwipedRight: (e) => {
            setRouteSettingsVisible(false);
        },
    });

    const [ calcParams, setCalcParams ] = useState<CalculationRequest>({ locations: [], speed: DEFAULT_SPEED, timeZone: TimeZonesEnum.UTC });
    const [ externalSearchParams, setExternalSearchParams ] = useState<SuggestionItem[]>([])
    const [ externalSuggestions, setExternalSuggestions ] = useState<Suggestion[]>([])
    const [ routeSettingsVisible, setRouteSettingsVisible ] = useState(false);
    // used to keep results between calculations. Avoid blinking/flashing CalcResults panel on the right.
    const [ calcResults, setCalcResults ] = useState<CalculationResult | undefined>(undefined);
    // add random token as with second request SWR doesn't query API
    const [ random, setRandom ] = useState(Date.now());
    const { data, error, isLoading } = useCalculateDistance(random, calcParams);
    const { data: areasToAvoidData } = useAreasToAvoid();

    // useEffect(() => {
    //     // Handle server errors locally.
    //     // Handle server errors with usage of the ErrorContext. Display Toast.
    //     ErrorToastService.handleError(error, [400, 500, 503]);
    // }, [error])

    function handleCalcParamsChange(change: CalcParamChange): void {
        setRandom(Date.now());
        switch (change.name) {
            case 'locations':
                handleParamTokenChange(change.value as CalculationRequestLocation[]);
                break;
            case 'avoiding':
                handleAvoidingsChange(change.value as AreaToAvoidRequest[]);
                break;
            case 'speed':
                handleSpeedChange(change.value);
                break;
            case 'startDate':
                handleStartDateChange(change.value as ParssedDateTimeResult);
                break;
            case 'timeZone':
                handleTimeZoneChange(change.value as TimeZonesEnum);
                break;
            default:
                break;
        }
    }

    const handleParamTokenChange = (change: CalculationRequestLocation[]): void => {

        setCalcParams(
            currentParams => {
                /**
                 * If locations have different speed values then disply "Mixed".
                 * If every location has the same speed value display it in "main speed" field.
                 * Otherwise just use current speed.
                 * @returns Speed value
                 */
                function getSpeed (): CalculationRequest["speed"] {
                    // omit last item as it does not neccessary contains speed as it's speed doesn't count in calculation
                    const haveTheSameSpeed = locations.slice(0, -1).every((loc, _index, arr) => loc.speed === arr[0].speed);

                    if (locations.length === 2 || haveTheSameSpeed) {
                        return locations[0]?.speed ?? DEFAULT_SPEED;
                    }

                    return 'Mixed';
                }

                //  update locations collection so existing tokens remain unchanged,
                //  new tokens are added to the collection and anything deleted
                //  is removed from items passed in calcuation request
                const locations = change.reduce(
                    (a: CalculationRequestLocation[], c: CalculationRequestLocation) => {
                        const currentItem = currentParams.locations.find(l => l.searchEntityId === c.searchEntityId && l.group === c.group);

                        return [
                            ...a,
                            {
                                ...currentItem,
                                ...c,
                                speed: c.speed ?? currentItem?.speed ?? DEFAULT_SPEED
                            }
                        ]}
                    , []
                )

                return {
                    ...currentParams,
                    locations,
                    speed: getSpeed()
                }
            }
        );
    }

    const handleAvoidingsChange = (avoiding: AreaToAvoidRequestExtended[] = []): void => {
        setCalcParams(
            currentParams => ({
                ...currentParams,
                avoiding
            })
        );

        handleSetupAvoidingAsExternalSearchParams(avoiding);
    }

    const handleSpeedChange = (speed: CalcParamChange["value"]): void => {
        const speedValue = typeof speed === 'number' ? speed : DEFAULT_SPEED;

        setCalcParams(
            currentParams => ({
                ...currentParams,
                // assign every location value from "main" speed field
                locations: currentParams.locations.map(loc => ({...loc, speed: speedValue }) ),
                speed: speedValue
            })
        );
    }

    const handleStartDateChange = (startDate: ParssedDateTimeResult): void => {
        setCalcParams(
            currentParams => ({
                ...currentParams,
                startDate:  startDate
            })
        );
    }

    const handleAddAvoidingAsExternalSearchParam = (avoiding: SuggestionItem[]): void => {
        const newAvoiding = calcParams.avoiding?.map((item) => {
            const found = avoiding.findIndex(i => i.id === item.id) > -1;

            // update value of added elements
            if (found) {
                return { ...item, value: false }
            }

            return item;
        });

        handleAvoidingsChange(newAvoiding);
    }

    const handleRemoveAvoidingAsExternalSearchParam = (removedItem: SuggestionItem): void => {
        const newAvoiding = calcParams.avoiding?.map(item => {
            if (removedItem.id === item.id) {
                return { ...item, value: true };
            }

            return item;
        });

        handleAvoidingsChange(newAvoiding);
    }

    const handleSelectedExternalItemChange = (items: SuggestionItem[], removedItem?: SuggestionItem): void => {
        // new items has been added or external hasn't been touched
        if (items && !removedItem) {
            // handle avoidings as external for now only
            // TODO: add other external group if needed
            handleAddAvoidingAsExternalSearchParam(items.filter(items => items.group === Group[Group.Avoiding]));
        }

        // item has been removed
        if (removedItem) {
            if (removedItem.group === Group[Group.Avoiding]) {
                // handle removing Avoiding token from Autocomplete
                handleRemoveAvoidingAsExternalSearchParam(removedItem);
            }
        }
    }

    const handleSetupAvoidingAsExternalSearchParams = (avoiding: AreaToAvoidRequestExtended[]): void => {
        // filter only items that should be shown as tokens and map them to the type of token
        const avoidingAsSearchParam = avoiding.reduce((areas, area) => {
            // request data contains only id and value. Find element in areasToAvoidData to get some missing data, ex. `name`
            const found = areasToAvoidData?.find(item => item.id === area.id);

            // value === false means area is selected
            if (!area.value && !area.overridden && found) {
                return [...areas, {
                    ...area,
                    external: true,
                    group: Group[Group.Avoiding],
                    label: undefined,
                    name: found.name,
                }]
            }

            return areas;
        }, [] as SuggestionItem[]);

        setExternalSearchParams(avoidingAsSearchParam);
    }

    const handleTimeZoneChange = (timeZone: TimeZonesEnum): void => {
        setCalcParams(
          currentParams => ({
              ...currentParams,
              timeZone: timeZone
          })
        );
    }

    useEffect(() => {
        console.log("Calc result is…", data);

        if (data) {
            setCalcResults(data);
        } else {

            // if there is less than 2 locations calculation won't be done. Hide CalculationResults panel.
            if (calcParams.locations.length < 2) {
                // go back to the map - locations changed
                setRouteSettingsVisible(false);
                setCalcResults(undefined);
            }
        }
    }, [data, calcParams.locations.length])

    useEffect(() => {
        if (data?.avoiding) {
                // avoiding areas might be overridden by vessel restrictions. If so, Avoid checkbox should be checked.
                const newAvoiding = data.avoiding.avoidingAreas.map(item =>
                    ({ ...item, overridden: !data.avoiding.overrideEnabled }));

                handleAvoidingsChange(newAvoiding);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setExternalSuggestions([{
            group: Group[Group.Avoiding],
            items: areasToAvoidData?.map(items => ({
                ...items,
                external: true,
                group: Group[Group.Avoiding],
                label: undefined,
            })) ?? []
        }]);
    }, [areasToAvoidData])

    if (error) {
        console.log("error", error);
    }

    function toggleRouteSettingsVisibility(): void {
        setRouteSettingsVisible(visible => !visible)
    }

    function renderFooterButtons(): ReactNode {
        if (!isTabletOrMobile || !data) {
            return null;
        }

        const iconCommonProps = "icon--small icon--primary";

        const buttonProps: ButtonProps = {
            children: "Refine Route Details",
            className: "toggle-route-settings-button p-button-filled",
            icon: `${iconCommonProps} iconoir-fast-arrow-right icon--right`,
            iconPos: "right",
            onClick: toggleRouteSettingsVisibility,
        }

        if (routeSettingsVisible) {
            buttonProps.children = "Return to map"
            buttonProps.icon = `${iconCommonProps} iconoir-fast-arrow-left icon--left`
            buttonProps.iconPos = "left"
        }

        return <Button {...buttonProps} />
    }


    return <>
        <header>
            <DistanceCalcSearchEntity
                callback={handleCalcParamsChange}
                externalSearchParams={externalSearchParams}
                externalSuggestions={externalSuggestions}
                handleSelectedExternalItemChange={handleSelectedExternalItemChange}
            />
        </header>
        <main
            className={clsx(
                "distance-calc-container",
                "grow-to-fill",
                { "drawer--active": routeSettingsVisible }
            )}
            data-cols="9,3"
            data-drawer-style="slide"
            data-drawer-position="alongside-right"
        >
            <section className="overflow--hidden">
                <div className="grow-to-fill">
                    <AzureMapsProvider>
                        <DistCalcMap calcResult={calcResults} calcParams={calcParams} />
                    </AzureMapsProvider>
                </div>
            </section>
            <aside {...gestures}>
                <ResultsPanel
                    calcResults={calcResults}
                    dataIsLoading={calcResults ? false : isLoading} // show loading when locations changed only
                    calcParams={calcParams}
                    callback={handleCalcParamsChange}
                />
            </aside>
        </main>
        <footer className="p-fluid">
            {renderFooterButtons()}
        </footer>
    </>
}