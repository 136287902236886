import { removeItemAt } from 'helpers/Utils/collections';

import { AccessLevel, UserEntity, UserShareAccess } from '../Models';
import UserAutocomplete from './UserAutocomplete';
import UserShareItem from './UserShareItem';

import styles from './UsersShareArea.module.scss';

interface UsersShareAreaProps {
  users?: UserShareAccess[],
  usersListUpdated: (users: UserShareAccess[]) => void
}

export default function UsersShareArea({
  users,
  usersListUpdated
}: UsersShareAreaProps): JSX.Element {
  const onRemoveUser = (index: number) => usersListUpdated(
    Array.isArray(users) && users.length
    ? removeItemAt(users, index)
    : []
  );
  const onUsersSelected = (usersAdded: UserEntity[]) => {
    const newUsers = usersAdded.map((user) => ({
      ...user,
      access: AccessLevel.Edit,
    }));
    usersListUpdated([
      ...(users || []),
      ...newUsers,
    ]);
  };
  const filterSuggestions = (item: UserEntity) => !users?.find((user) => user.email === item.email);

  return (
    <div className={styles.usersShareContainer}>
      {(Array.isArray(users) && users.length)
        ? <>
            <div className={styles.usersShareContainerHeader}>Shared with</div>
            <div className={styles.usersShareContainerList}>
              {users.map((user, index) => <UserShareItem key={index} user={user} onRemove={() => onRemoveUser(index)} />)}
            </div>
          </>
        : null
      }
      <UserAutocomplete onSelect={onUsersSelected} onFilterSuggestions={filterSuggestions} />
    </div>
  )
}
