import { useEffect, useRef, useState } from 'react';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { useDebounce } from 'primereact/hooks';
import { clsx } from 'clsx';

import GroupedItemTemplate from 'components/Autocomplete/Templates/GroupedItemTemplate';
import ItemTemplate, { type ItemTemplateProps } from 'components/Autocomplete/Templates/ItemTemplate';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';
import { SEARCH_DEBOUNCE_DELAY, type SearchSuggestionsParsedResponse } from 'components/EntitySearch/Models/SearchEntities';

import type { Suggestion } from 'components/Autocomplete';
import type { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';

interface IVesselTypeSearch {
  fields: EntitySearchFieldsEnum | EntitySearchFieldsEnum[];
  module: EntitySearchGroupEnum;
  mutate: (change?: SuggestionResponseLocation) => void;
  addOn?: JSX.Element | null;
  allowCustomSelection?: boolean
  dropdownOptions?: DropdownProps;
  id?: string,
  initialTerm?: string,
  mapper?: (item: ItemTemplateProps) => ItemTemplateProps;
  panelClassName?: string;
  showError?: boolean;
  inputProps?: AutoCompleteProps;
}

export interface SuggestionResponse {
  searchEntityId: string;
  searchField: EntitySearchFieldsEnum;
  searchTerm: string;
  value: string;
  mapper?: (item: ItemTemplateProps) => ItemTemplateProps;
  searchId?: string;
}

export type SuggestionResponseLocation = SuggestionResponse & {
  CountryCodeISO3: string;
  SignalOceanPortId: string;
  Latitude?: string;
  Longitude?: string;
  PortName?: string;
  TimeZone?: string;
}

const GroupedSearch = (props: IVesselTypeSearch): JSX.Element => {

  const { allowCustomSelection, dropdownOptions, mutate, id, panelClassName, initialTerm, module, fields, showError, mapper, addOn, inputProps } = props;
  const [term, debouncedTerm, setTerm] = useDebounce(initialTerm ?? '', SEARCH_DEBOUNCE_DELAY);
  const [ suggestions, setSuggestions ] = useState<Suggestion[]>([]);
  const [ error, setError ] = useState<string | null>(null);
  const autocompleteRef = useRef<AutoComplete>(null);

  const { data, isLoading } = useSearchSuggestions({
    module: module,
    fields: fields,
    chunkSize: 10,
    term: `${debouncedTerm}`.trim().replace(/[^\w\s-]/g, '').replace(/\s+/g, ' ') // Strip out "()" etc. or extra spaces
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    //	we've got some search results
    const grouped: Suggestion[] = data.reduce(
      (a: Suggestion[], c: SearchSuggestionsParsedResponse<any>) => {
        const group = {
          group: c.searchFieldName,
          items: c.values.map(v => ({...v, mapper: mapper})
          )};
        return [...a, group];
      }, []
    );

    setSuggestions(grouped);
  }, [data, mapper]);

  useEffect(() => {
    setTerm(initialTerm ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTerm]);

  useEffect(() => {
    setError(!term || term === '' ? 'Required field' : null);
  }, [term]);

  const handleDropdownChange = (e: DropdownChangeEvent):void => {
    dropdownOptions?.onChange && dropdownOptions.onChange(e);

    // hide dropdown options panel
    (e.originalEvent?.target as HTMLSpanElement).blur();
    // and focus input
    setTimeout(() => {
      if (autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }, 0);
  };

  const dropdown = dropdownOptions ? <Dropdown
    {...dropdownOptions}
    onChange={handleDropdownChange}
  /> : null;

  const autocomplete = <AutoComplete
    id={id}
    ref={autocompleteRef}
    value={term}
    suggestions={[...suggestions]}
    emptyMessage={isLoading ? 'Searching…' : 'Sorry. No matches for that search…'}
    showEmptyMessage={!allowCustomSelection}
    minLength={2}
    loadingIcon={<></>}
    onClear={():void => mutate(undefined)}
    onChange={(e):void => setTerm(e.value)}
    completeMethod={(e):void => {
      //	placeholder, do not remove
      inputProps?.completeMethod && inputProps.completeMethod(e);
    }}
    optionGroupChildren="items"
    optionGroupLabel="role"
    optionGroupTemplate={GroupedItemTemplate}
    scrollHeight="60dvh"
    onSelect={(e):void => {
      setTerm(e.value.value);
      mutate(e.value);
    }}
    panelClassName={clsx(
      {
        'autocomplete-entity autocomplete-entity-grouped': suggestions.length || !allowCustomSelection,
        'empty': !suggestions.length
      },
      panelClassName
    )}
    itemTemplate={(item):JSX.Element => ItemTemplate(item, item.mapper)}
    inputClassName={clsx({'p-invalid': showError && error !== null})}
  />;

  return (<>
    {addOn ?
      <div className={clsx('p-inputgroup', {'p-invalid': showError && error !== null})}>
        {dropdown}
        {autocomplete}
        <span className='p-inputgroup-addon'>
          { addOn }
        </span>
      </div> :
      <div className={clsx({'p-inputgroup flex-1': dropdown, 'p-invalid': showError && error !== null })}>
        {dropdown}
        {autocomplete}
      </div>
    }
    { showError && error && <small className="message-invalid">{error || 'Invalid input'}</small> }
  </>);
};

export default GroupedSearch;
