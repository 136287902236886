import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import { TranscriptionFooterProps } from '../../../Models';

import styles from './TranscriptionFooter.module.scss';

const DATE_FORMAT = 'dd LLL HH:mm:ss';

const TranscriptionFooter = ({
  isEditable,
  isDisabled,
  isEditing,
  onEdit,
  onCancel,
  transcription,
  onSave,
}: TranscriptionFooterProps): JSX.Element => {
  if(!transcription || !transcription.text) {
    return (<></>);
  }

  if(isEditing) {
    return (
      <>
        <Button className={styles.surveillanceTranscriptionButtonSmall} size="small" text severity="danger" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className={clsx('align-items--center', styles.surveillanceTranscriptionButtonLarge)}
          size="small"
          severity="success"
          autoFocus
          disabled={isDisabled}
          onClick={onSave}
        >
          Save changes
        </Button>
      </>
    );
  }

  return (
    <>
      <div className='direction--column grow-to-fill'>
        {transcription.isOriginal
          ? 'Original version'
          : <>
            <div>
              <span>Edited {DateTime.fromISO(transcription.updatedAt).toFormat(DATE_FORMAT)}</span>
            </div>
            <div>
              <span>by {transcription.updatedByName}</span>
            </div>
          </>
        }
      </div>
      {isEditable &&
        <Button
          className={clsx(styles.surveillanceTranscriptionButtonSmall, styles.surveillanceTranscriptionEdit)}
          icon='iconoir-edit-pencil icon--tiny icon--ob-orange'
          size='large'
          disabled={isDisabled}
          onClick={onEdit}
          text
        >
          Edit
        </Button>
      }
    </>
  );
};

export default TranscriptionFooter;
