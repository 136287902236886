/* eslint-disable no-unused-vars */
export enum SurveillanceSearchGroup {
  Counterpart,
  User,
  Content,
  Company,
  Media,
  Raw,
}

export enum Frequency {
  Minutely = 'Minutely',
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Custom = 'Custom'
}

export enum Channel {
  Email = 'Email'
}

export enum State {
  Unmuted = 'Unmuted',
  Muted = 'Muted',
  Deleted = 'Deleted'
}

export enum SurveillanceEntityStatus {
  Active = 'Active',
  Escalated = 'Escalated',
  Reviewed = 'Reviewed',
  ActiveWithComments = 'ActiveWithComments',
}

export enum SurveillanceModeEnum {
  Results = 1,
  Reviewed
}

