import axios from 'axios';
import {
	ArtisPackage,
	ArtisProduct,
	GetPackakgesResponse,
	ApiArtisPackageProduct,
	TenorFrequency,
	TenorPeriods,
	TenorWindowKey 
} from '../Models/Packages';

import { camelToSpace, capFirstLetter } from 'helpers/Utils/string';

export class ArtisPackagesAPI {

	static WINDOW_ORDER: (keyof TenorWindowKey)[] = [
		"today",
		"weekToDate",
		"last7Days", 
		"last14Days", 
		"monthToDate", 
		"last30Days", 
		"last90Days", 
		"yearToDate", 
		"oneYear", 
		"threeYears",
		"fiveYears" 
	]

	static getPackages = (): Promise<ArtisPackage[]> => axios.request<null, GetPackakgesResponse>({
		url: 'datafeed/user/packages',
		method: 'GET'
	}).then(
		result => result.data.map(({label, id: source, groupable = true}) => ({label, source, groupable})) as ArtisPackage[]
	)

	static getProducts = (artispackage: string): Promise<ArtisProduct[]> => axios.request<null, { data: ApiArtisPackageProduct[]; }>({
		url: `datafeed/user/products/${artispackage}`,
		method: 'GET'
	}).then(
		(result) => result.data.map( 
		({label, unit: apiunt, id, windows: apiwindows}) => {

			const unit = apiunt?.replace("_", " / ");
			
			
			const windows: TenorFrequency[] = Object.keys(apiwindows)
			.reduce((a: TenorFrequency[], key: string) => {

				const periods: TenorPeriods[] = apiwindows[key as keyof TenorWindowKey];
				const label: string = capFirstLetter(camelToSpace(key, { lower: false, spaceAroundNumber: true }));

				return [
					...a, 
					{ label, frequency: key as keyof TenorWindowKey, periods }
				];
				
			}, [])
			.sort(({frequency:a}, {frequency: b}) => {
				/** 
				* Sort results according to the sequential order. Makes for 
				* easier iteration over items upstream in the UI
				**/
				return ArtisPackagesAPI.WINDOW_ORDER.findIndex(p => p === a) - ArtisPackagesAPI.WINDOW_ORDER.findIndex(p => p === b);
			});

			return {label, id, name: id, unit, windows}
		}
	))
}
