import React, { ReactHTML } from 'react';
import { uniqueId } from 'helpers/Utils/string';

type MarkTextComponentProps = {
  children?: string;
  replace?: string;
  to?: keyof ReactHTML;
};

const MarkTextComponent = ({
  children = '',
  replace = '<b>',
  to = 'mark',
}: MarkTextComponentProps): JSX.Element => {
  const closeTag = replace.replace('<', '</');
  const parts =
    children?.split(new RegExp(`(?=${replace})|(?<=${closeTag})`)) || [];
  const removeTagsRegex = new RegExp(closeTag.replace('/', '/?'), 'g');

  return (
    <>
      {parts.map(part => {
        if (part.startsWith(replace)) {
          return React.createElement(
            to,
            { key: uniqueId() },
            part.replace(removeTagsRegex, '')
          );
        } else {
          return part;
        }
      })}
    </>
  );
};

export { MarkTextComponent };
export default MarkTextComponent;
