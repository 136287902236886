import { Dispatch, SetStateAction, useCallback } from 'react';
import * as React from 'react';
import { Button } from 'primereact/button';

import { 
  DistributionListResponse
} from '../../Models/distribution-list-response';

import CreateNewButton from './CreateNewButton';

interface EmptyMessageProps {
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  activeDlId?: DistributionListResponse['id'];
  isCreateDisabled?: boolean;
}

const EmptyMessage = (props: EmptyMessageProps): JSX.Element => {
  const { activeDlId, isCreateDisabled, setActiveDetailTab } = props;

  const goToRecipientsTab = useCallback((): void => {
    setActiveDetailTab(1);
  }, [setActiveDetailTab]);

  return (
    <div className="dl-messages-empty distlist-empty grow-to-fill">
      <i className="iconoir-folder-plus no-background"></i>
      <h2 className="no-background">
        It looks like no messages have been sent to this distribution list yet
        {isCreateDisabled && <>.<br />But first, please complete the process of creating a new distribution list</>}
      </h2>
      {isCreateDisabled ?
        <Button
          size="small"
          className="dl-new-message no-background distlist-add"
          onClick={goToRecipientsTab}
        >
          Go to Distribution List Tab
        </Button>
        : <CreateNewButton activeDlId={activeDlId} className="distlist-add"/>}
    </div>);
};

export default EmptyMessage;