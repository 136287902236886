import { RadioButton, type RadioButtonChangeEvent } from 'primereact/radiobutton';
import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';

import TextInput from 'components/TextInput';

import type { SectionProps } from 'modules/Blotter/Models/SectionProps';

export function ClearingSection(props: SectionProps): JSX.Element {
  const { mutate, request, shouldShowError } = props;

  return <section>
    <header><h2>Clearing</h2></header>
    <div className='section--clearing'>
      <div className='form-input__container direction--row trade-input--single-line clearing-section__requires-clearing'>
        <Checkbox
          checked={request.clearing.requires}
          value={request.clearing.requires}
          inputId='trade__requires-clearing--checkbox'
          onChange={(e: CheckboxChangeEvent): void => mutate(
            {
              clearing: {
                ...request.clearing,
                requires: !!e.checked,
                cleared: e.checked ? !!request.clearing.cleared : null, // reset clearing props on uncheck
                house: e.checked ? request.clearing.house : null,
                id: e.checked ? request.clearing.id : null,
              }
            }, ['clearing.house', 'clearing.id'])}
        />
        <label htmlFor='trade__requires-clearing--checkbox'>Require Clearing</label>
      </div>
      {request.clearing.requires && <>
        <div className='form-input__container'>
          <label htmlFor='trade__clearing-house'>Clearing House*</label>
          <TextInput
            id='trade__clearing-house'
            defaultValue={request.clearing.house ?? ''}
            onChange={(value: string): void => mutate({ clearing: { ...request.clearing, house: value } }, 'clearing.house')}
            showError={shouldShowError('clearing.house')}
          />
        </div>
        <div className='form-input__container'>
          <label htmlFor='trade__clearing-id'>Clearing ID*</label>
          <TextInput
            id='trade__clearing-id'
            defaultValue={request.clearing.id ?? ''}
            onChange={(value: string): void => mutate({ clearing: { ...request.clearing, id: value } }, 'clearing.id')}
            showError={shouldShowError('clearing.id')}
          />
        </div>
        <div className='trade__clearing--radio-buttons'>
          <div className='form-input__container direction--row trade-input--single-line'>
            <RadioButton
              inputId='trade__clearing-not-cleared'
              value={false}
              onChange={(e: RadioButtonChangeEvent): void => mutate({ clearing: { ...request.clearing, cleared: !e.checked } })}
              checked={!request.clearing.cleared}
            />
            <label htmlFor='trade__clearing-not-cleared'>Not Cleared</label>
          </div>
          <div className='form-input__container direction--row trade-input--single-line'>
            <RadioButton
              inputId='trade__clearing-cleared'
              value={true}
              onChange={(e: RadioButtonChangeEvent): void => mutate({ clearing: { ...request.clearing, cleared: !!e.checked } })}
              checked={!!request.clearing.cleared}
            />
            <label htmlFor='trade__clearing-cleared'>Cleared</label>
          </div>
        </div>
      </>}
    </div>
  </section>;
}