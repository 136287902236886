import { DateTime } from 'luxon';

import { Channel, Frequency, State } from './Enums';
import { SearchRequestPaged } from './ReportsRequest';

export type SearchResponse = {
  originalRequest: SearchRequestPaged,
  results: ResultsResponse[],
  totalPages: number,
  totalResults: number,
}

export enum SurveillanceProvider {
  C9 = 'C9',
  VoxSmart = 'VoxSmart'
}

export enum SurveillanceMediaRecording {
  Cellular = 'Cellular',
  Voice = 'Voice',
  WhatsAppAudio = 'WhatsAppAudio',
}
export enum SurveillanceMediaText {
  Sms = 'Sms',
  WhatsAppMessage = 'WhatsAppMessage',
}
export type SurveillanceMedia =
  | SurveillanceMediaRecording
  | SurveillanceMediaText;

export const SURVEILLANCE_MEDIA_LABEL: { [key in SurveillanceMedia]: string } =
  {
    [SurveillanceMediaRecording.Cellular]: 'Cellular',
    [SurveillanceMediaRecording.Voice]: 'Voice',
    [SurveillanceMediaText.Sms]: 'SMS',
    [SurveillanceMediaRecording.WhatsAppAudio]: 'WhatsApp',
    [SurveillanceMediaText.WhatsAppMessage]: 'WhatsApp',
  };

export type ResultsResponse = {
  id: string,
  company: string,
  provider: SurveillanceProvider,
  media: SurveillanceMedia,
  email: string,
  userName: string,
  groupName: string, // Line
  counterParty: string, // Participants "nickjohn,eugenerub" or Telephone "+44 123 456 789" - TBC
  partitionKey: string;
  startTime: DateTime,
  stopTime: DateTime,
  content: string,
  elementCounter?: number, // Added for testing pagination
  isAttachment?: boolean,
};

export type SearchFiltersResponse = {
  company?: string[] | null;
  media?: string[] | null;
};

export type SubscriptionsResponse = {
  userId: string;
  email: string;
  worksheetId: string;
  frequency: Frequency;
  state: State;
  chanel: Channel;
  id?: string;
};


