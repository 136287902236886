import { AxiosError } from 'axios';

export enum AccessLevel {
  None = 'None',
  Edit = 'Edit',
}

export interface UserEntity {
  name: string;
  email: string;
}

export interface UserShareAccess extends UserEntity {
  access: AccessLevel;
}

export type ValidateEmailsResult = {
  validEmailsDetails?: UserEntity[];
  invalidEmailsDetails?: UserEntity[];
};

export type ValidateEmailsResponse = {
  data?: ValidateEmailsResult;
  error?: AxiosError;
  isLoading?: boolean;
};
