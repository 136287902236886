import { RefObject, useState } from 'react';

import { eventBus } from "server/EventBus";
import { Button } from 'primereact/button';

import { ShareWorksheetPayload } from '../Services/WorksheetsAPI';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { WorksheetStores } from "../Models/Enums";

interface IComponentParams<T> {
  shareWorksheet: (param: ShareWorksheetPayload<T>) => void;
  store: WorksheetStores;
  worksheet: string;
  onShareParamsRef?: RefObject<{ params: T }>;
}

export const ShareWorksheetDialogFooter = function ShareWorksheetDialogFooter<
  T = any
>(params: IComponentParams<T>): JSX.Element {
  const { shareWorksheet, store, worksheet, onShareParamsRef } = params;
	const [ mutating, setMutating ] = useState<boolean>(false);

	return <div className="space-between">
		<Button
			size='small'
			text
			severity='danger'
			onClick={(e) => eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null)}
			>
			Cancel
		</Button>
		<Button
			size='small'
			severity="success"
			autoFocus
			loading={mutating}
			// disabled={!isValid}
      onClick={async () => {
        console.log('share id', worksheet);
        setMutating(true);
        await shareWorksheet({
          store: store,
          id: worksheet,
          requestBody: onShareParamsRef?.current?.params,
        });
				setMutating(false);
				eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null)
			}}
			>
				Share
		</Button>
	</div>
}