import './SecureEmailGeneratePage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EmailAPI } from 'modules/SecureEmail/Services/EmailAPI';
import PillsInput from 'modules/SecureEmail/Components/PillsInput';
import { EmailStatusResponse } from '../Models/EmailStatusResponse';
import { BsFillPencilFill } from 'react-icons/bs'
import { SiMinutemailer } from 'react-icons/si';
import { AiOutlineMonitor } from 'react-icons/ai';
import { SignalRApi } from 'server/signalR-api';

function SecureEmailGeneratePage() {

    const [validEmailPills, setValidEmailPills] = useState<string[]>([]);
    const [invalidEmailPills, setInvalidEmailPills] = useState<string[]>([]);
    const [emailStatus, setEmailStatus] = useState<EmailStatusResponse | null>(null);
    const [signalREmailStatus, setSignalREmailStatus] = useState<EmailStatusResponse | null>(null);
    const [emailMailtoTriggered] = useState<boolean>(false); // Removed setter as it wasn't used
    const [wizardIndex, setWizardIndex] = useState<number>(0);
    const navigate = useNavigate();
    const emailApi: EmailAPI = new EmailAPI();
    const { messageId } = useParams();

    const signalRApi: SignalRApi = new SignalRApi();

    useEffect(() => {
        reconnected();
        return () => {
            signalRApi.closeConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (messageId && emailStatus?.id !== messageId) {

            document.addEventListener('visibilitychange', () => reconnected())
            document.addEventListener('blur', () => reconnected())
            window.addEventListener('blur', () => reconnected())
            window.addEventListener('focus', () => reconnected())
            document.addEventListener('focus', () => reconnected())

            emailApi.getStatus(messageId).subscribe(r => {
                setEmailStatus(r);
                setWizardIndex(1);
                setValidEmailPills([...r.recipients.map(x => x.emailAddress)]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageId])

    useEffect(() => {
        if (emailStatus?.alreadyProcessed) {
            navigate('/secure-mail/' + emailStatus.id);
        } else if (emailStatus && messageId !== emailStatus.id) {
            console.log("b");
            navigate('/secure-mail-processing/' + emailStatus.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailStatus])

    useEffect(() => {
        console.log(emailStatus, signalREmailStatus);
        if (emailStatus?.id === signalREmailStatus?.id) {
            setEmailStatus(signalREmailStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signalREmailStatus])

    function reconnected() {
        console.log("c");
        signalRApi.startConnection();
        signalRApi.handleMessage("EmailProjectionChange", (emailProjection) => {
            let ep = JSON.parse(emailProjection);
            setSignalREmailStatus(ep);
        });
        if (emailStatus?.id) {
            emailApi.getStatus(emailStatus?.id).subscribe(r => {
                setEmailStatus(r);
            });
        }
    }

    function deleteValidPill(index: number) {
        setValidEmailPills(validEmailPills.filter(x => validEmailPills.indexOf(x) !== index));
    }

    function deleteInvalidPill(index: number) {
        setInvalidEmailPills(invalidEmailPills.filter(x => invalidEmailPills.indexOf(x) !== index));
    }

    function handleNewPillsEntryAvailable(value: string) {
        const emailRegex = RegExp("(?<emailaddress>([a-z|A-Z|.|0-9]+)@([a-z|A-Z|.|0-9]+))");

        if (value) {
            let finalAddresses: string[] = [];
            let newLineAddresses: string[] = value.split('\n');

            for (let i = 0; i < newLineAddresses.length; i++) {
                if (newLineAddresses[i].indexOf(";") !== -1) {
                    finalAddresses.push(...newLineAddresses[i].split(';'))
                } else {
                    finalAddresses.push(newLineAddresses[i])
                }
            }

            let invalidEmailAddresses: string[] = [];

            for (var i = 0; i < finalAddresses.length; i++) {
                if (emailRegex.test(finalAddresses[i])) {
                    var exec = emailRegex.exec(finalAddresses[i])
                    if (exec && exec.groups) {
                        var emailAddress = exec.groups["emailaddress"];
                        if (emailAddress) {
                            console.log(emailAddress);
                            finalAddresses[i] = emailAddress;
                        } else {
                            finalAddresses.splice(i);
                            i--;
                            invalidEmailAddresses.push(finalAddresses[i])
                        }
                    } else {
                        invalidEmailAddresses.push(finalAddresses[i])
                        finalAddresses.splice(i);
                        i--;
                    }
                } else {
                    invalidEmailAddresses.push(finalAddresses[i])
                    finalAddresses.splice(i);
                    i--;
                }
            }

            setValidEmailPills([...validEmailPills, ...finalAddresses]);
            setInvalidEmailPills([...invalidEmailPills, ...invalidEmailAddresses]);
            console.log(validEmailPills)
            console.log(invalidEmailPills)
        }
    }

    function requestSecureEmail() {
        if (validEmailPills?.length > 0) {
            setWizardIndex(1);
            emailApi.generateSecureEmailAddress(validEmailPills).subscribe(r => {
                setEmailStatus(r);
            });
        }
    }



    function resetPage() {
        setWizardIndex(0);
        setInvalidEmailPills([]);
        setValidEmailPills([]);
        setEmailStatus(null);
    }

    function generateOptions() {
        if (!emailStatus) {
            return <>
                <p>Please supply the email addresses you want to send an email to in the box above. When you have supplied the email addresses please click Next.</p>
                <div className='options'>
                    <button onClick={() => resetPage()} className='button cancel'>Reset</button>
                    <button disabled={validEmailPills.length === 0} onClick={() => requestSecureEmail()} className='button success'>Next</button>
                </div>
            </>
        } else if (!emailMailtoTriggered) {
            return <>
                <p>Please use the button on the right to open a new secure email.</p>

                <div className='options'>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className='email-link cancel' onClick={() => resetPage()}>Reset</a>
                    <a className='email-link' target={'_blank'} rel="noreferrer" href={`mailto:${emailStatus?.emailAddress}`}>Click here to send the email.</a>
                </div>
            </>
        }
    }

    return <div className='securemail-generate-page'>
        <div className='progress-wrapper'>
            <div className='points'>
                <div title='Prepare email addresses' className={'point ' + (wizardIndex === 0 ? 'active' : '')}><BsFillPencilFill></BsFillPencilFill></div>
                <div className='spacer'></div>
                <div title='Send email' className={'point ' + (wizardIndex === 1 ? 'active' : '')}><SiMinutemailer></SiMinutemailer></div>
                <div className='spacer'></div>
                <div title='Monitor email' className={'point ' + (wizardIndex === 2 ? 'active' : '')}><AiOutlineMonitor></AiOutlineMonitor></div>
            </div>
        </div>
        <div className='securemail-generate-page-row'>
            <PillsInput enableInputBox={wizardIndex === 0} enablePillDelete={wizardIndex === 0} deleteInvalidPill={deleteInvalidPill} deleteValidPill={deleteValidPill} invalidPills={invalidEmailPills} validPills={validEmailPills} onInputDataAvailable={handleNewPillsEntryAvailable} />
        </div>
        <div className='securemail-generate-page-row'>
            {generateOptions()}
        </div>
    </div >
}

export default SecureEmailGeneratePage;
