import { useState } from 'react';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';

import Loader from 'components/Loader';
import { ToastSeverity } from 'components/ToastMessage';

import { useCreateTranscriptionVersion, useDetails } from './Services/SurveillanceDetailsAPI';
import DetailsView from './DetailsView';
import MetadataView from './MetadataView';
import { DetailsAreaProps, DetailsTabs } from './Models';

import styles from './DetailsArea.module.scss';

const DetailsArea = ({
  activeWorksheetId,
  record,
  toastRef,
  searchRequestFields,
  setRecordSelected,
}: DetailsAreaProps): JSX.Element => {
  const { id, partitionKey, provider } = record;

  const [activeTab, setActiveTab] = useState(0);

  const { details, isLoading, mutate: getDetails } = useDetails(
    {
      id,
      partitionKey,
      providerName: provider,
      searchRequestFields,
    },
    activeWorksheetId
  );
  const { trigger: saveTranscription } = useCreateTranscriptionVersion();

  const saveTranscriptionVersion = async (transcription: string): Promise<void> => {
    await saveTranscription({
      id,
      partitionKey,
      transcription,
    })
      .then(() => {
        toastRef?.current?.replace({
          severity: ToastSeverity.SUCCESS,
          title: 'Changes saved',
          message: 'Success',
        });
        record.content = transcription;
        /**
         * force update results grid item with
         * changed content value without reload
         */
        setRecordSelected({ ...record });
        getDetails();
      });
  };

  if (isLoading) {
    return (
      <div className={styles.flexCenterParent}>
        <div className={styles.flexCenterChild}>
          <Loader className='small' />
        </div>
      </div>
    );
  }

  return (
    <>
      <TabView
        renderActiveOnly={true}
        activeIndex={activeTab}
        onTabChange={({ index }): void => setActiveTab(index)}
        className={styles.tabview}
      >
        <TabPanel header={DetailsTabs.Details}>
          <DetailsView
            activeWorksheetId={activeWorksheetId}
            id={record.id}
            companyName={record.company}
            partitionKey={record.partitionKey}
            details={details}
            highlights={details?.highlights}
            saveTranscriptionVersion={saveTranscriptionVersion}
          />
        </TabPanel>
        <TabPanel
          visible={Boolean(details?.metadata)}
          header={DetailsTabs.Metadata}
        >
          <MetadataView
            metadata={details?.metadata}
            record={record}
            toastRef={toastRef}
          />
        </TabPanel>
      </TabView>
      <Button
        text
        icon='iconoir-xmark icon--tiny'
        className={styles.closeButton}
        onClick={(): void => setRecordSelected(null)}
      />
    </>
  );
};

export default DetailsArea;
