import { ColumnBodyOptions } from 'primereact/column';
import clsx from 'clsx';

import { getValueByPath } from 'helpers/Utils/misc';

import type { Clearing } from 'modules/Blotter/Models/BlotterResponse';

import styles from './Clearing.module.scss';

export default function ClearingTemplate<T,>(data: T, config: ColumnBodyOptions): JSX.Element {

  const { field } = config;
  const value = getValueByPath(data, field);

  const cssclass = clsx(
    styles.cell,
    {
      [`iconoir-check icon--small icon--green ${styles.cellCleared}`]: value === true,
      [styles.cellNotCleared]: value === false,
    }
  );

  const getLabel = (value: Clearing['cleared']): string => {
    switch (value) {
      case true:
        return 'Cleared';
      case false:
        return 'Not cleared';
      case null:
        return '';
    }
  };

  return <div className={cssclass}><span>{getLabel(value)}</span></div>;
}
