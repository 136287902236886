import { RefObject } from 'react';

import { ToastMessageRef } from 'components/ToastMessage';

import { SearchRequestFields } from 'modules/CargoTracker/Models/CargoTrackerRequest';
import {
  ResultsResponse,
  SurveillanceMedia,
} from 'modules/Surveillance/Models/ReportsResponse';

export enum DetailsTabs {
  Details = 'Details',
  Metadata = 'Metadata',
}

export enum MIMEType {
  application = 'application',
  audio = 'audio',
  example = 'example',
  font = 'font',
  image = 'image',
  model = 'model',
  text = 'text',
  video = 'video',
}

export enum AttachmentType {
  Audio = 'Audio',
  Image = 'Image',
  File = 'File',
}

export const AttachmentTypeMap: Partial<Record<MIMEType, AttachmentType>> = {
  [MIMEType.audio]: AttachmentType.Audio,
  [MIMEType.image]: AttachmentType.Image,
};

export type AttachmentItem<T = AttachmentType> = {
  type: T;
  source: string;
  fileName: string;
};

export interface AttachmentComponentProps extends AttachmentItem {}

export interface AttachmentSectionProps {
  className?: string;
  header?: string;
  data?: AttachmentComponentProps | AttachmentComponentProps[];
}

export interface TranscriptionComponentProps {
  className?: string;
  transcriptions?: DetailsVersion[];
  highlights?: string[];
  isEditable?: boolean;
  saveTranscriptionVersion?: (transcription: string) => Promise<void>;
}

export interface TranscriptionFooterProps {
  isEditable: boolean;
  isDisabled: boolean;
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  transcription?: DetailsVersion;
  onSave?: () => void;
}

export interface DetailsAreaProps {
  record: ResultsResponse;
  setRecordSelected: (result: ResultsResponse | null) => void;
  activeWorksheetId: string | null | undefined;
  toastRef?: RefObject<ToastMessageRef>;
  searchRequestFields?: SearchRequestFields[];
}

export interface MetadataViewProps {
  record: ResultsResponse;
  className?: string;
  metadata?: Metadata;
  toastRef?: RefObject<ToastMessageRef>;
}

export interface DetailsViewProps {
  activeWorksheetId: string | null | undefined;
  id: string;
  companyName: string;
  partitionKey: string;
  details?: SurveillanceDetailsResponse;
  highlights?: HighlightsResultsResponse;
  className?: string;
  saveTranscriptionVersion?: (transcription: string) => Promise<void>;
}

enum DetailsContentMedia {
  attachment = 'attachment',
  shoutdown = 'shoutdown',
  sms = 'sms',
  voice = 'voice',
}

export type DetailsVersion = {
  isOriginal: boolean;
  text: string;
  updatedAt: string;
  updatedById?: string;
  updatedByName?: string;
};

export type HighlightsResultsResponse = {
  content?: string[];
};

type DetailsContent = {
  username: string;
  participants: string;
  providerName: string;
  startTime: string;
  versions: DetailsVersion[];
  line?: string;
  media?: SurveillanceMedia;
  isAttachment?: boolean;
  highlights?: HighlightsResultsResponse;
};

export type Metadata = {
  'record id': string;
  'utc offset': string;
  group: string;
  'call status': string;
  'stop reason'?: string;
  region?: string;
  type?: DetailsContentMedia;
  email?: string;
  'counterpart country'?: string;
  'contact number'?: string;
  number?: string;
};

export type SurveillanceDetailsResponse = DetailsContent & {
  metadata: Metadata;
};
