import { type FocusEvent, type ChangeEvent } from 'react';
import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import clsx from 'clsx';

import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import { getAutocompleteValue } from 'components/Autocomplete/Helpers';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { capFirstLetter, formatName } from 'helpers/Utils/string';
import { ValidationMode } from 'modules/Blotter/Models/Enums';
import { getFixedValue } from 'modules/Blotter/Helpers';

import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';

export function BrokerSection(props: SectionProps & { sectionKey: 'buyer' | 'seller'; validationMode: ValidationMode; }): JSX.Element {
  const { errors, shouldShowError, mutate, request, sectionKey, validationMode } = props;

  const selectOnFocus = (e: FocusEvent<HTMLInputElement>): void => e.target.select();

  const sectionRequestData = request[sectionKey];
  const capitalizedSectionKey = capFirstLetter(sectionKey) as 'Buyer' | 'Seller';

  const onObBrokerCompleteMethod = (): void =>
    // keep userId empty until it's selected from the dropdown options
    mutate({ [sectionKey]: { ...sectionRequestData, obBroker: { ...sectionRequestData.obBroker, userId: '' } } }, [`${sectionKey}.obBroker.userName`, `${sectionKey}.obBroker.userId`]);

  const onObBrokerInputClear = (): void =>
    mutate({ [sectionKey]: { ...sectionRequestData, obBroker: { userId: '', userName: '' } } }, [`${sectionKey}.obBroker.userName`, `${sectionKey}.obBroker.userId`]);

  const onObBrokerChange = (change?: SearchSuggestionsResponse): void =>
    mutate({ [sectionKey]: { ...sectionRequestData, obBroker: { userId: change?.searchEntityId, userName: change?.value } } }, [`${sectionKey}.obBroker.userName`, `${sectionKey}.obBroker.userId`]);

  return <section>
    <header><h2>{capFirstLetter(sectionKey)}</h2></header>
    <div className={`section--${sectionKey}`}>
      <SingleEntitySearch
        allowCustomSelection
        callback={(change?: SearchSuggestionsResponse | string): void => mutate({ [sectionKey]: { ...sectionRequestData, company: getAutocompleteValue(change) } }, `${sectionKey}.company`)}
        onInputClear={(): void => mutate({ [sectionKey]: { ...sectionRequestData, company: '' } }, `${sectionKey}.company`)}
        label='Company*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError(`${sectionKey}.company`) || (validationMode === ValidationMode.CompanyOnly && !!errors?.[`${sectionKey}.company`])} // show error immidiatelly if buyer.company === seller.company
        customErrorMessage={errors?.[`${sectionKey}.company`]}
        fields={EntitySearchFieldsEnum.TradeCompany}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={sectionRequestData.company}
        itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
        onFocusMethod={selectOnFocus}
      />
      <SingleEntitySearch
        allowCustomSelection
        callback={(change?: SearchSuggestionsResponse | string): void =>
          mutate({ [sectionKey]: { ...sectionRequestData, contactName: getAutocompleteValue(change) } }, `${sectionKey}.contactName`)}
        onInputClear={(): void => mutate({ [sectionKey]: { ...sectionRequestData, contactName: undefined } }, `${sectionKey}.contactName`)}
        label='Contact Name*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError(`${sectionKey}.contactName`)}
        fields={EntitySearchFieldsEnum[`${capitalizedSectionKey}ContactName`]}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={sectionRequestData.contactName}
        itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
        onFocusMethod={selectOnFocus}
      />
      <SingleEntitySearch
        allowCustomSelection
        callback={(change?: SearchSuggestionsResponse | string): void => mutate({ [sectionKey]: { ...sectionRequestData, tradingAccount: getAutocompleteValue(change) } }, `${sectionKey}.tradingAccount`)}
        onInputClear={(): void => mutate({ [sectionKey]: { ...sectionRequestData, tradingAccount: undefined } }, `${sectionKey}.tradingAccount`)}
        label='Trading Account*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError(`${sectionKey}.tradingAccount`)}
        fields={EntitySearchFieldsEnum[`${capitalizedSectionKey}TradingAccount`]}
        module={EntitySearchGroupEnum.Blotter}
        initialTerm={sectionRequestData.tradingAccount}
        itemTemplate={(i: SearchSuggestionsResponse): string => i.value}
        onFocusMethod={selectOnFocus}
      />
      <div className='form-input__container direction--row trade-input--single-line'>
        <Checkbox
          checked={sectionRequestData.paysBrokerage}
          value={sectionRequestData.paysBrokerage}
          inputId={`trade__${[sectionKey]}-pays-brokerage-checkbox`}
          onChange={(e: CheckboxChangeEvent): void => mutate(
            {
              [sectionKey]: {
                ...sectionRequestData,
                paysBrokerage: e.checked,
                rate: e.checked ? sectionRequestData.rate : '', // reset rate and obBroker on uncheck
              }
            },
            `${sectionKey}.rate`
          )}
        />
        <label htmlFor={`trade__${[sectionKey]}-pays-brokerage-checkbox`}>Pays Brokerage</label>
      </div>
      {sectionRequestData.paysBrokerage &&
        <div className='form-input__container'>
          <label htmlFor={`trade__${[sectionKey]}-rate`}>Rate*</label>
          {/* Use `InputText` isntead of `InputNumber` because of bug on mobile Android with entering decimal numbers */}
          <div className={clsx('p-inputgroup', { 'p-invalid': shouldShowError(`${sectionKey}.rate`) })}>
            <InputText
              id={`trade__${[sectionKey]}-rate`}
              className='align--right'
              keyfilter='pnum'
              type='number'
              value={`${sectionRequestData.rate ?? ''}`}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => mutate({
                [sectionKey]: {
                  ...sectionRequestData,
                  rate: getFixedValue(e.target.value)
                }
              }, `${sectionKey}.rate`)}
              min={0}
            />
          </div>
          {shouldShowError(`${sectionKey}.rate`) && <small className='message-invalid'>Required field</small>}
        </div>}
      <SingleEntitySearch
        className='broker-section__ob-broker'
        callback={onObBrokerChange}
        onInputClear={onObBrokerInputClear}
        completeMethod={onObBrokerCompleteMethod}
        label='OB Broker*'
        errorVisibleAfterTouch={false}
        showError={shouldShowError(`${sectionKey}.obBroker.userName`) || shouldShowError(`${sectionKey}.obBroker.userId`)}
        customErrorMessage={errors?.[`${sectionKey}.obBroker.userId`]} // if text is inputted but not an option selected then show validation error
        fields={EntitySearchFieldsEnum.User}
        module={EntitySearchGroupEnum.Users}
        initialTerm={request.isImported ? sectionRequestData.obBroker.userName : formatName(sectionRequestData.obBroker.userName)}
        itemTemplate={(i: SearchSuggestionsResponse): string => formatName(i.value)}
        onFocusMethod={selectOnFocus}
      />
    </div>
  </section>;
};