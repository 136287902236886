import axios from 'axios';
import { DateTime } from 'luxon';
import useSWR, { MutatorCallback, State, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import {
  DistributionListCreateRequest,
  DistributionListMessagesSearchRequest,
  ResendParams,
} from '../Models/distribution-list-create-request';
import {
  DistributionListMessagesSearchResponse,
  DistributionListResponse,
  DistributionListResponseSingle
} from '../Models/distribution-list-response';

import { replaceItemAt } from 'helpers/Utils/collections';

import { ResponseError } from 'models/shared/error';

export const DIST_LISTS_CACHE: string = 'DistributionLists';

export const useGetDistLists = (): {
    loadDistlistData: DistributionListResponse[] | undefined | void,
    loadDistlistError: Error | undefined,
    loadDistlistIsLoading: boolean } => {
      
  const { data, error, isLoading} = useSWR(
    DIST_LISTS_CACHE,
    () => DistListApiService.GetDistists(),
    { revalidateOnFocus: false }
  );
  
  return { loadDistlistData : data, loadDistlistError : error, loadDistlistIsLoading:  isLoading};
};

export const useSearchMessages = (dlsr: DistributionListMessagesSearchRequest):
  { searchResults: DistributionListMessagesSearchResponse[] | undefined,
    searchError: ResponseError,
    searchIsLoading: boolean,
    searchMutate: MutatorCallback<DistributionListMessagesSearchResponse[]> } => {
  const { data, error, isLoading, mutate } =
    useSWR(dlsr,
      DistListApiService.SearchMessages,
      { revalidateOnFocus: false });
  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
  };
};

export const useGetDistListById = (id?: string): {
  data: DistributionListResponseSingle | undefined | void,
  error: Error | undefined,
  isLoading: boolean } => {
  const shouldFetch = id !== undefined;
  const { data, error, isLoading} = useSWR(
    shouldFetch ? `DistributionLists/${ id }` : null,
    () => DistListApiService.GetDististById(id),
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading};
};

export const useCreateUpdateDistList = (): {
  data: DistributionListResponseSingle | undefined | void;
  trigger: (extraArgument: DistributionListCreateRequest | any) => Promise<DistributionListResponseSingle | undefined | void>;
  isMutating: boolean;
} => {
  const { mutate } = useSWR(DIST_LISTS_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    DIST_LISTS_CACHE,
    DistListApiService.CreateUpdateDistList,
    {
      onSuccess: c => {
        //	Mutate the cache with new or updated data
        const { data } = cache.get(DIST_LISTS_CACHE) as State<DistributionListResponse[]>;

        if (!data || !c) {
          return;
        }

        const index: number = data.findIndex(i => i.id === c.id);

        if (index === -1) { // New
          mutate([DistListApiService.updateDistListsResponse(c), ...data], { revalidate: false });
        } else { // Updated
          mutate(replaceItemAt(data, DistListApiService.updateDistListsResponse(c), index), { revalidate: false });
        }
      }
    }
  );

  return { trigger, data, isMutating };
};

export class DistListApiService
{
  static CreateUpdateDistList = (url: string, params: { arg: DistributionListCreateRequest }): Promise<DistributionListResponseSingle | void> => {
    const data = params.arg;
    const method: string = 'PUT';

    return axios.request({
      url: 'DistributionLists',
      method: method,
      data: data
    }).then(r => r?.data as DistributionListResponseSingle)
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      });
  };

  // Not used for now, for future use if we want to save only recipients
  static UpdateDistListRecipients = (url: string, params: { arg: { id: string, recipients: string[] }}): Promise<DistributionListResponse | void> => {
    const data = params.arg;
    const method: string = 'PATCH';

    return axios.request({
      url: `DistributionLists/${ data.id }/Recipients`,
      method: method,
      data: data.recipients
    })
      .then(r => r?.data as DistributionListResponse)
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      });
  };

  static GetDistists = ():Promise<void | DistributionListResponse[]> => {
    const method: string = 'GET';

    return axios.request({
      url: 'DistributionLists',
      method: method
    }).then(r => r?.data.map((el:DistributionListResponse):DistributionListResponse => {
      const { id, companyDomain, recipientsCount, linkedCldd,  name, lastEmailDate } = el;

      return {
        id,
        companyDomain,
        recipientsCount,
        linkedCldd,
        name,
        lastEmailDate: DateTime.fromISO(`${ lastEmailDate }`, {zone: 'utc'}),
      };
    }) as DistributionListResponse[])
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        throw e;
      });
  };

  static GetDististById = (id?: string):Promise<void | DistributionListResponseSingle> => {
    const method: string = 'GET';

    return axios.request({
      url: `DistributionLists/${ id }`,
      method: method
    }).then(r => r?.data as DistributionListResponseSingle)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        throw e;
      });
  };

  static SearchMessages = (data: DistributionListMessagesSearchRequest): Promise<DistributionListMessagesSearchResponse[]> => axios.request({
    url: 'mail/email/search',
    data,
    method: 'POST'
  })
    .then(results => results.data.map(this.updateSearchMessagesResponse))
    .catch(e => {
      ErrorToastService.handleError(e, [400, 500, 503]);

      throw e;
    });

  static Resend = (params: ResendParams): Promise<ResendParams | null> =>
    axios<ResendParams>({
      url: 'mail/email/resend',
      data: params,
      method: 'POST'
    })
      .then(() => params)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static updateSearchMessagesResponse =
    (r:DistributionListMessagesSearchResponse, index: number):DistributionListMessagesSearchResponse => ({
      ...r,
      messageDate: DateTime.fromISO(`${ r.messageDate }`).toUTC(),
    });

  static EmptyRequest = (): DistributionListCreateRequest => ({
    id: undefined,
    name: '',
    linkedCldd: '',
    recipients: [],
    comments: ''
  });

  static updateDistListsResponse =
    (r:DistributionListResponseSingle):DistributionListResponse => ({
      ...r,
      recipientsCount: r.recipients.length,
      lastEmailDate: DateTime.fromISO(`${ r.lastEmailDate }`, { zone: 'utc' }),
    });

}