import { memo, useMemo } from 'react';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import clsx from 'clsx';

import { formatName } from 'helpers/Utils/string';

import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import AssignToMe from 'components/AssignToMe';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';

import { WorkflowProvider, WorkflowStatusTypeEnum } from 'modules/Workflows/Models/Enums';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { useStatusOptions } from 'modules/Workflows/Services/hooks';
import { WorkflowTask } from 'modules/Workflows/Models';

import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';

import './Task.scss';

interface TaskProps {
  handleAssigneeChange: (change: SuggestionResponse | null, task: WorkflowTask, save?: boolean) => void;
  handleStatusChange: (e: DropdownChangeEvent, task: WorkflowTask, save?: boolean) => void;
  task: WorkflowTask;
	provider: WorkflowProvider
}

export function Task(props: TaskProps): JSX.Element {

  const { handleAssigneeChange, handleStatusChange, task, provider } = props;

	const { obxuser } = useLoggedInUser();
  const availableStautusOptions = useStatusOptions(provider);

	const options = useMemo(() => {
		if (!availableStautusOptions) return;

		return [
			...availableStautusOptions.map(({key, value}) => ({
			value,
			label: key })),
			{ value: 'Custom', label: 'Change status with note' }
		]
	}, [availableStautusOptions])

  return <Card className='task-card__container'>
    <div className='task-card__row'>
      <div className="task-card__row-data--column-1">
        {task.title}
      </div>
      {!!task.comments.length && <div className='task-card__row-data--comments-count'>
        <i className='icon--small iconoir-chat-lines' />
        <span>{task.comments.length}</span>
      </div>}
    </div>
    <div className='task-card__row'>
      <SingleEntitySearch
        className='task-card__row-data--column-1'
        customItems={[{
          value: obxuser?.name ?? '',
          searchEntityId: obxuser?.userId ?? '',
          template: AssignToMe
        }]}
        showCustomItem={!task.assignedToId || task.assignedToId !== obxuser?.userId}
        label=''
        module={EntitySearchGroupEnum.Users}
        fields={EntitySearchFieldsEnum.User}
        initialTerm={formatName(task.assignedToName ?? '')}
        itemTemplate={(i: SuggestionResponse): string => formatName(i.value)}
        onInputClear={(): void => handleAssigneeChange(null, task, true)}
        showError={!task.assignedToId}
        callback={(change: SuggestionResponse | null): void => handleAssigneeChange(change, task, true)}
        onClickMethod={(e) => e.stopPropagation()}
        autocompletePt={{
          panel: {
            onClick: (e) => e.stopPropagation() // prevent opening Sidebar on panel (option) click
          },
        }}
      />
      <Dropdown
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
					// setStatus(e.value);
					handleStatusChange(e, task, true)}
				}
        value={task.currentStatus}
        options={options}
        scrollHeight='40vh'
        className={clsx(`task-card__row-data--column-2 status--${WorkflowStatusTypeEnum[task.currentStatus]}`)}
        panelClassName='task__task-status-dropdown--panel'
      />
    </div>
  </Card>;
}

export default memo(Task);